import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useState } from "react";
// import UsersAdd from './CwWfUsersAdd';
// import { Row } from "react-bootstrap";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import {useHistory} from 'react-router-dom';
class valueHelpDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "DataElement",
           
        };
    }
    componentDidUpdate(prevProps) {
        
      }
    
      componentDidMount() {
        
        // fetch("/workrules/v1/dataElement")
        //   .then((response) => response.json())
        //   .then((data) =>
        //     this.setState({
        //       ...this.state,
        //       rows: this.getRows(),
        //     })
        //   );
    
       
       
      }
    handleChange = (event, newValue) => {
        this.setState({
            ...this.state,
            value: newValue
        });
    };
    rowSelected=(event)=>{
        var s=event;
    }

    render() {

        return (<Dialog
            fullScreen={false}
            open={this.props.open}
           
        //onClose={() => props.onClose('CANCEL')}
        >
            <DialogTitle style={{  display: 'flex', alignItems: 'center', borderBottom: '1px solid #d9d9d9' }}>Value Help</DialogTitle>
            <DialogContent >
                <DialogContentText style={{width:"35rem"}}>
                <TableContainer component={Paper} style={{width:"100%"}}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Name</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Label</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Description</TableCell>
                                   

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.dataElement.map((row) => {
                                  return ( 


                                        <TableRow key="b1" onClick={()=>this.props.rowSelected(row)}>

                                            <TableCell >{row.name}</TableCell>
                                            <TableCell >{row.label}</TableCell>
                                            <TableCell >{row.description}</TableCell>
                                            
                                        </TableRow>
                                     );
                                 })} 
                            </TableBody>
                        </Table>
                    </TableContainer>
                
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ height: '3rem', borderTop: '1px solid #d9d9d9' }}>
                {/* {props.actions.map(action => <Button key={action} variant="contained" size="small" onClick={() => props.handleClose(action)}>{action}</Button>)} */}
                <Button key={'CANCEL'} variant="contained" size="small" onClick={()=>{this.props.onClose("CANCEL")}}>CANCEL</Button>
               
            </DialogActions>
        </Dialog>)
    }
}

export default withRouter(valueHelpDialog);