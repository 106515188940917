import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
//import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class dataElementDialog extends React.Component {

    constructor(props) {
        super(props);
        this.valuehelp = React.createRef();
        let emptyRow = this.emptyRow();
        this.state = {
            // value: "DataElement",
            // tableVisible: true,
            selectedRow: props.selectedRow,
            constraints: [],
            fields: [],
            apiType: [{
                name: "REST"
            }, {
                name: "ODATA"
            }
            ],
            fileTypeDropDown: [{
                name: ".xlsx"
            }, {
                name: ".csv"
            }, {
                name: ".xml"
            }
            ]
        };
    }
    // state = {
    //     tableVisible: true,

    // };
    componentDidUpdate(prevProps) {
       
        if (this.props.rowData) {
        }
    }
    emptyRow = () => {

        const selectedRow = {
            attributeId: "",
            createdBy: "",
            createdOn: null,
            dataType: "",
            description: "",
            destinations: null,
            isLookup: true,
            label: "",
            length: "",
            lookUpId: "",
            lookUpType: "",
            lookupConfig: null,
            name: "",
            source: "",
            updatedBy: "",
            updatedOn: null,
            propertyDto: {
                isMandatory: true,
                isVisible: true,
                isNullable: true,
                defaultValue: "abc",
                isFilterable: true,
                isSortable: true,
                isEditable: true
            }
        };
        return selectedRow;
    };
    createNewField = (event, newValue) => {
        this.setState({
            ...this.state,
            tableVisible: false,
        });
    };
    handleSubmit = (evt) => {
        let a = evt;
    }
    editFields = (row) => {
        this.setState({
            ...this.state,
            tableVisible: false,
            selectedRow: row
        });
    };


    onValueHelpType = (event) => {
        let value = event.target.value;
        this.props.lookupType = value;
    };



    goToMsgComp = event => {
        this.props.history.push('/messageSummary');
    }

    goToThemeComp = event => {
        this.props.history.push('/themeStyling');
    }
    render() {

        // const userAccess = this.props.userAccess;
        // const modellingEntity = userAccess.entities.find(ele => ele.name === "Modelling") ? true : false;
        // const userFormsEntity = userAccess.entities.find(ele => ele.name === "UserForms") ? true : false;
        // const configurationEntity = userAccess.entities.find(ele => ele.name === "Configuration") ? true : false;
        // const rulesEntity = userAccess.entities.find(ele => ele.name === "Rules") ? true : false;

        return (<Dialog
            fullScreen={false}
            open={this.props.open}
           
        //onClose={() => props.onClose('CANCEL')}
        >
            <DialogTitle style={{  display: 'flex', alignItems: 'center', borderBottom: '1px solid #d9d9d9' }}>Data Element Details</DialogTitle>
            <DialogContent >
                <DialogContentText style={{width:"35rem", marginTop:'0.5rem'}}>
                <Grid container spacing={3} 
                       
                    >

                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                disabled
                                id="filled-disabled"
                                label="Name"
                                name="name"
                                value={this.props.rowData.name}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                disabled
                                id="filled-disabled"
                                label="Label"
                                name="label"
                                value={this.props.rowData.label}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                disabled
                                id="filled-disabled"
                                label="Description"
                                name="description"
                                value={this.props.rowData.description}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                        <TextField
                                required
                                disabled
                                id="filled-disabled"
                                label="Data Type"
                                name="description"
                                value={this.props.rowData.dataType}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                disabled
                                // error={this.state.validLength}
                                // helperText={this.state.errorMssg}
                                // disabled={this.state.selectedRow.dataType === "BOOLEAN" || this.state.selectedRow.dataType === "TIMESTAMP" || this.state.selectedRow.dataType === "DATE"}

                                id="filled-disabled"
                                label="Length"
                                name="length"
                                value={this.props.rowData.length}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 5 }}
                            // className="customInput2" 
                            />
                            

                              </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} >
                                {/* <InputLabel id="demo-simple-select-outlined-label" ></InputLabel> */}
                                <FormGroup aria-label="position" row >

                                    <FormControlLabel
                                        required
                                        label='Value Help'
                                        size="small"
                                        // checked={this.state.selectedRow.isLookup}
                                        //checked={this.state.obj.active}
                                        name="active"
                                        onChange={(evt) => this.onValueHelpSwitch(evt)}
                                        //value={row.active}
                                        labelPlacement="start"
                                        control={<Switch color="primary" />}


                                    />


                                </FormGroup>
                            </FormControl >
                        </Grid>
                    </Grid>
                <FormControl component="fieldset">

                    <RadioGroup row name="row-radio-buttons-group"
                        // value={this.props.parentState.lookupType}
                        style={{ marginLeft: "1rem" }}
                        // onChange={(e, newvalue) => this.props.handleChangeValueHelpType(e, newvalue)}
                    >
                        <FormControlLabel value="VL"
                            control={<Radio color="primary" />} label="Static Valus Help" />
                        <FormControlLabel value="API" control={<Radio color="primary" />} label="Api Based" />
                        <FormControlLabel value="DB" control={<Radio color="primary" />} label="Database Based" />
                        <FormControlLabel value="FILE" control={<Radio color="primary" />} label="File Type" />
                        <FormControlLabel value="COMPOSITE" control={<Radio color="primary" />} label="Composite" />

                    </RadioGroup>
                </FormControl>

               
              
           
            </DialogContentText>
            </DialogContent>
            <DialogActions style={{ height: '3rem', borderTop: '1px solid #d9d9d9' }}>
                {/* {props.actions.map(action => <Button key={action} variant="contained" size="small" onClick={() => props.handleClose(action)}>{action}</Button>)} */}
                <Button key={'CANCEL'} variant="contained" size="small" onClick={()=>{this.props.onClose("CANCEL")}}>CANCEL</Button>
               
            </DialogActions>
        </Dialog>
        );
    }
}

export default withRouter(dataElementDialog);
