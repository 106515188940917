import React, { Component } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MessageBoxComponentSave from "./CwWfSaveConfirmation";
import Select from "@material-ui/core/Select";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { TextField, InputAdornment, Toolbar, Icon } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Paper from "@material-ui/core/Paper";
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import MapPropertiesMessagetext from './Cw_MapPropertiesMessagetext';

import MenuItem from "@material-ui/core/MenuItem";

class CwMessagetext extends Component {
    constructor(props) {
        super(props);
this.state={
    detaileddata:[],
    finaldetaileddata:[],
    usageArea:'',
    showDialogSave:false,
    messagetype:'',
    showmessageDetails:false,
    new:false,
    newcreation:'EXISTING',
    messageno:'',

}
    }
    adddata=()=>{
      let tempa=this.state.detaileddata;
      let tempd={
         languageCode:null,
         messageText:''
      }
      tempa.push(tempd);
      this.setState({
         ...this.state,
         detaileddata:tempa,
       
     })

  }
  importExcelFile = async (e) => {
    
    // console.log(e)
    if(e.target.files[0]) {
      const fileType = e.target.files[0].name.split(".")[1];
      if(fileType === "xlsx" || fileType === "csv") {
        const formData = new FormData();
        formData.append("file",e.target.files[0]);
        let headers= {
          ContentType: "application/json",
          Accept: "application/json",
          cache: false,
          "X-CSRF-Token": "Fetch",
        "Authorization":this.props.authorization
      };
      const response1 =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
      
      const token =  response1.headers.get("X-Csrf-Token");
         

        const requestOptions = {
          method: "POST",
          "X-CSRF-Token": token ,
          body: formData,
          headers:{"Authorization":this.props.authorization}
        };
        const response = await fetch( "CW_Worktext/message/upload", requestOptions);
        const result = await response.json();
         console.log(result.output)
      
      } 
    } 
    this.props.changeapplication(this.props.applicationName);    
  }

  propertydata=()=>{
    let tempd=[{
      languageCode:null,
      messageText:''
   }]
   fetch("CW_Worktext/message/maxMessageNumber?applicationName="+this.props.applicationName +"&elementName=MESSAGE_TEXT",{headers:{"Authorization":this.props.authorization}})
   .then((res) => res.json())
   .then((json) => {
    this.setState({
      ...this.state,
      detaileddata:tempd,
      showproperty:true,
      messageno:json.data,
      messagetype:'',
      usageArea:'',
      new:true,
      newcreation:'NEW',

  })})
    
}
  changelanguage=(option)=>{
    let tempdetails=this.state.finaldetaileddata.filter(app=>app.languageCode.includes(option.langaugeCode));
    this.setState({
        ...this.state,
       detaileddata:tempdetails,
       
    })
 }

 savethedata=()=>{
  this.setState({
    ...this.state,

    showDialogSave: true

})
 };
 onSaveDataElement = async(e) => {
  var tempmessage="Add all the fields" ;
  var  payload = {
   
    applicationName: this.props.applicationName,
   
    elementName: "MESSAGE_TEXT",
    messageCreationType: this.state.newcreation,
    messageNumber:this.state.messageno,
   messageType:this.state.messagetype,
    translations: this.state.detaileddata,
    usageArea:this.state.usageArea
  };
    let method, url;
   
      method = "POST";
      url = "CW_Worktext/message/createMessage";
    
    let headers= {
      ContentType: "application/json",
      Accept: "application/json",
      cache: false,
      "X-CSRF-Token": "Fetch",
  "Authorization":this.props.authorization
  };
  const response =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
  
  const token =  response.headers.get("X-Csrf-Token");
  console.log(token)
    const requestParam1 = {
      method: method,
      headers: { "X-CSRF-Token": token, "Content-Type": "application/json","Authorization":this.props.authorization},
      body: JSON.stringify(payload),

    };
   await fetch(url, requestParam1)
      .then((response)=>response.json())
      .then((response) => {
        this.setState({...this.state,messageOpen:true,messageContent:response.message});
      })
      ;
      fetch("CW_Worktext/message/getMessagesTranslationsInAllLanguages?applicationName="+this.props.applicationName+"&elementName=MESSAGE_TEXT&messageNumber="+this.state.messageno+"&messageType="+this.state.messagetype+"&usageArea="+this.state.usageArea,{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
            ...this.state,
            detaileddata:json.data,
            finaldetaileddata:json.data,
           
             
              
          })})
        }
      
 closeDialog = (evt) => {    
  if (evt === "SAVE" ) {
      this.onSaveDataElement();
  }
  


 
  this.setState(
      {
          ...this.state,            
          showDialogSave: false,  
      });

}
changeDescription=(evt)=>{
  this.setState({
      ...this.state,
     [evt.target.name]:evt.target.value,
  })
}
 download=()=>{
  var sUrl = "CW_Worktext/message/download?applicationName=" + this.props.applicationName + "&elementName=MESSAGE_TEXT";
  var oWindow = window.open(sUrl, "_blank");
}
    changeshowproperty=()=>{
        this.setState({
          ...this.state,

         detaileddata:[],
         messagetype:'',
         usageArea:'',
         messageno:'',

        })
      }
      changedetaillanguage=(evt,option,index)=>{
        let tempa=this.state.detaileddata;
        let tempv=this.state.detaileddata[index];
        let val=this.props.languages.filter(ele=> ele.languageDescription===evt.target.dataset.value);
        tempv.languageCode=val[0].langaugeCode;
        tempa[index]=tempv;
        this.setState({
           ...this.state,
           detaileddata:tempa,
           new:false
       })

    }

    changedetails=(evt,option,index)=>{
      //let index=
      let tempa=this.state.detaileddata;
      let tempv=this.state.detaileddata[index];
     let t=evt.target.name;
      tempv[t]=evt.target.value;
      tempa[index]=tempv;
      this.setState({
          ...this.state,
          detaileddata:tempa
      })
  }
  delete=async(option)=>{
      let url='CW_Worktext/message/deleteMessage?applicationName='+this.props.applicationName+'&elementName=MESSAGE_TEXT&messageNumber='+ option.messageNumber+'&messageType='+option.messageType+'&usageArea='+option.usageArea ;

      let headers= {
        ContentType: "application/json",
        Accept: "application/json",
        cache: false,
        "Authorization":this.props.authorization,
        "X-CSRF-Token": "Fetch",
    };
    const response =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
    
    const token =  response.headers.get("X-Csrf-Token");
    
    
    console.log(token)
      await fetch(url, { method: 'DELETE', headers:{"X-CSRF-Token": token ,"Authorization":this.props.authorization}})
      .then((response)=>response.json())
      .then((response) => {
       console.log(option)
      });
      this.props.changelanguage(this.props.selectedlanguage);
    }
  deletemessage=async(option)=>{
    let url='CW_Worktext/message/deleteMessage?applicationName='+this.props.applicationName+'&elementName=MESSAGE_TEXT&languageCode='+option.languageCode+'&messageNumber='+ this.state.messageno+'&messageType='+this.state.messagetype+'&usageArea='+this.state.usageArea ;
    let headers= {
      ContentType: "application/json",
      Accept: "application/json",
      cache: false,
      "X-CSRF-Token": "Fetch",
"Authorization":this.props.authorization
  };
  const response =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
  
  const token =  response.headers.get("X-Csrf-Token");
     
    await fetch(url, { method: 'DELETE', headers:{"X-CSRF-Token": token,"Authorization":this.props.authorization} })
      .then((response)=>response.json())
      .then((response) => {
       console.log(option)
      });
      fetch("CW_Worktext/message/getMessagesTranslationsInAllLanguages?applicationName="+this.props.applicationName+"&elementName=MESSAGE_TEXT&messageNumber="+this.state.messageno+"&messageType="+this.state.messagetype+"&usageArea="+this.state.usageArea,{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
            ...this.state,
            detaileddata:json.data,
            finaldetaileddata:json.data,
           
             
              
          })})
  }
    editFields = (row) => {
        fetch("CW_Worktext/message/getMessagesTranslationsInAllLanguages?applicationName="+this.props.applicationName+"&elementName=MESSAGE_TEXT&messageNumber="+row.messageNumber+"&messageType="+row.messageType+"&usageArea="+row.usageArea,{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
              ...this.state,
              detaileddata:json.data,
              finaldetaileddata:json.data,
               usageArea:row.usageArea,
               messagetype : row.messageType,
               showmessageDetails:true,
               messageno:row.messageNumber
               
                
            })})
      };

    render() {
        const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
        const userAccess = this.props.userAccess;
    console.log(userAccess);
    const editMessage = userAccess.activities.filter(
        ele => ele === "Edit Message"
        ) ? true : false;
        const importMessage = userAccess.activities.filter(
          ele => ele === "Import Message"
          ) ? true : false;
          const mapMessage = userAccess.activities.filter(
            ele => ele === "Map Message"
            ) ? true : false;
          
        return (<div>
           {this.props.showmap && <MapPropertiesMessagetext changeshowproperty={()=>this.changeshowproperty()} editscreen={()=>this.props.editscreen()}editfield={(option)=>this.editFields(option)}properties={this.props.messagetextproperty}changeshowtable={()=>this.props.changeshowtable()} authorization={this.props.authorization }languages={this.props.languages} application={this.props.application} applicationName={this.props.applicationName}  onChangeSearchQuery={(evt)=>this.props.onChangeSearchQuery(evt)}showmapprops={()=>this.props.showmapprops()}/>}
          
          {this.props.showtable &&  <div>
            <Toolbar style={{ display:'block',position:"absolute",marginTop:'-3.5rem',right:'0'}}>    
                 <TextField
                  id='filled-disabled'
                  size="small" 
                
                 variant='outlined'
                 style={{ width:'20rem',background: '#ffffff', marginRight:'1rem'}}
                 placeholder="Search usage Area..."
               //   value={searchQuery}
                 className="styleSearch"
                 onChange={(evt)=>this.props.onChangeSearchQuerymessage(evt)}
               //   onKeyDown={onChangeSearchQuery}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="toggle password visibility"
                         
                       >
                         <SearchIcon />
                       
                       </IconButton>
                      
                     </InputAdornment>
                   )
                 }} />
                 <FormControl style={{width: 180 ,marginRight:'1rem'}} variant="outlined" size="small">
             <InputLabel id="demo-simple-select-outlined-label" required>
                Language
             </InputLabel>
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               // size="small"
               name="language"
             
             
             > 
             {this.props.languages.map((option) => (
                 
                 <MenuItem
                   value={option.langaugeCode}
                    onClick={() => this.props.changelanguage(option.langaugeCode)}
                  
                  
                 >
                   {option.languageDescription}
                 </MenuItem>

               ))}

              </Select>
             </FormControl>
             <Button disabled={!mapMessage}variant='contained' color='primary' onClick={()=>{this.props.showmapprops()}} >Map Message</Button>
            
           
            
                </Toolbar>
<TableContainer
              component={Paper}
              style={{ margin: "0.5rem", width: "99%" }}
            >
<Table
                  size="small"
                  aria-label="a dense table"
                  name="fieldsTable"
                >

                  <TableHead>
                    <TableRow>
                    <TableCell width="5%" style={{ fontWeight: 700 }}>
                    <Checkbox {...label} />
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        
                        Usage Area
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                       
                      Message Type
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                       Message No.
                      </TableCell>

                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        Message Text
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        Available Translation
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                      <IconButton disabled={!editMessage}onClick={()=>{this.propertydata();this.props.changeshowtable();this.changeshowproperty()}}>
                 <AddIcon/>
             </IconButton>
             <IconButton onClick={()=>this.download()}>
             <FileDownloadOutlinedIcon />
               </IconButton>
               <IconButton disabled={!importMessage}variant="outlined" size="small" color="primary" style={{marginRight: '0.5rem'}} component="label"> <FileUploadOutlinedIcon/>
                      <input size="4"  accept=".xlsx, .xls, .csv" hidden type="file" name="importExcelFile" onChange={(e) => this.importExcelFile(e)} />
                </IconButton>
                       </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {this.props.messagetextproperty.map((option) => (
                    <TableRow key={option.id}>
                      {/* <TableCell >{row.application}</TableCell> */}

                      <TableCell> <Checkbox {...label} /></TableCell>
                      <TableCell>{option.usageArea}</TableCell>
                      <TableCell>{option.messageType}</TableCell>
                      <TableCell>{option.messageNumber}</TableCell>
                      <TableCell>{option.messageText}</TableCell>
                      <TableCell>{option.availableTranslations}</TableCell>

                      {/* <TableCell >{row.permissionType}</TableCell> */}

                     <TableCell> <IconButton
                          aria-label="Edit"
                          style={{ color: "green" }}
                          onClick={() => {this.editFields(option);this.props.changeshowtable();this.changeshowproperty()}}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                        <IconButton disabled={!editMessage}onClick={()=>{this.delete(option)}}><DeleteIcon/></IconButton></TableCell>
             
                    </TableRow>
                  ))}
                </TableBody>
                 </Table></TableContainer>
                
            </div>}
            {  this.props.showmessageDetails && <div ><IconButton onClick ={()=>{this.props.changeshowtable();this.changeshowproperty()}}><KeyboardArrowLeftIcon /></IconButton>
               <Toolbar style={{marginTop:'-3rem', marginLeft:'2rem'}}> <TextField  style={{marginRight:'3rem'}}variant='outlined' 
               name='usageArea'
               label='Usage Area'
               id='filled-disabled'
               size="small" 
               required
               onChange={(evt)=>this.changeDescription(evt)} disabled={!this.state.new} 
               value={this.state.usageArea}/>

<FormControl style={{ width: 180, marginLeft:'2rem' }} variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label" required>
             Message Type
              </InputLabel>
              <Select
                style={{width:'10rem'}}
                variant='outlined'
                //value={row.applicationId}
                // size="small"
                name='messagetype'
                onChange={(evt)=>this.changeDescription(evt)}
                value={this.state.messagetype} 
              >
            <MenuItem value="INFORMATION" key="1"  >INFORMATION</MenuItem>
            <MenuItem value="CONFIRMATION" key="2"  >CONFIRMATION</MenuItem>
            <MenuItem value="WARNING" key="3"  >WARNING</MenuItem>
            <MenuItem value="SUCCESS" key="4"  >SUCCESS</MenuItem>
            <MenuItem value="ERROR" key="5"  >ERROR</MenuItem>
            <MenuItem value="ABORT" key="6"  >ABORT</MenuItem>
            <MenuItem value="OTHERS" key="7"  >OTHERS</MenuItem>
            
                  
                 
              </Select>
             </FormControl>
             
             <TextField variant='outlined' id="filled-disabled" size="small"  label="Message No" disabled value={this.state.messageno} name="messageNumber"
               required/>
            <FormControl style={{width: 180 ,marginLeft:'2rem'}} variant="outlined" size="small">
             <InputLabel id="demo-simple-select-outlined-label" required>
               Search Language
             </InputLabel>
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               // size="small"
               name="language"
             
             
             > 
             {this.props.languages.map((option,index) => (
                 
                 <MenuItem
                   value={option.langaugeCode}
                    onClick={() =>  this.changelanguage(option)}
                  
                  
                 >
                   {option.languageDescription}
                 </MenuItem>
               ))}
              </Select>
             </FormControl>
             <IconButton  disabled={!editMessage}onClick={()=>this.adddata()}>
                 <AddIcon/>
             </IconButton>
             
             
             
             
             </Toolbar>
                      <TableContainer
              component={Paper}
              style={{ margin: "0.5rem", width: "99%" }}
            >
<Table
                  size="small"
                  aria-label="a dense table"
                  name="fieldsTable"
                >
                  <TableHead>
                    <TableRow>
                    <TableCell width="5%" style={{ fontWeight: 700 }}>
                    <Checkbox {...label} />
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        
                       Language
                      </TableCell>
                      <TableCell width="25%" style={{ fontWeight: 700 }}>
                       
                      Message Text
                      </TableCell>
                     
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        Status
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                         Translation Type
                       </TableCell>
                       <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {this.state.detaileddata.map((option,index) => (
                    <TableRow key={option.id}>
                      {/* <TableCell >{row.application}</TableCell> */}
                        {/* { v=} */}
                      <TableCell> <Checkbox {...label} /></TableCell>
                      <TableCell>
                 { option.languageCode?null:<InputLabel id="demo-simple-select-outlined-label" required>
               Choose Language
             </InputLabel>}
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               // size="small"
               name="languageCode"
               value={option.languageCode?this.props.languages.filter(ele=> ele.langaugeCode===option.languageCode)[0].languageDescription :null}
             > 
             {this.props.languages.map((option1,index1) => (
                 
                 <MenuItem
                   value={option1.languageDescription}
                    onClick={(evt) =>  this.changedetaillanguage(evt,option,index)}
                  
                  
                 >
                   {option1.languageDescription}
                 </MenuItem>
               ))}
              </Select>
              </TableCell>
                      <TableCell>    <TextField variant='outlined'  id='filled-disabled'
               size="small" 
                multiline required  sx="small"  style={{padding:'0 0 0 0'}}value={option.messageText}
                onChange={(evt)=>this.changedetails(evt,option,index)} name="messageText"/></TableCell>
                      <TableCell style={{color:'red'}}>{option.status}</TableCell>

                    

                     <TableCell> {option.translationType} </TableCell> 
                     <TableCell> <IconButton  disabled={!editMessage}onClick={()=>{this.deletemessage(option)}}><DeleteIcon/></IconButton>
             </TableCell>
             
                    </TableRow>
                  ))}
                </TableBody>
                 </Table></TableContainer>
                     
             
                      
                
                
                
                
                </div>}
                {
             this.props.showmessageDetails &&    <div className="footerdiv" style={{position:"fixed", right:"10px" ,bottom:"10px"}}>
                    <Button
                      style={{ margin: "0 0.5rem" }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick ={()=>{this.props.changeshowtable();this.changeshowproperty()}}
                    >
                      Cancel
                    </Button>
                    <Button
                     style={{ margin: "0 0.5rem" }}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!editMessage}
                      onClick={() => this.savethedata()}
                    >
                      Save
                    </Button>
                    
                    
                  </div>}
              
                <MessageBoxComponentSave
                  title="save"
                  open={this.state.showDialogSave}
                  actions={["SAVE", "CANCEL"]}
                  onClose={(evt)=>this.closeDialog(evt)}
                />
            </div>
        );
    }
}



export default CwMessagetext;