import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Entity from './CwMSEntity';
import Emailtemplate from './CwMSEmailtemplate';


class CwMSEmailtemplate extends React.Component {

    constructor(props) {
        super(props);
        this.settingsPopoverRef = React.createRef();
        this.state = {
            value: "Email template",
            showEmailentity:false,
            showemailtemplate:false,
            fieldCatalogs: [{
                attributeId: "APP",
                createdBy: "P000032",
                createdOn: 1619198922671,
                dataType: "NVARCHAR",
                description: "Application ID",
                destinations: null,
                isLookup: false,
                label: "Application ID",
                length: "100",
                lookUpId: "67f8943-c47a-425d-9f9a-06e4ef996bu",
                lookUpType: "API",
                lookupConfig: null,
                name: "APP",
                source: "WR",
                updatedBy: "P000032",
                updatedOn: 1619198922671,
                propertyDto: {
                    isMandatory: true,
                    isVisible: true,
                    isNullable: true,
                    defaultValue: "abc",
                    isFilterable: true,
                    isSortable: true,
                    isEditable: true
                },
                workText: true
            }],
            dataElement: [{
                attributeId: "ADD",
                createdBy: "P000032",
                createdOn: 1619198922671,
                dataType: "NVARCHAR",
                description: "Address",
                destinations: null,
                isLookup: true,
                label: "Address",
                length: "100",
                lookUpId: "67f8943-c47a-425d-9f9a-06e4ef996bu",
                lookUpType: "VL",
                lookupConfig: null,
                name: "ADD",
                source: "WR",
                updatedBy: "P000032",
                updatedOn: 1619198922671,
               
            }]
        };
    }
    applicationChangeHandler=(event)=>{
        if(event.target.value==="Email templete") this.setState({
            ...this.state,
            showEmailentity: false,
            showemailtemplate:true,
        });
        else   this.setState({
            ...this.state,
            showEmailentity: true,
            showemailtemplate:false,
        });
    }
    handleChange = (event, newValue) => {
        this.setState({
            ...this.state,
            value: newValue
        });
    };



    goToMsgComp = event => {
        this.props.history.push('/messageSummary');
    }

    goToThemeComp = event => {
        this.props.history.push('/themeStyling');
    }
    render() {

        // const userAccess = this.props.userAccess;
        // const modellingEntity = userAccess.entities.find(ele => ele.name === "Modelling") ? true : false;
        // const userFormsEntity = userAccess.entities.find(ele => ele.name === "UserForms") ? true : false;
        // const configurationEntity = userAccess.entities.find(ele => ele.name === "Configuration") ? true : false;
        // const rulesEntity = userAccess.entities.find(ele => ele.name === "Rules") ? true : false;

        return (
            <div>
 <Grid container style={{margin:"-0.5rem 0rem 0rem -1rem"}}>
<Grid item xs={8} sm={3} md={4}>
            <FormControl style={{ width: 180,marginTop:'1rem', marginLeft:'2rem' }} variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label" required>
               Email Toolbar
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                //value={row.applicationId}
                // size="small"
                label="Email Toolbar"
                name="emailconfig"
               onChange={(evt)=>this.applicationChangeHandler(evt)}
              
              >
            <MenuItem value="Email templete" key="1"  >Email template</MenuItem>
            <MenuItem value="Entity/Variable Definition" key="2"  >Entity/Variable Definition</MenuItem>
            
                  
                  {/* <MenuItem
                   
                //   key={option.id}
                    
                   
                  >
                    
                  </MenuItem> */}
               
              </Select>
            
            </FormControl>
           
          </Grid>
          
        
                {/* <TabContext value={this.state.value}>
                    <AppBar position="static">
                        <TabList onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Email template" value="Email template" />
                            <Tab label="Entity/Variable Definition" value="Entity/Variable Definition" />
                           

                        </TabList>
                    </AppBar>
                    <TabPanel value="Email template">
                        < Emailtemplate {...this.props} fieldCatalogs={this.state.dataElement}/>
                        
                    </TabPanel>
                <TabPanel value="Entity/Variable Definition">
                        <Entity {...this.props} />
                    </TabPanel>

                    

                </TabContext> */}
               </Grid>
               {this.state.showEmailentity &&
          <Entity {...this.props} />}
           {this.state.showemailtemplate &&
             < Emailtemplate {...this.props} fieldCatalogs={this.state.dataElement}/>
         }
            </div>
        );
    }
}

export default withRouter(CwMSEmailtemplate);