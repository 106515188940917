import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import InfoSharp from '@material-ui/icons/InfoSharp';
import ValueHelp from './ValueHelp';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import MessageBoxComponentDiscard from "./CwWfDiscardBasicInfo";
// import MessageBoxComponentSubmit from "./CwWfSubmitConfirmation";
import MessageBoxComponentSave from "./CwWfSaveConfirmation";
import ValueHelpDialog from './valueHelpDialog';
import ValueHelpDynamicDialog from './valueHelpDynamicDialog';
import Snackbar from '@mui/material/Snackbar';

class CwMSFields extends React.Component {

    constructor(props) {
        super(props);
        this.valuehelpref = React.createRef();
        let emptyRow = this.emptyRow();
        this.state = {
            dataElement: [],
            showValueHelpDynamicDialog: false,
            showDialog: false,
            showDialogDiscard: false,
            showDialogSubmit: false,
            showDialogSave: false,
            showValueHelpDialog: false,
            showVHTableDialog: false,
            anchorEl: null,
            setAnchorEl: null,
            open: false,
            value: "ValueHelp",
            tableVisible: true,
            selectedRow: emptyRow,
            constraints: [],
            fields: [],
            // staticVL: [],
            errorMssg: "",
            validLength: false,
            dataTypes: [{
                id: "NVARCHAR",
                name: "NVARCHAR"
            }, {
                id: "BOOLEAN",
                name: "BOOLEAN"
            }, {
                id: "INTEGER",
                name: "INTEGER"
            }, {
                id: "DECIMAL",
                name: "DECIMAL"
            }, {
                id: "DATE",
                name: "DATE"
            }, {
                id: "TIMESTAMP",
                name: "TIMESTAMP"
            }, {
                id: "FILE",
                name: "FILE"
            }],
            apiMetadata: {
                "url": "",
                "destination": "",
                "apiType": "",
                "resultPath": null,
                "constraints": null,

            },
            dbMetadata: {},
            fileMetadata: {
                fileType: null,
                properties: {
                    "parentTag": null,
                    "sheets": []
                }
            },
            valueList: [],
            data: {
                "lookupId": "67f8943-c47a-425d-9f9a-06e4ef996bu",
                "lookupName": "APP",
                "lookupType": "API",
                "dbMetadata": null,
                "apiMetadata": {
                    "url": "/rest/v2/entity/application",
                    "destination": "workrule",
                    "apiType": "Rest",
                    "resultPath": null,
                    "fields": [],
                    "constraints": null,
                    "pagination": {
                        "allowed": true,
                        "recordsPerPage": "30"
                    }
                },
                "valueList": null
            },
            existing: false,
            snackbarOpen: false,
            snackbarMessg: "",
            dialogData: [],
            lookupType: "VL",
            destinations: [],
            sheets: [],
            fieldCatalogArray: [],
            compositeArray: [],
            dynamicColumn: [],
            rows: []
        };
    }
    componentDidMount(prevProps) {
        this.getDataElementList();
        if (this.props.fromFieldCatalog) {
            let emptyRow = this.emptyRow();
            this.setState({
                ...this.state,
                tableVisible: false,
                selectedRow: emptyRow

            })
        }
    }
    getDataElementList = () => {
        let url;
        url = "/workbox-field-catalog/v1/data-element"
        fetch(url,{headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state, dataElement: result.data ? result.data : []
                });

            })
    }
    getAttributeList = () => {
        let url;

        url = "/workbox-field-catalog/v1/attribute-master?applicationId=479f37ce-40ec-4bba-b428-426f582da75d"
        fetch(url,{headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state,
                    // selectedApplication: "479f37ce-40ec-4bba-b428-426f582da75d",
                    fieldCatalogArray: result.data ? result.data : []
                });

            })
    }
    // componentDidUpdate(prevProps) {
    //     if(this.props.fromFieldCatalog){
    //         this.setState({
    //             ...this.state,
    //             tableVisible: false,
    //             selectedRow:this.emptyRow()

    //         })
    //     }
    // }

    emptyRow = () => {

        const selectedRow = {
            attributeId: "",
            createdBy: "",
            createdOn: null,
            dataType: "",
            description: "",
            destinations: null,
            isLookup: false,
            label: "",
            length: "",
            lookUpId: "",
            lookUpType: "",
            lookupConfig: null,
            name: "",
            source: "",
            updatedBy: "",
            updatedOn: null,

        };
        return selectedRow;
    };
    // state = {
    //     tableVisible: true,

    // };
    handlePopoverOpen = (event) => {
        // setAnchorEl(event.currentTarget);
        this.setState({
            ...this.state,
            anchorEl: event.currentTarget,
            open: true
        });
    };

    handlePopoverClose = () => {
        // setAnchorEl(null);
        this.setState({
            ...this.state,
            anchorEl: null,
            open: false
        });
    };
    createNewField = (event, newValue) => {
        this.setState({
            ...this.state,
            tableVisible: false,
            tagged: [],
            constraints: [],
            compositeArray: [],
            valueList: [],
            apiMetadata: [],
            lookupType: "VL",
            attributeLapiDataInput: false
        });
    };
    editFields = (row) => {
        this.setState({
            ...this.state,
            existing: true,
            lookupType: row.lookupType,
            tableVisible: false,
            selectedRow: row,
            fields: [],
        });
        if (row.isLookup && row.lookupId) {
            let url = "/workbox-field-catalog/v1/lapi?lookupId=" + row.lookupId
            fetch(url, {headers:{"Authorization":this.props.authorization}})
                .then((res) => res.json())
                .then((result) => {
                    let valueList, constraints, fields, sheets, apiMetadata = {}, fileMetadata = {};
                    if (result.data.lookupType === "VL") {
                        valueList = result.data.valueList;
                        this.setState({
                            ...this.state,
                            valueList: valueList
                        });
                    } else if (result.data.lookupType === "API") {
                        constraints = result.data.apiMetadata.constraints;
                        fields = result.data.apiMetadata.fields;
                        apiMetadata = {
                            "url": result.data.apiMetadata.url,
                            "destination": result.data.apiMetadata.destination,
                            "apiType": result.data.apiMetadata.apiType,
                            "resultPath": result.data.apiMetadata.resultPath,
                        }
                        this.setState({
                            ...this.state,
                            constraints: constraints ? constraints : [],
                            fields: fields,
                            apiMetadata: apiMetadata

                        });
                    } else if (result.data.lookupType === "FILE") {
                        constraints = result.data.fileMetadata.constraints;
                        fields = result.data.fileMetadata.fields;
                        sheets = result.data.fileMetadata.properties.sheets
                        fileMetadata = {
                            "fileType": result.data.fileMetadata.fileType.toLowerCase(),
                            "properties": result.data.fileMetadata.properties,
                        }
                        this.setState({
                            ...this.state,
                            constraints: constraints ? constraints : [],
                            fields: fields,
                            sheets: sheets ? sheets : [],
                            fileMetadata: fileMetadata

                        });
                    } else if (result.data.lookupType === "COMPOSITE") {
                        constraints = result.data.compositeMetadata.constraint;

                        var constraint = [];
                        if (constraints) {
                            constraint.push(constraints);
                        }
                        var constraintColumn = result.data.compositeMetadata.constraint.constraintColumn;
                        var fieldCatalogArray = this.state.fieldCatalogArray;
                        var Data = {};
                        fieldCatalogArray.filter(function (element) {
                            if (element.name === constraintColumn) {
                                Data = element;
                            }
                        });
                        var constaintLookups = result.data.compositeMetadata.constaintLookups;

                        if (constaintLookups) {
                            for (var i = 0; i < constaintLookups.length; i++) {
                                constaintLookups[i]["value"] = constaintLookups[i].constraintValue;
                            }


                        }

                        this.setState({
                            ...this.state,
                            constraints: constraint,
                            lookupType: "COMPOSITE",
                            valueList: result.data.valueList ? [] : [],
                            apiMetadata: result.data.apiMetadata ? [] : [],
                            compositeArray: constaintLookups,
                            attributeLapiDataInput: Data.metaData.isLookup
                        });
                        this.getLapiData(Data.metaData);
                    }

                })
        } else if (row.isLookup && row.lookupId && row.lookupType === "FILE") {
            this.getFileLookUp(row);
        }
    };
    getFileLookUp = (row) => {
        let url = "/workbox-field-catalog/v1/lapi?lookupId=" + row.lookupId
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {
                let valueList, constraints, fields, apiMetadata = {};
                if (result.data.lookupType === "VL") {
                    valueList = result.data.valueList;
                    this.setState({
                        ...this.state,
                        valueList: valueList
                    });
                } else if (result.data.lookupType === "API") {
                    constraints = result.data.apiMetadata.constraints;
                    fields = result.data.apiMetadata.fields;
                    apiMetadata = {
                        "url": result.data.apiMetadata.url,
                        "destination": result.data.apiMetadata.destination,
                        "apiType": result.data.apiMetadata.apiType,
                        "resultPath": result.data.apiMetadata.resultPath,
                    }
                    this.setState({
                        ...this.state,
                        constraints: constraints ? constraints : [],
                        fields: fields,
                        apiMetadata: apiMetadata

                    });
                }


            })
    }
    // {
    //     "id": "8366f2d0-27f4-4fe3-8f5b-b27009f029d7",
    //     "columnName": "APP",
    //     "displayName": "Application ID",
    //     "searchable": true,
    //     "isDisplayName": false,
    //     "mappedName": "APP"
    // }, {
    //     "id": "8654b498-740c-449a-98b0-ec49c09c2366",
    //     "columnName": "NAME",
    //     "displayName": "Name",
    //     "searchable": true,
    //     "isDisplayName": true,
    //     "mappedName": "NAME"
    // }, {
    //     "id": "4b9cc30f-2939-4b6d-ac25-558d87cce164",
    //     "columnName": "DESCRIPTION",
    //     "displayName": "Description",
    //     "searchable": true,
    //     "isDisplayName": false,
    //     "mappedName": "DESCRIPTION"
    // }, {
    //     "id": "6af6152a-80bf-4960-a6a2-f3f3cc0efd66",
    //     "columnName": "LABEL",
    //     "displayName": "Label",
    //     "searchable": true,
    //     "isDisplayName": false,
    //     "mappedName": "LABEL"
    // }
    backToTable = (event) => {
        let emptyRow = this.emptyRow();
        this.setState({
            ...this.state,
            existing: false,
            tableVisible: true,
            selectedRow: emptyRow
        });
    };
    onValueHelpSwitch = (event) => {
        let state = event.target.checked;
        // this.state.selectedRow.isLookup=state;
        this.setState({
            ...this.state,
            selectedRow: {
                ...this.state.selectedRow,
                isLookup: state,
                metaData: {
                    ...this.state.selectedRow.metaData,
                    isLookup: state
                }

            }
        });
    };
    getDestinationList = () => {
        
        let url;

        url = "/workbox-field-catalog/v1/lapi/destination"
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state, destinations: result.data ? result.data : []
                });

            })
    }
    getDataTableList = () => {
        
        let url;

        url = "workbox-field-catalog/v1/lapi/databaseValuehelp"
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state, dataTable: result.data ? result.data : []
                });

            })
    }
    handleChangeValueHelpType = (event) => {
        let state = event.target.value;
        let fields = [];
        // this.state.selectedRow.isLookup=state;

        if (event.target.value !== "VL") {
            if (event.target.value === "DB" || event.target.value === "API") {
                this.getDestinationList();
                this.getDataTableList();
            }
            this.getAttributeList();
            // if (this.state.fields.length === 0) {
            //     fields = [
            //         {
            //             "columnName": this.state.selectedRow.name,
            //             "displayName": this.state.selectedRow.label,
            //             "id": null,
            //             "isDisplayName": true,
            //             "mappedName": null,
            //             "searchable": true
            //         }
            //     ]

            // } else {
            //     fields = this.state.fields;
            //     fields[0].columnName = this.state.selectedRow.name
            //     fields[0].displayName = this.state.selectedRow.label
            // }
            // this.setState({
            //     ...this.state,
            //     fields: fields
            // })
        }
        this.setState({
            ...this.state,
            // fields: fields,
            lookupType: state,
            selectedRow: {
                ...this.state.selectedRow,
                lookUpType: state,
                constraints: [],
                compositeArray: [],
                attributeLapiDataInput: false

            }
        });


    };
    inputChangeHandler = (evt) => {
        let value = evt.target.value, isValid = true, errorMssg = "", isValidObj = {}, lengthVal;
        if (evt.target.name === "name") {
            value = value.toUpperCase();

        } else if (evt.target.name === "length" && (this.state.selectedRow.dataType === "NVARCHAR" || this.state.selectedRow.dataType === "INTEGER")) {
            isValid = this.integerValidation(value);
            if (!isValid)
                errorMssg = "Please enter Integer value";


        } else if (evt.target.name === "length" && this.state.selectedRow.dataType === "DECIMAL") {
            isValidObj = this.lengthValidation(value);
            isValid = isValidObj.isValid;
            errorMssg = isValidObj.errorMssg;
            if (value.split(",")[1] === "") {
                value = value.split(",")[0] + ",";
            }



        }
        if (evt.target.name === "dataType") {
            lengthVal = "";
            this.setState({
                ...this.state,
                validLength: !isValid,
                errorMssg: errorMssg,
                selectedRow: {

                    ...this.state.selectedRow,
                    length: lengthVal,
                    [evt.target.name]: value

                }
            })
        } else {

            //this.state.obj[evt.target.name] = evt.target.value;

            this.setState({
                ...this.state,
                validLength: !isValid,
                errorMssg: errorMssg,
                selectedRow: {
                    ...this.state.selectedRow,
                    [evt.target.name]: value

                }
            })
        }




    };
    inputChangeHandler1 = (evt, val) => {
        let value = evt.target.value, key, name;
        let fileTypeProp;
        name = evt.target.name;
        if (val) {
            value = val.name;
            name = evt.target.id.split("-")[0];
        }
        if (this.state.lookupType === "API") {
            key = "apiMetadata";
        } else if (this.state.lookupType === "DB") {
            key = "dbMetadata";
        } else if (this.state.lookupType === "FILE") {
            key = "fileMetadata";
            fileTypeProp = {
                "parentTag": this.state.fileMetadata.properties.parentTag,
                "sheets": this.state.sheets
            };
            if (name === "parentTag") {
                fileTypeProp.parentTag = value;
            }

            // if (value === ".csv") {
            //     fileTypeProp = {
            //         "parentTag": null,
            //         "sheets": null
            //     };
            // } else if (value === ".xml") {
            //     fileTypeProp = {
            //         "parentTag": "",
            //         "sheets": null

            //     }
            // } else if (value === ".xlsx") {
            //     fileTypeProp = {
            //         "parentTag": null,
            //         "sheets": []
            //     }
            // }
        }
        if (this.state.lookupType !== "FILE") {
            this.setState({
                ...this.state,
                [key]: {
                    ...this.state[key],

                    [name]: value

                }
            })
        } else {
            this.setState({
                ...this.state,
                [key]: {
                    ...this.state[key],
                    [name]: value,
                    properties: fileTypeProp

                }
            })
        }
    }
    sheetsInputs = (evt, index) => {
        let value, name;
        name = evt.target.name
        value = evt.target.value;
        let sheets = [...this.state.sheets];
        if (evt.target.name === "sheetIndex") {
            if (parseInt(value)) {
                name = "sheetIndex";
                sheets[index].sheetName = null;
            } else {
                name = "sheetName";
                sheets[index].sheetIndex = null;
            }
        }
        sheets[index][name] = value;
        this.setState({
            ...this.state,
            sheets: sheets
        })
    }

    valueHelpTableInputs = (evt, index) => {
        
        let value;
        if (evt.target.name !== "isDisplayName" && evt.target.name !== "searchable") {
            value = evt.target.value;
        } else {
            value = evt.target.checked;
        }
        if (this.state.lookupType === "API" || this.state.lookupType === "DB" || this.state.lookupType === "FILE" || this.state.lookupType === "COMPOSITE") {
            if (evt.target.id === "fieldsTable") {
                let fields = [...this.state.fields];
                if (evt.target.name == "isDisplayName") {
                    fields.filter(function (element) {
                        element.isDisplayName = false;
                    });
                }

                fields[index][evt.target.name] = value;
                if (evt.target.name === "columnName" && evt.target.row) {
                    fields[index]["displayName"] = evt.target.row.label
                }
                this.setState({
                    ...this.state,
                    fields: fields,
                    showVHTableDialog: false
                })
            } else if (evt.target.id === "constraintTable") {
                let constraints = [...this.state.constraints];

                constraints[index][evt.target.name] = value;
                if (evt.target.name === "constraintColumn" && evt.target.row) {
                    constraints[index]["constraintName"] = evt.target.row.label
                }
                this.setState({
                    ...this.state,
                    constraints: constraints,
                    showVHTableDialog: false
                })
            }
        } else if (this.state.lookupType === "DB") {

        } else if (this.state.lookupType === "VL") {
            let valueList = [...this.state.valueList];
            valueList[index][evt.target.name] = value;
            this.setState({
                ...this.state,
                valueList: valueList
            })

        }

    }
    integerValidation = (length) => {
        const numberRegEx = /^[0-9]*$/;
        return numberRegEx.test(String(length).toLowerCase());
    }
    lengthValidation = (length) => {
        var a, b, errorMssg = "";

        var bValid;
        var aVal = length.split(",");
        if (aVal) {
            var a1 = this.integerValidation(aVal[0]);
            var b1 = this.integerValidation(aVal[1]);
        }
        if (a1 && b1) {
            bValid = true;
            a = aVal[0];
            b = aVal[1] ? aVal[1] : 0;

        } else {
            bValid = false;
        }
        if (bValid) {
            var iInt = parseInt(a);
            var iDec = parseInt(b);

            if (iInt > iDec && iInt <= 25 && iDec <= 7) {
                return {
                    isValid: true,
                    errorMssg: ""
                };
            } else if (iInt < iDec) {
                errorMssg = "Total length less than number of decimal places!";
                return {
                    isValid: false,
                    errorMssg: errorMssg
                };

            } else if (iInt > 25) {
                errorMssg = "Integer part should be <= 25.";
                return {
                    isValid: false,
                    errorMssg: errorMssg
                };

            } else if (iDec > 7) {
                errorMssg = "Decimal part should be <= 7";
                return {
                    isValid: false,
                    errorMssg: errorMssg
                };

            }
        } else {


            errorMssg = "Not Valid Format!";
            return {
                isValid: false,
                errorMssg: errorMssg
            };
        }


    }
    addNewConstraint = (event) => {
        let constraints = [...this.state.constraints];
        let obj = {
            "constraintColumn": null,
            "constraintName": null,
            "constraintOperator": "EQ",
            "constraintType": null,
            "constraintValue": null,
            "id": null,
            "mappedName": null
        };

        constraints.push(obj);
        this.setState({
            ...this.state,
            constraints: constraints,
        });
    };
    addNewFields = (event) => {
        let fields = [...this.state.fields];
        let obj = {
            "id": "",
            "columnName": "",
            "displayName": "",
            "searchable": true,
            "isDisplayName": false,
            "mappedName": "",
            "tempId": fields.length - 1
        };

        fields.push(obj);
        this.setState({
            ...this.state,
            fields: fields,
        });
    };
    deleteFieldRows = (row, index) => {

        let fields = [...this.state.fields];
        //  let i;
        // if (row) {
        //     fields.filter(function (element, index) {
        //         if (element.id === row) {
        //             i = index;
        //         }
        //     });
        // }else{
        //     fields.filter(function (element, index) {
        //         if (element.tempId === tempId) {
        //             i = index;
        //         }
        //     });
        // }
        fields.splice(index, 1);
        this.setState({
            ...this.state,
            fields: fields,
        });

    }
    deleteConstraintRows = (row, index) => {
        let constraints = [...this.state.constraints];




        constraints.splice(index, 1);
        this.setState({
            ...this.state,
            constraints: constraints,
        });

    }
    addNewStaticVL = (event) => {
        let staticVL = [...this.state.valueList];
        let obj = {
            key: "",
            value: "",
            additionalValue: "",

        };

        staticVL.push(obj);
        this.setState({
            ...this.state,
            valueList: staticVL,
        });
    };
    deleteStaticVL = (row, index) => {
        let fields = [...this.state.valueList];
        fields.splice(index, 1);
        this.setState({
            ...this.state,
            valueList: fields,
        });

    }
    closeDialog = (action) => {
        if (action === "DISCARD") {
            //   this.discardMappingRoles();
        }
        else if (action === "SUBMIT") {
            //   this.onSaveMappingRoles();
        }
        else if (action === "SAVE") {
            this.onSaveDataelements();
        }

        this.setState(
            {
                ...this.state,
                showDialog: false,
                showDialogDiscard: false,
                showDialogSubmit: false,
                showDialogSave: false,
                showValueHelpDialog: false,
                showDataElementDialog: false,
                showVHTableDialog: false,
                showValueHelpDynamicDialog: false
            });

    }
    onSaveDataelements = () => {
        let createdOn, createdBy, method, url;
        var dDate = new Date().getTime()
        if (this.state.existing) {
            createdOn = this.state.selectedRow.createdOn;
            createdBy = this.state.selectedRow.createdBy;
            method = "PATCH";
            url = "/workbox-field-catalog/v1/data-element/" + this.state.selectedRow.dataElementId

        } else {
            createdOn = dDate;
            createdBy = this.props.pid;
            method = "POST"
            url = "/workbox-field-catalog/v1/data-element"


        }

        const payload = {
            "createdBy": createdBy,
            "createdOn": createdOn,
            "dataElementId": this.state.selectedRow.dataElementId,
            "dataType": this.state.selectedRow.dataType,
            "description": this.state.selectedRow.description,
            "isLookup": this.state.selectedRow.isLookup,
            "label": this.state.selectedRow.label,
            "length": this.state.selectedRow.length,
            "lookupId": this.state.selectedRow.lookupId,
            "name": this.state.selectedRow.name,
            "updatedBy": this.props.pid,
            "updatedOn": dDate
        }
        var that = this;
        const requestParam1 = {
            method: method,
            headers: { "Content-Type": "application/json", "Authorization":this.props.authorization},
            body: JSON.stringify(payload),
            
        }
        fetch(url, requestParam1)
            .then((response) => response.json())
            .then((data, message) => {
                console.log(data);
                if (this.state.selectedRow.isLookup) {
                    this.fnLookupsave(data.data);
                }
                this.getDataElementList();
                this.setState({
                    ...this.state,
                    tableVisible: true,
                    snackbarOpen: true,
                    snackbarMessg: 'Data Element is Saved!'

                })
            }
            )

    }
    fnLookupsave = (data) => {
        let method, lookupType = this.state.lookupType, valueList, oPayload, sUrl;
        if (data.lookupId) {
            method = "PATCH"
            sUrl = "/workbox-field-catalog/v1/lapi/" + data.lookupId + "?objectId=" + data.dataElementId + "&objectType=DE"
        } else {
            method = "POST";
            sUrl = "/workbox-field-catalog/v1/lapi" + "?objectId=" + data.dataElementId + "&objectType=DE";
        }
        if (lookupType === "VL") {
            oPayload = {
                "lookupId": data.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "valueList": this.state.valueList
            }
        } else if (lookupType === "API") {

            oPayload = {
                "lookupId": data.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "apiMetadata": {
                    "apiType": this.state.apiMetadata.apiType,
                    "constraints": this.state.constraints,
                    "destination": this.state.apiMetadata.destination,
                    "fields": this.state.fields,
                    "pagination": {
                        "allowed": true,
                        "recordsPerPage": "30"
                    },
                    "resultPath": this.state.apiMetadata.resultPath,
                    "url": this.state.apiMetadata.url
                },

            }
        } else if (lookupType === "DB") {
            oPayload = {
                "lookupId": data.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "dbMetadata": {
                    "constraints": this.state.constraints,
                    "databasePropertiesDto": {
                        "dbType": "string",
                        "lookupId": "string",
                        "password": "string",
                        "url": "string",
                        "userName": "string"
                    },
                    "destination": this.state.apiMetadata.destination,
                    "fields": this.state.fields,
                    "lookupTable": "string",
                    "pagination": {
                        "allowed": true,
                        "recordsPerPage": "30"
                    }
                },

            }
        } else if (lookupType === "FILE") {
            let sheets = [...this.state.sheets]
            oPayload = {
                "lookupId": data.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "fileMetadata": {
                    "constraints": this.state.constraints,
                    "fields": this.state.fields,
                    "fileType": this.state.fileMetadata.fileType.toUpperCase(),
                    "lookupId": data.lookupId,
                    "properties": {
                        "parentTag": this.state.fileMetadata.properties.parentTag,
                        "sheets": this.state.sheets
                    }
                },
            }
        }
        const requestParam1 = {
            method: method,
            headers: { "Content-Type": "application/json", "Authorization":this.props.authorization },
            body: JSON.stringify(oPayload),
        }
        fetch(sUrl, requestParam1)
            .then((response) => response.json())
            .then((data, message) => {
                console.log(data);

                this.setState({
                    ...this.state,
                    snackbarOpen: true,
                    snackbarMessg: 'Data Element is Saved!'

                })
            }
            )
        // let payload = {

        //     "compositeMetadata": {
        //         "constaintLookups": [
        //             {
        //                 "constraintValue": "string",
        //                 "id": "string",
        //                 "isDefaulted": true,
        //                 "lookupId": "string",
        //                 "referenceId": "string"
        //             }
        //         ],
        //         "constraint": {
        //             "constraintColumn": "string",
        //             "constraintName": "string",
        //             "constraintOperator": "string",
        //             "constraintType": "string",
        //             "constraintValue": "string",
        //             "id": "string",
        //             "mappedName": "string"
        //         }
        //     },

        //     "fileMetadata": {
        //         "constraints": [
        //             {
        //                 "constraintColumn": "string",
        //                 "constraintName": "string",
        //                 "constraintOperator": "string",
        //                 "constraintType": "string",
        //                 "constraintValue": "string",
        //                 "id": "string",
        //                 "mappedName": "string"
        //             }
        //         ],
        //         "fields": [
        //             {
        //                 "columnName": "string",
        //                 "displayName": "string",
        //                 "id": "string",
        //                 "isDisplayName": true,
        //                 "mappedName": "string",
        //                 "searchable": true
        //             }
        //         ],
        //         "fileType": "string",
        //         "lookupId": "string"
        //     },
        //     "lookupId": data.lookupId,
        //     "lookupName": "",
        //     "lookupType": lookupType,

        // }
    }
    onCancel = () => {
        // this.props.history.push("/roles");
        // this.props.setNameVis(false);
        this.setState({
            ...this.state,

            showDialogDiscard: true

        })
    }

    onSaveDataElement = () => {
        var payload1 = this.state.rolesData;
        console.log(payload1);
        this.setState({
            ...this.state,

            showDialogSave: true

        })
    }
    addSheets = (event) => {
        var sheet = this.state.sheets;
        let obj = {
            "sheetName": null,
            "sheetIndex": null,
            "rowStart": null,
            "columnStart": null
        }
        sheet.push(obj);
        this.setState({
            ...this.state,

            sheets: sheet

        })
    }
    deleteSheet = (evt, index) => {
        let sheets = [...this.state.sheets];

        sheets.splice(index, 1);
        this.setState({
            ...this.state,
            sheets: sheets,
        });
    }

    goToThemeComp = event => {
        this.props.history.push('/themeStyling');
    }
    fnAttrValueHelp = (index, evt, table) => {

        this.setState({
            ...this.state,
            showVHTableDialog: true,
            index: index,
            tableType: table

        });

    }
    addComposite = () => {
        var sheet = this.state.sheets;
        let obj = {
            "value": null,
            "lookupType": null,
        }
        sheet.push(obj);
        this.setState({
            ...this.state,

            compositeArray: sheet

        })
    }
    // rowSelectedValueHelp = (row) => {
    //     let evt = {
    //         target: {
    //             row: row,
    //             value: row.name,
    //             id: "constraintTable",
    //             name: "constraintColumn"
    //         }
    //     }
    //     this.setState({
    //         ...this.state,
    //         attributeLapiDataInput: row.metaData.isLookup
    //     })
    //     this.valueHelpTableInputs(evt, this.state.index)

    //     if (this.state.lookupType === "COMPOSITE" && row.metaData.isLookup) {
    //         this.getLapiData(row.metaData);
    //     }

    // }
    rowSelectedDynamic = (row, i) => {
        let key = row[this.state.selectedField];
        let arr = [...this.state.compositeArray];
        if (!key) {
            key = row[this.state.dynamicColumn[0].mappedName]
        }
        let value;
        var that = this;
        this.state.dynamicColumn.filter(function (element) {
            if (element.isDisplayName) {
                arr[that.state.compositeArrayIndex].value = row[element.mappedName]
            }
        });


        arr[this.state.compositeArrayIndex].key = key

        this.setState({
            ...this.state,
            showValueHelpDynamicDialog: false,
            compositeArray: arr

        })
    }
    rowSelectedValueHelp = (row) => {
        if (this.state.tableType === "constraintTable") {
            let evt = {
                target: {
                    row: row,
                    value: row.name,
                    id: "constraintTable",
                    name: "constraintColumn"
                }
            }
            this.setState({
                ...this.state,
                selectedField: row.name,
                attributeLapiDataInput: row.metaData.isLookup
            }, () => this.valueHelpTableInputs(evt, this.state.index))
        } else {
            let evt = {
                target: {
                    row: row,
                    value: row.name,
                    id: "fieldsTable",
                    name: "columnName"
                }
            }

            this.valueHelpTableInputs(evt, this.state.index)


        }

        if (this.state.lookupType === "COMPOSITE" && row.metaData.isLookup) {
            this.getLapiData(row.metaData);
        }

    }
    rowSelectedValueHelpFields = (row) => {
        let evt = {
            target: {
                row: row,
                value: row.name,
                id: "fieldsTable",
                name: "columnName"
            }
        }
        // this.setState({
        //     ...this.state,
        //     selectedField: row.name,
        //     attributeLapiDataInput: row.metaData.isLookup
        // })
        this.valueHelpTableInputs(evt, this.state.index)

        if (this.state.lookupType === "COMPOSITE" && row.metaData.isLookup) {
            this.getLapiData(row.metaData);
        }

    }
    getLapiConfig = (row) => {
        let sUrl = "/workbox-field-catalog/v1/lapi?lookupName=" + row.name
        fetch(sUrl, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {
                if (result.data.lookupType.toUpperCase() === "VL") {
                    this.setState({
                        ...this.state,
                        valueList: result.valueList
                    })
                } else {
                    this.getLapiData(result)
                }



            })
    }
    // getLapiData = (data) => {
    //     let sUrl = "/workrules/v1/lapi/data?lookupId=" + data.lookupId

    //     fetch(sUrl)
    //         .then((res) => res.json())
    //         .then((result) => {
    //             if (data.lookupType === "API") {

    //             }



    //         })
    // }
    getLapiData = (data) => {
        console.log(data, "data");
        if (data.lookupId && data.isLookup === true) {
            let sUrl = "workbox-field-catalog/v1/lapi/data?lookupId=" + data.lookupId

            fetch(sUrl, {headers:{"Authorization":this.props.authorization}})
                .then((res) => res.json())
                .then((result) => {
                    let dynamicColumn, row;
                    console.log(result);
                    if (result.data.metaData) {
                        dynamicColumn = result.data.metadata;
                        row = result.data.values;
                    } else {
                        row = result.data.values;
                        dynamicColumn = [{
                            "id": null,
                            "columnName": "key",
                            "displayName": "Key",
                            "searchable": true,
                            "isDisplayName": false,
                            "mappedName": "key"
                        }, {
                            "id": "1",
                            "columnName": "value",
                            "displayName": "Text",
                            "searchable": false,
                            "isDisplayName": true,
                            "mappedName": "value"
                        }, {
                            "id": "2",
                            "columnName": "additionalValue",
                            "displayName": "Additional Text",
                            "searchable": false,
                            "isDisplayName": false,
                            "mappedName": "additionalValue"
                        }];
                    }
                    this.setState({
                        ...this.state,
                        dynamicColumn: dynamicColumn,
                        rows: row ? row : []
                    })



                })
        }
    }
    inputChangeHandler2 = (evt, val) => {
        
        let value = val.lookupTableId;
        let fileTypeProp;

        this.setState({
            ...this.state,

            dbMetadata: {
                ...this.state.dbMetadata,

                lookupTable: value

            }
        })

    }
    rowSelected = (row) => {
        
        this.setState({
            ...this.state,
            showValueHelpDialog: false,
            dataElementRow: row,
            selectedRow: {
                ...this.state.selectedRow,
                dataElementId: row.dataElementId,
                dataElementName: row.name

            }
        })
    }
    compositeValueHelp = (index) => {
        
        this.setState({
            ...this.state,
            showValueHelpDynamicDialog: true,
            compositeArrayIndex: index


        });
    }
    onLookupType = (evt, keyProps, index) => {

        let arr = [...this.state.compositeArray];




        arr[index].lookupType = keyProps.props.value

        this.setState({
            ...this.state,
            lookupTypeC: false,
            compositeArray: arr

        })
    }
    render() {

        // const userAccess = this.props.userAccess;
        // const modellingEntity = userAccess.entities.find(ele => ele.name === "Modelling") ? true : false;
        // const userFormsEntity = userAccess.entities.find(ele => ele.name === "UserForms") ? true : false;
        // const configurationEntity = userAccess.entities.find(ele => ele.name === "Configuration") ? true : false;
        // const rulesEntity = userAccess.entities.find(ele => ele.name === "Rules") ? true : false;
        const userAccess = this.props.userAccess;
        console.log(userAccess);
        const CreateDataElement = userAccess.activities.filter(
            ele => ele === "Update Data Element"
        ) ? true : false;
        // ) ? true : true;
        return (
            <div>

                {(this.state.tableVisible) && <div>

                    <span className="iconBtn" style={{ float: 'right', margin: '0.5rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10, padding: 1 }}
                            disabled={!CreateDataElement}
                            onClick={this.createNewField}
                        //className={classes.buttonAdd}
                        >
                            Create
                        </Button>
                    </span>

                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Name</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Label</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Description</TableCell>
                                    {/* <TableCell width="10%" style={{ fontWeight: 700 }}> Data Element</TableCell> */}
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.dataElement.map((row) => {
                                    return (


                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}


                                            <TableCell >{row.name}</TableCell>
                                            <TableCell >{row.label}</TableCell>
                                            <TableCell >{row.description}</TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            {/* <TableCell>{row.status}</TableCell> */}
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton aria-label="Edit" style={{ color: "green" }}
                                                    onClick={() => this.editFields(row)}
                                                >
                                                    <ArrowForwardIosIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>}
                {(!this.state.tableVisible) && <div >
                    <span style={{ float: 'left', margin: '0.1rem' }}>
                        <IconButton aria-label="Edit" style={{ color: "blue", size: "2rem" }}
                            onClick={this.backToTable}
                        >
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <label className="customLabel"  >Create Data Element </label>
                    </span>
                    <Grid container spacing={1} hspacing="0rem"
                        style={{
                            // padding: "1rem", width: "100%"
                            paddingLeft: "1rem", width: "100%", margin: "0rem", paddingBottom: "0px"
                        }}
                    >

                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                id="filled-disabled"
                                label="Name"
                                name="name"
                                value={this.state.selectedRow.name}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                id="filled-disabled"
                                label="Label"
                                name="label"
                                value={this.state.selectedRow.label}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                id="filled-disabled"
                                label="Description"
                                name="description"
                                value={this.state.selectedRow.description}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-outlined-label" required>Data Type</InputLabel>
                                <Select

                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Data Type"
                                    value={this.state.selectedRow.dataType}
                                    // size="small"
                                    name="dataType"
                                    style={{ width: "100%" }}
                                    onChange={(evt) => this.inputChangeHandler(evt)}
                                >
                                    {this.state.dataTypes.map(option =>
                                        <MenuItem value={option.name} key={option.id}
                                        // onClick={() =>
                                        //     this.applicationChangeHandler(option.id, index)

                                        // }
                                        // style={{ width: 30 }}
                                        >{option.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                error={this.state.validLength}
                                helperText={this.state.errorMssg}
                                disabled={this.state.selectedRow.dataType === "BOOLEAN" || this.state.selectedRow.dataType === "TIMESTAMP" || this.state.selectedRow.dataType === "DATE" || this.state.selectedRow.dataType === "FILE"}

                                id="filled-disabled"
                                label="Length"
                                name="length"
                                value={this.state.selectedRow.length}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 5 }}
                            // className="customInput2" 
                            />
                            <IconButton aria-describedby="mouse-over-popover"
                                onClick={(event) => this.handlePopoverOpen(event)}
                                aria-owns={this.state.open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                hidden={this.state.selectedRow.dataType !== "DECIMAL"}
                            // onMouseEnter={this.handlePopoverOpen}
                            // onMouseLeave={this.handlePopoverClose}
                            >
                                <InfoSharp />
                            </IconButton>

                            <Popover

                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: 'none',
                                }}
                                open={this.state.open}
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                onClose={this.handlePopoverClose}
                                disableRestoreFocus

                            >
                                <Typography gutterBottom class="popoverClass">For Decimal datatype, input value should be
                                    of eg: a,b where 'a' is the total length of number and 'b'
                                    is the number of max decimal places and a greate than b.
                                </Typography>
                            </Popover>

                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} >
                                {/* <InputLabel id="demo-simple-select-outlined-label" ></InputLabel> */}
                                <FormGroup aria-label="position" row >

                                    <FormControlLabel
                                        required
                                        label='Value Help'
                                        size="small"
                                        checked={this.state.selectedRow.isLookup}
                                        //checked={this.state.obj.active}
                                        name="active"
                                        onChange={(evt) => this.onValueHelpSwitch(evt)}
                                        //value={row.active}
                                        labelPlacement="start"
                                        control={<Switch color="primary" />}


                                    />


                                </FormGroup>
                            </FormControl >
                        </Grid>
                    </Grid>

                    {(this.state.selectedRow.isLookup) && <TabContext value={this.state.value} >
                        <AppBar position="static">
                            <TabList onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                                <Tab label="Value Help" value="ValueHelp" />
                                {/* <Tab label="Field Catalog" value="FieldsCatalog" /> */}

                            </TabList>
                        </AppBar>
                        <div style={{ "overflow-y": "scroll", "overflow-x": "hidden" }}>
                            <TabPanel value="ValueHelp">
                                <ValueHelp  {...this.props} selectedRow={this.state.selectedRow} ref={this.valuehelpref}
                                    constraints={this.state.constraints} fields={this.state.fields} parentState={this.state}
                                    handleChangeValueHelpType={this.handleChangeValueHelpType} addNewConstraint={this.addNewConstraint}
                                    addNewFields={this.addNewFields}
                                    inputChangeHandler2={(evt, index) => this.inputChangeHandler2(evt, index)}
                                    addSheets={this.addSheets}
                                    deleteSheet={(row, index) => this.deleteSheet(row, index)}
                                    sheetsInputs={(evt, index) => this.sheetsInputs(evt, index)}
                                    deleteFieldRows={(row, index) => this.deleteFieldRows(row, index)}
                                    deleteConstraintRows={(row, index) => this.deleteConstraintRows(row, index)}
                                    addNewStaticVL={this.addNewStaticVL}
                                    deleteStaticVL={(row, index) => this.deleteStaticVL(row, index)}
                                    inputChangeHandler1={(evt, val) => this.inputChangeHandler1(evt, val)}
                                    valueHelpTableInputs={(evt, index) => this.valueHelpTableInputs(evt, index)}
                                    fnAttrValueHelp={(index, evt, table) => this.fnAttrValueHelp(index, evt, table)}
                                    closeDialog={this.closeDialog}
                                    addComposite={this.addComposite}
                                    rowSelected={(row, index) => this.rowSelectedValueHelp(row, index)}
                                    rowSelectedFields={(row, index) => this.rowSelectedValueHelpFields(row, index)}
                                    compositeValueHelp={(index) => this.compositeValueHelp(index)}
                                    onLookupType={(row, props, index) => this.onLookupType(row, props, index)}
                                    deleteComposite={(row, index) => this.deleteComposite(row, index)} />

                                {/* <DataElementDetails {...this.props} fieldCatalogs={this.state.dataElement}/> */}

                            </TabPanel>
                        </div>

                    </TabContext>}
                    {/* <div className="footerdiv">
          <Button variant="contained" color="primary" size="small" onClick={() => this.onclickSave()} >Save</Button>
          <Button style={{ margin: '0 0.5rem' }} variant="outlined" color="primary" size="small" onClick={this.onCancel}>Cancel</Button>
        </div> */}

                </div>}
                {(!this.state.tableVisible) && <div className="footerdiv">
                    <Button variant="contained" color="primary" size="small" onClick={this.onSaveDataElement} disabled={!CreateDataElement}>Save</Button>
                    <Button style={{ margin: '0 0.5rem' }} variant="outlined" color="primary" size="small" onClick={this.onCancel}>Cancel</Button>
                </div>}
                <MessageBoxComponentSave title="save" open={this.state.showDialogSave} actions={["SAVE", "CANCEL"]} onClose={this.closeDialog} />
                <MessageBoxComponentDiscard title="discard" open={this.state.showDialogDiscard} actions={["DISCARD", "CANCEL"]} onClose={this.closeDialog} />
                <ValueHelpDialog title="Value Help" rowSelected={(row) => this.rowSelected(row)} open={this.state.showValueHelpDialog} actions={["DISCARD", "CANCEL"]} onClose={this.closeDialog} dataElement={this.state.dialogData} />
                <ValueHelpDynamicDialog title="Value Help" open={this.state.showValueHelpDynamicDialog} actions={["DISCARD", "CANCEL"]} onClose={this.closeDialog} dataElement={this.state.rows}
                    rowSelected={(row, i) => this.rowSelectedDynamic(row, i)} dynamicColumn={this.state.dynamicColumn} />
                <Snackbar open={this.state.snackbarOpen} autoHideDuration={3000} onClose={() => this.setState({ ...this.state, snackbarOpen: false })}
                    message={this.state.snackbarMessg} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} />

            </div>
        );
    }
}

export default withRouter(CwMSFields);