import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useState } from "react";
// import UsersAdd from './CwWfUsersAdd';
import { Row } from "react-bootstrap";
//import {useHistory} from 'react-router-dom';
const MessageBoxComponentSubmit = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //console.log(this.props.content)
//    const deleteuser = () => {
//        
//        ////console.log(user);
//        let id=props.content;
//        fetch("/userRoles/api/v1/users?id=" + id,{ method: "DELETE" }).then((res) => res.json())
//             .then(users => {
//             })
//             props.handleClose();
       
//    };
    return (<Dialog
        fullScreen={fullScreen}
        open={props.open}
    //onClose={() => props.onClose('CANCEL')}
    >
        <DialogTitle style={{ height: '3rem', display: 'flex', alignItems: 'center', borderBottom: '1px solid #d9d9d9' }}>{"Confirmation"}</DialogTitle>
        <DialogContent style={{ minWidth: '20rem', minHeight: '4rem' }}>
            <DialogContentText style={{}}>
            Do you want to save the record?
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ height: '3rem', borderTop: '1px solid #d9d9d9' }}>
            {/* {props.actions.map(action => <Button key={action} variant="contained" size="small" onClick={() => props.handleClose(action)}>{action}</Button>)} */}
            <Button key={'CANCEL'} variant="contained" size="small" onClick={()=>{props.onClose("CANCEL")}}>CANCEL</Button>
            <Button key={'SAVE'} variant="contained" size="small" onClick={()=>props.onClose("SAVE")} color="secondary">SAVE</Button>
        </DialogActions>
    </Dialog>)

};
export default withRouter(MessageBoxComponentSubmit);
