import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import UIelements from './CwUIELEMENT.jsx'
import Message from './CwMessagetext';
import { IconButton } from '@material-ui/core';
import { Button } from 'react-bootstrap';


class CwWorkText extends Component {
    constructor(props) {
        super(props);
        this.state={
            value:"UI Element",
            showUIElement:true,
            showmessage:false,
            application:[],
            showTable:true,
            properties:[],
            showproperty:false,
            showmap:false,
            showmessageDetails:false,
            languages:[],
            selectedlanguage:'EN',
            messagetextproperty:[],
            finalmessagetextproperty:[],
            finalproperties:[],
            configuration:'UI Element',
            applicationName:'GLOBAL',
        }

    }

    tabChangeHandler=(event)=>{
        if(event==="UI Element"){
          fetch("CW_Worktext/translation/getPropertiesTranslations?applicationName="+this.state.applicationName+"&elementName=UI&languageCode=EN",{headers:{"Authorization":this.props.authorization}})
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              ...this.state,
              isLoaded: true,
             properties: json.data,
             finalproperties:json.data,
             showUIElement:true,
             showmessage:false,
             configuration:event,
             showTable:true,
  
            });
          }); }
        else  {  fetch("CW_Worktext/message/getMessagesTranslations?applicationName="+this.state.applicationName+"&elementName=MESSAGE_TEXT&languageCode=EN",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
           messagetextproperty: json.data,
           finalmessagetextproperty:json.data,
           showUIElement:false,
           showmessage:true,
           configuration:event,
      
  
          });
        });
    }}
  
    handleChange = (event, newValue) => {
        this.setState({
            ...this.state,
            value: newValue
        });
    };
    changeshowtable=()=>{
        this.setState({
            ...this.state,
            showTable:!this.state.showTable,
            showproperty:this.state.showTable,
            showmessageDetails:this.state.showTable
        })
    }
    onChangeSearchQuery=(evt)=>{
        let tempproperties=this.state.finalproperties.filter(app=>app.propertyName.includes(evt.target.value));
       this.setState({
           ...this.state,
           properties:tempproperties,
       })

    }
    onChangeSearchQuerymessage=(evt)=>{
      let tempmessage=this.state.finalmessagetextproperty.filter(app=>app.usageArea.includes(evt.target.value));
     this.setState({
         ...this.state,
         messagetextproperty:tempmessage,
     })

  }
    changeapplication=(option)=>{
      fetch("CW_Worktext/message/getMessagesTranslations?applicationName="+option+"&elementName=MESSAGE_TEXT&languageCode=EN",{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ...this.state,
          messagetextproperty:json.data,
          finalmessagetextproperty:json.data,
          selectedlanguage:'EN'
        }) });
        fetch("CW_Worktext/translation/getPropertiesTranslations?applicationName="+option+"&elementName=UI&languageCode=EN",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
           properties: json.data,
           finalproperties:json.data,
           
           applicationName:option,
          selectedlanguage:'EN',
           showTable:true,
           showmessageDetails:false,
           showproperty:false

          });
        });
    }
    showmapprops=()=>{
      this.setState({
        ...this.state,
        showmap:!this.state.showmap,
        showTable:!this.state.showTable,
      })
    }
    editscreen=()=>{
      this.setState({
        ...this.state,
        showmap:false,
        showTable:false,
        showproperty:true,
        showmessageDetails:true,
      })
    }
    componentDidMount() {
        fetch("CW_Worktext/application/getApplications",{headers:{"Authorization":this.props.authorization}})
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              ...this.state,
              isLoaded: true,
              application: json.data,
            });
          });
          
          fetch("CW_Worktext/language/getLanguages",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
          languages: json.data,  
          });
        });

          fetch("CW_Worktext/translation/getPropertiesTranslations?applicationName="+"GLOBAL"+"&elementName=UI&languageCode=EN",{headers:{"Authorization":this.props.authorization}})
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              ...this.state,
              isLoaded: true,
             properties: json.data,
             finalproperties:json.data,
             showUIElement:true,
             showmessage:false,
             applicationName:"GLOBAL",
             configuration:'UI Element',
  
            });
          });
        }
        changelanguagemessage=(option)=>{
            fetch("CW_Worktext/message/getMessagesTranslations?applicationName="+this.state.applicationName+"&elementName=MESSAGE_TEXT&languageCode="+option ,{headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((json) => {
              this.setState({
                ...this.state,
               messagetextproperty: json.data,
               finalmessagetextproperty: json.data,
                
              
              });
            });
          }
          changelanguage=(option)=>{
            fetch("CW_Worktext/translation/getPropertiesTranslations?applicationName="+this.state.applicationName+"&elementName=UI&languageCode=" + option ,{headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((json) => {
              this.setState({
                ...this.state,
               properties: json.data,
               finalproperties:json.data,
               selectedlanguage:option,
              });
            });
          }

    render() {
      const userAccess = this.props.userAccess;
        return (
            <div>

        <div style={{display:'flex',justifyContent:'flex-start'}}>            
 
    {this.state.showTable &&
<div>
            <FormControl style={{ width: 180,marginTop:'1rem', marginLeft:'2rem' }} variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label" required>
               Worktext configuration
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                //value={row.applicationId}
                // size="small"
                label="Worktext Configuration"
                name="worktextconfig"
               onChange={(evt)=>this.tabChangeHandler(evt.target.value)}
               value={this.state.configuration}
              >
            <MenuItem value="UI Element" key="1"  >UI Element</MenuItem>
            <MenuItem value="Messsage Text" key="2"  >Message Text</MenuItem>
            
                  
                 
              </Select>
            
            </FormControl>
           
          </div>}
         
          {this.state.showTable &&     <span
             

              style={{ float: "left",marginLeft:'1rem',marginTop:'1rem', Color:"blue",width:'4rem',height:'3rem' }}
            >
              <Button
                 size="large"
                 
                color="secondary"
           style={{color:'white',backgroundColor:"#3f51b5",width:'4.625rem',height:'2rem',fontSize:'0.8125rem', padding:'0.25rem 0.5rem',display:'inline-flex', borderRadius:'0.35rem'}}
               onClick={()=>this.changeapplication('GLOBAL')}
              >
                GLOBAL
              </Button>
            </span>}
             
            {this.state.showTable &&    <div>
          <FormControl style={{width: 180, marginLeft:'1rem',marginTop:'1rem' }} variant="outlined" size="small">
             <InputLabel id="demo-simple-select-outlined-label" required>
               Application
             </InputLabel>
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               // size="small"
               label="Application"
               name="application"
             
             
             > 
             {this.state.application.map((option,index) => (
                 
                 <MenuItem
                   value={option.applicationName}
                   onClick={() =>  this.changeapplication(option.applicationName)}
                  
                  
                 >
                   {option.applicationDescription}
                 </MenuItem>
               ))}
              </Select>
             </FormControl>
           </div>}
        
              
         </div>

             { this.state.showUIElement &&
          <UIelements {...this.props} applicationName={this.state.applicationName} showmapprops={()=>this.showmapprops()} showmap={this.state.showmap} showproperty={this.state.showproperty}properties={this.state.properties}  changelanguage={(option)=>this.changelanguage(option)} languages={this.state.languages}  selectedlanguage={this.state.selectedlanguage} editscreen={()=>this.editscreen()} changeshowtable={()=>this.changeshowtable()} changeapplication={(option)=>this.changeapplication(option)}showtable={this.state.showTable}   application={this.state.application} onChangeSearchQuery={(evt)=>this.onChangeSearchQuery(evt)}/>}
           {this.state.showmessage && 
             < Message {...this.props} applicationName={this.state.applicationName} showmapprops={()=>this.showmapprops()} showmap={this.state.showmap} showmessageDetails={this.state.showmessageDetails} messagetextproperty={this.state.messagetextproperty}  changelanguage={(option)=>this.changelanguagemessage(option)} selectedlanguage={this.state.selectedlanguage} languages={this.state.languages}  editscreen={()=>this.editscreen()}    changeapplication={(option)=>this.changeapplication(option)}  changeshowtable={()=>this.changeshowtable()} showtable={this.state.showTable}   application={this.state.application} onChangeSearchQuerymessage={(evt)=>this.onChangeSearchQuerymessage(evt)} />
         }
            </div>
        );
    }
}



export default CwWorkText;