import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Keycloak from "keycloak-js";
import reportWebVitals from './reportWebVitals';


let keycloak = Keycloak("/azureKeycloak.json");

if (window.location?.hostname.includes("localhost")) {

  keycloak = Keycloak("/localhostKeycloak.json");

}

else if (window.location?.hostname.includes("cfapps")) {

  keycloak = Keycloak("/keycloak_cf.json");

}
// const keycloak = Keycloak(

//   window.location?.hostname === "localhost"

//     ? "/localhostKeycloak.json"

//     : "/azureKeycloak.json"

// );
// const userAccess = {
//   "userId": "P000241",
//   "user": {
//     "id": "501",
//     "userId": "P000241",
//     "userName": "Varun",
//     "displayName": "Varun V",
//     "firstName": "Varun",
//     "lastName": "V",
//     "emailId": "varun.v@incture.com",
//     "status": "Active",
//     "isDeleted": false,
//     "isActive": true,
//     "createdBy": "System",
//     "createdOn": 1612797807000,
//     "updatedBy": "System",
//     "updatedOn": 1612797807000
//   },
//   "applicatioName": "WorkUtils",
//   "entities": [
//     {
//       "id": "203",
//       "name": "UserForms",
//       "label": "UserForms",
//       "description": "WorkForms UserForms",
//       "applicationId": "101",
//       "status": "Active",
//       "createdBy": "System",
//       "createdOn": 1633714621000,
//       "updatedBy": "System",
//       "updatedOn": 1633714621000,
//       "deleted": false,
//       "active": true
//     },
//     {
//       "id": "204",
//       "name": "Rules",
//       "label": "Rules",
//       "description": "WorkForms Rules",
//       "applicationId": "101",
//       "status": "Active",
//       "createdBy": "System",
//       "createdOn": 1633714621000,
//       "updatedBy": "System",
//       "updatedOn": 1633714621000,
//       "deleted": false,
//       "active": true
//     },
//     {
//       "id": "202",
//       "name": "Configuration",
//       "label": "Configuration",
//       "description": "WorkForms Configuration",
//       "applicationId": "101",
//       "status": "Active",
//       "createdBy": "System",
//       "createdOn": 1633714621000,
//       "updatedBy": "System",
//       "updatedOn": 1633714621000,
//       "deleted": false,
//       "active": true
//     },
//     {
//       "id": "201",
//       "name": "Modelling",
//       "label": "Modelling",
//       "description": "WorkForms Modelling",
//       "applicationId": "101",
//       "status": "Active",
//       "createdBy": "System",
//       "createdOn": 1612790680000,
//       "updatedBy": "System",
//       "updatedOn": 1612790680000,
//       "deleted": false,
//       "active": true
//     }
//   ],
//   "activities": [
//     {
//       "id": "310",
//       "name": "EditTheme",
//       "label": "Edit Theme",
//       "description": "Edit Theme",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "311",
//       "name": "DeleteTheme",
//       "label": "Delete Theme",
//       "description": "Delete Theme",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "312",
//       "name": "CreateData",
//       "label": "Create Data",
//       "description": "Create Data",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "301",
//       "name": "CreateForm",
//       "label": "Create Form",
//       "description": "Create Form",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "313",
//       "name": "EditData",
//       "label": "Edit Data",
//       "description": "Edit Data",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "302",
//       "name": "EditForm",
//       "label": "Edit Form",
//       "description": "Edit Form",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "314",
//       "name": "ViewData",
//       "label": "View Data",
//       "description": "View Data",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "303",
//       "name": "EmbedForm",
//       "label": "Embed Form",
//       "description": "Embed Form",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "304",
//       "name": "PreviewForm",
//       "label": "Preview Form",
//       "description": "Preview Form",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "305",
//       "name": "ViewForm",
//       "label": "View Form",
//       "description": "View Form",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "316",
//       "name": "ViewMessage",
//       "label": "View Message",
//       "description": "View Message",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "306",
//       "name": "CreateMessage",
//       "label": "Create Message",
//       "description": "Create Message",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "317",
//       "name": "ViewTheme",
//       "label": "View Theme",
//       "description": "View Theme",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "307",
//       "name": "EditMessage",
//       "label": "Edit Message",
//       "description": "Edit Message",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "318",
//       "name": "CreateRule",
//       "label": "Create Rule",
//       "description": "Create Rule",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "308",
//       "name": "DeleteMessage",
//       "label": "Delete Message",
//       "description": "Delete Message",
//       "permissionType": "ALL"
//     },
//     {
//       "id": "309",
//       "name": "CreateTheme",
//       "label": "Create Theme",
//       "description": "Create Theme",
//       "permissionType": "ALL"
//     }
//   ]
// }

keycloak.init({ onLoad: "login-required" }).then((authenticated) => {

  console.log(keycloak)

  const bearer = 'Bearer ' + keycloak.token;

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*", 
    "Authorization": bearer,

  };// fetching roles and permission from workaccess
  fetch('https://waservicesqa.cherryworkproducts.com/api/v1/applications/entitiesAndActivities?applicationName=WorkUtils', { headers: headers })

    .then(res => res.json())

    .then(response => {
      console.log(response);
      let entitiesAndActivities = response.data.entitiesAndActivities[0];

      const entities = Object.keys(entitiesAndActivities).map(ele => { return ele });

      let activities = [];

      for (let entity of entities) {

        entitiesAndActivities[entity].forEach(ele => { activities[ele] = true })

      }

      let userAccess = {};

      userAccess.entities = entities;

      userAccess.activities = activities;

      let defaultTab = entities[0];
      let newArr =entities;
      let fieldCatalogArr = [];
      if(entities[0] === "Data Elements"){
        defaultTab = "Field Catalog" 
     }
     if(entities.find(item => item === "Data Elements") && entities.find(item => item === "Field Catalog"))
     {
      // let index = entities.indexOf("Data Elements");
      newArr = entities.filter(item => item !== "Data Elements");
     }
     if(entities.find(item => item === "Data Elements") || entities.find(item => item === "Field Catalog"))
     {
      // let index = entities.indexOf("Data Elements");
      fieldCatalogArr = entities.filter(item => item === "Data Elements" || item === "Field Catalog");
     }
      (userAccess && userAccess.entities && userAccess.entities.length)

        ?
        //  ReactDOM.render(

        //   <Provider store={store}>

        //     <App requestHeaders={headers} keycloak={keycloak} userAccess={userAccess} userDetails={response.data} />

        //   </Provider>,

        //   document.getElementById("root")

        // )
        
        ReactDOM.render(
              
              <App requestHeaders={headers} keycloak={keycloak}  userDetails={response.data} pid={response.data.user_id} userAccess={userAccess} defaultTab={defaultTab} newArr={newArr} fieldCatalogArr={fieldCatalogArr} authorization={bearer}>
          
              </App>,
          
            document.getElementById('root')
          )

        : ReactDOM.render(

          null,

          document.getElementById("root")

        );

    });



});

// let pid = userAccess.userId;
// let pid = "P000241"
// let pEmailId = userAccess.user.emailId;
// let roleCollection = [
//    "WF_AppViewer",
//   "WF_App",
//   "WF_Modeler",
//   "WF_ADMIN",
//   "WF_ModelViewer",
// ];

//Only for CF
// fetch("/user")
//   .then((res) => res.json())
//   .then((user) => {
//     // console.log(user);
//     let pid = user.id;
//     let pEmailId = user.emails[0].value;
//     // ReactDOM.render(
//     //   <App pid={pid} pEmailId={pEmailId} userAccess={userAccess} roleCollection={roleCollection}/>,
//     //   document.getElementById("root")
//     // )
    
//   });

// ReactDOM.render(

//     <App roleCollection={roleCollection} pid={pid} >

//     </App>,

//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
