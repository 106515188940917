import React, { Component } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@mui/material/Alert';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MessageBoxComponentSave from "./CwWfSaveConfirmation";
import FormControl from "@material-ui/core/FormControl";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Paper from "@material-ui/core/Paper";
import { Button } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Tooltip from "@material-ui/core/Tooltip";
import { TextField, InputAdornment, Toolbar, Icon } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Checkbox from '@mui/material/Checkbox';
import MapPropertiesUI from './Cw_MapPropertiesUI';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Dropdown } from 'react-bootstrap';

class CwUIELEMENT extends Component {
    constructor(props) {
        super(props);
        let emptyRow = this.emptyRow();
        let dumproperties=this.props.properties;
        this.state={properties:dumproperties,
        selectedRow:emptyRow,
        detaileddata:[],
        finaldetaileddata:[],
        showmap:false,
        propertyName:'',
        Description:'',
        showDialogSave:false,
        showproperty:false,
        languages:[],
        newcreation:'EXISTING',
        new:false,
        
    }
    }
    emptyRow = () => {
        const selectedRow = {
         propertyName:'',
         propertyDescription:'',
         shortDescription:'',
         longDescription: "",
         mediumDescription: "",
         availableTranslations:'',
         availableLanguages:[],
         availableLanguageDescriptions:[],
         availableLanguageCodes:[],
        };
        return selectedRow;
      };
      savethedata=()=>{
        this.setState({
          ...this.state,
    
          showDialogSave: true
    
      })
       };
       importExcelFile = async (e) => {
        
        // console.log(e)
        if(e.target.files[0]) {
          const fileType = e.target.files[0].name.split(".")[1];
          if(fileType === "xlsx" || fileType === "csv") {
            const formData = new FormData();
            formData.append("file",e.target.files[0]);
            let headers= {
              ContentType: "application/json",
              Accept: "application/json",
              cache: false,
              "X-CSRF-Token": "Fetch",
              "Authorization":this.props.authorization
          };
          const response1 =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
          
          const token =  response1.headers.get("X-Csrf-Token");
             
 
            const requestOptions = {
              method: "POST",
              "X-CSRF-Token": token ,
              body: formData,
              headers:{"Authorization":this.props.authorization}
            };
            const response = await fetch( "CW_Worktext/property/upload", requestOptions);
            const result = await response.json();
             console.log(result.output)
          
          } 
        } 
        this.props.changeapplication(this.props.applicationName);    
      }
    
       onSaveDataElement = async(e) => {
        var tempmessage="Add all the fields" ;
        var  payload = {
          applicationName: this.props.applicationName,
          createdBy: "",
          createdOn: "",
          elementName: "UI",
          propertyCreationType: this.state.newcreation,
          propertyDescription:this.state.Description,
          propertyName: this.state.propertyName,
          translations: this.state.detaileddata,
          updatedBy: "",
          updatedOn: "",
        };
          let method, url;
         
            method = "POST";
            url = "CW_Worktext/property/createProperty";

            let headers= {
              ContentType: "application/json",
              Accept: "application/json",
              cache: false,
              "X-CSRF-Token": "Fetch",
              "Authorization":this.props.authorization
          };
          const response =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
          
          const token =  response.headers.get("X-Csrf-Token");
          console.log(token)
          
          const requestParam1 = {
            method: method,
            headers: { "X-CSRF-Token": token ,"Content-Type": "application/json", "Authorization":this.props.authorization },
            body: JSON.stringify(payload),
          };
          await fetch(url, requestParam1)
            .then((response)=>response.json())
            .then((response) => {
              this.setState({...this.state,messageOpen:true,messageContent:response.message});
            })
            ;
            fetch("CW_Worktext/translation/getTranslationsInAllLanguages?applicationName="+this.props.applicationName+"&elementName=UI"+"&propertyName="+this.state.propertyName,{ headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((json) => {
              this.setState({
                  ...this.state,
                  detaileddata:json.data,
                  finaldetaileddata:json.data,
                   
                    
                })});}
            
           
         
      
       closeDialog = (evt) => {    
       if (evt === "SAVE" ) {
           //   this.onSaveMappingRolesDraft();
           this.onSaveDataElement();
       }
   
   
       
       this.setState(
           {
               ...this.state,            
               showDialogSave: false,  
           });
   
   }
     adddata=()=>{
         let tempa=this.state.detaileddata;
         let tempd={
            languageCode:null,
            longDescription: "",
            mediumDescription: "",
            shortDescription: "",
            createdBy:'Manish',
            createdOn:'',
            translationType:'',
            updatedBy:'Manish',
            updatedOn:'',
            status:''

         }
         tempa.push(tempd);
         this.setState({
            ...this.state,
            detaileddata:tempa,
          
        })

     }
    
     changeshowproperty=()=>{
       this.setState({
         ...this.state,
         detaileddata:[],
         propertyName:'',
         Description:''
       })
     }
   delete=async(option)=>{
      let url='CW_Worktext/translation/deleteTranslations?applicationName='+this.props.applicationName+'&elementName=UI&languageCode=&propertyName='+option.propertyName;
      
      let headers= {
        ContentType: "application/json",
        Accept: "application/json",
        cache: false,
        "X-CSRF-Token": "Fetch",
        "Authorization":this.props.authorization
    };
    const response =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
    
    const token =  response.headers.get("X-Csrf-Token");
       
      await fetch(url, { method: 'DELETE', headers:{"X-CSRF-Token": token, "Authorization":this.props.authorization} })
      .then((response)=>response.json())
      .then((response) => {
       console.log(option)
      });
      console.log(option)
      this.props.changelanguage(this.props.selectedlanguage);

    }
  deleteproperty=async(option)=>{
    let url='CW_Worktext/translation/deleteTranslations?applicationName='+this.props.applicationName+'&elementName=UI&languageCode='+option.languageCode+'&propertyName='+this.state.propertyName;
    let headers= {
      ContentType: "application/json",
      Accept: "application/json",
      cache: false,
      "Authorization":this.props.authorization,
      "X-CSRF-Token": "Fetch",
  };
  const response =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
  
  const token =  response.headers.get("X-Csrf-Token");
     
    await fetch(url, { method: 'DELETE', headers:{"X-CSRF-Token": token,"Authorization":this.props.authorization} })
      .then((response)=>response.json())
      .then((response) => {
       console.log(option)
      });

      fetch("CW_Worktext/translation/getTranslationsInAllLanguages?applicationName="+this.props.applicationName+"&elementName=UI"+"&propertyName="+this.state.propertyName,{ headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
              ...this.state,
              detaileddata:json.data,
              finaldetaileddata:json.data,
               
                
            })})
     
  }
     propertydata=()=>{
         let tempp=[  {
            languageCode: null,
            longDescription: "",
            mediumDescription: "",
            shortDescription: "",
         }]
         this.setState({
             ...this.state,
             detaileddata:tempp,
             showproperty:true,
             Description:'',
             propertyName:'',
             new:true,
             newcreation:'NEW',

         })
     }
     changedetaillanguage=(evt,option,index)=>{
         let tempa=this.state.detaileddata;
         let tempv=this.state.detaileddata[index];
         let val=this.props.languages.filter(ele=> ele.languageDescription===evt.target.dataset.value);
         tempv.languageCode=val[0].langaugeCode;
         tempa[index]=tempv;
         this.setState({
            ...this.state,
            detaileddata:tempa,
            new:false
        })

     }
     download=()=>{
        var sUrl = "CW_Worktext/property/download?applicationName=" + this.props.applicationName + "&elementName=UI";
        var oWindow = window.open(sUrl, "_blank");
     }
      changedetails=(evt,option,index)=>{
          //let index=
          let tempa=this.state.detaileddata;
          let tempv=this.state.detaileddata[index];
         let t=evt.target.name;
          tempv[t]=evt.target.value;
          tempa[index]=tempv;
          this.setState({
              ...this.state,
              detaileddata:tempa
          })
      }
      changeDescription=(evt)=>{
          this.setState({
              ...this.state,
             [evt.target.name]:evt.target.value,
          })
      }
     changelanguage=(option)=>{
        let tempdetails=this.state.finaldetaileddata.filter(app=>app.languageCode.includes(option.langaugeCode));
        this.setState({
            ...this.state,
           detaileddata:tempdetails,
        })
     }
      
      editFields = (row) => {
        fetch("CW_Worktext/translation/getTranslationsInAllLanguages?applicationName="+this.props.applicationName+"&elementName=UI"+"&propertyName="+row.propertyName,{ headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
              ...this.state,
              detaileddata:json.data,
              finaldetaileddata:json.data,
               propertyName:row.propertyName,
               Description : row.mediumDescription,
                
            })})
      };
      componentDidMount(){
       
        
        // fetch("CW_Worktext/translation/getPropertiesTranslations?applicationName="+"GLOBAL"+"&elementName=UI&languageCode=EN")
        // .then((res) => res.json())
        // .then((json) => {
        //   this.setState({
        //     ...this.state,
        //     isLoaded: true,
        //    properties: json.data,
          

        //   });
        // });
      }
    render() {
        const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
       let v;
     const userAccess = this.props.userAccess;
    console.log(userAccess);
    const editProperties = userAccess.activities.filter(
        ele => ele === "Edit Properties"
        ) ? true : false;
        const importProperties = userAccess.activities.filter(
          ele => ele === "Import Properties"
          ) ? true : false;
          const mapProperties = userAccess.activities.filter(
            ele => ele === "Map Properties"
            ) ? true : false;
          

         
        return (<div>
          {this.props.showmap && <MapPropertiesUI changeshowproperty={()=>this.changeshowproperty()} editscreen={()=>this.props.editscreen()}editfield={(option)=>this.editFields(option)}properties={this.props.properties}changeshowtable={()=>this.props.changeshowtable()} languages={this.props.languages} application={this.props.application} applicationName={this.props.applicationName}  onChangeSearchQuery={(evt)=>this.props.onChangeSearchQuery(evt)}showmapprops={()=>this.props.showmapprops()}/>}
           {this.props.showtable &&  <div >
             
            <Toolbar style={{ display:'block',position:"absolute",marginTop:'-3.5rem',right:'0'}}>    
            <TextField
                 
                 variant='outlined'
                 style={{width:'15rem',marginRight:'0.5rem',background: '#ffffff' }}
                 placeholder="Search for Properties..."
                 id='filled-disabled'
                 size="small" 
                 
               //   value={searchQuery}
                 className="styleSearch"
                 onChange={(evt)=>this.props.onChangeSearchQuery(evt)}
               //   onKeyDown={onChangeSearchQuery}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="toggle password visibility"
                         
                       >
                         <SearchIcon />
                       
                       </IconButton>
                      
                     </InputAdornment>
                   )
                 }} />
                
                  <FormControl style={{width: "10rem" ,marginRight:'1rem'}} variant="outlined" size="small">
             <InputLabel id="demo-simple-select-outlined-label" required>
                Language
             </InputLabel>
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               
               // size="small"
               name="language"
             
             
             > 
             {this.props.languages.map((option) => (
                 
                 <MenuItem
                   value={option.langaugeCode}
                    onClick={() => this.props.changelanguage(option.langaugeCode)}
                  
                  
                 >
                   {option.languageDescription}
                 </MenuItem>
               ))}
              </Select>
             </FormControl>
             <Button  disabled={!mapProperties}variant='contained' color='primary' onClick={()=>{this.props.showmapprops()}} >Map Properties</Button>
             
           
                
                </Toolbar>
                  <TableContainer
              component={Paper}
              style={{ margin: "0.5rem", width: "99%" }}
            >
<Table
                  size="small"
                  aria-label="a dense table"
                  name="fieldsTable"
                >
                  <TableHead>
                    <TableRow>
                    
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        
                        Property
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                       
                       Short Description
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                       Medium Description
                      </TableCell>

                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                         Long Description
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        Available Translation
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                      <IconButton  disabled={!editProperties}onClick={()=>{this.propertydata();this.props.changeshowtable();this.changeshowproperty()}}>
                 <AddIcon/>
             </IconButton>
             <IconButton onClick={()=>this.download()}>
             <FileDownloadOutlinedIcon />
               </IconButton>
               <IconButton disabled={!importProperties} variant="outlined" size="small" color="primary" style={{marginRight: '0.5rem'}} component="label"><FileUploadOutlinedIcon/>
                      <input size="4"  accept=".xlsx, .xls, .csv" hidden type="file" name="importExcelFile" onChange={(e) => this.importExcelFile(e)} />
                </IconButton>
                       </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {this.props.properties.map((option) => (
                    <TableRow key={option.id}>
                      {/* <TableCell >{row.application}</TableCell> */}

                    
                      <TableCell width="15%">{option.propertyName}</TableCell>
                      <TableCell  width="15%">{option.shortDescription}</TableCell>
                      <TableCell  width="15%">{option.mediumDescription}</TableCell>
                      <TableCell  width="15%">{option.longDescription}</TableCell>
                      <TableCell  width="15%">{option.availableTranslations}</TableCell>

                      {/* <TableCell >{row.permissionType}</TableCell> */}

                     <TableCell> <IconButton
                          aria-label="Edit"
                          style={{ color: "green" }}
                          onClick={() => {this.editFields(option);this.props.changeshowtable();this.changeshowproperty()}}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                        <IconButton onClick={()=>{this.delete(option)}}><DeleteIcon/></IconButton></TableCell>
             
                    </TableRow>
                  ))}
                </TableBody>
                 </Table></TableContainer>
                 
            </div>}
            {
             this.props.showproperty && <div><IconButton onClick ={()=>{this.props.changeshowtable();this.changeshowproperty()}} style={{marginTop:'0.5rem'}}><KeyboardArrowLeftIcon /></IconButton>
               <Toolbar style={{marginTop:'-3rem',marginLeft:'1.5rem'}}> Property Name : <TextField  style={{marginRight:'3rem'}}variant='outlined' value={this.state.propertyName} disabled={!this.state.new}  onChange={(evt)=>this.changeDescription(evt) }/>
                      
            
                

                Property Description : <TextField variant='outlined' required   value={this.state.Description} name="Description"
                onChange={(evt)=>this.changeDescription(evt)}/>
            <FormControl style={{width: 180 ,marginLeft:'2rem'}} variant="outlined" size="small">
             <InputLabel id="demo-simple-select-outlined-label" required>
               Search Language
             </InputLabel>
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               // size="small"
               name="language"
             
             
             > 
             {this.props.languages.map((option,index) => (
                 
                 <MenuItem
                   value={option.langaugeCode}
                    onClick={() =>  this.changelanguage(option)}
                  
                  
                 >
                   {option.languageDescription}
                 </MenuItem>
               ))}
              </Select>
             </FormControl>
             <IconButton  disabled={!editProperties} onClick={()=>this.adddata()}>
                 <AddIcon/>
             </IconButton>
             
             
             
             
             </Toolbar>
                      <TableContainer
              component={Paper}
              style={{ margin: "0.5rem", width: "99%" }}
            >
<Table
                  size="small"
                  aria-label="a dense table"
                  name="fieldsTable"
                >
                  <TableHead>
                    <TableRow>
                    <TableCell width="5%" style={{ fontWeight: 700 }}>
                    <Checkbox {...label} />
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        
                       Language
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                       
                       Short Description
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                       Medium Description
                      </TableCell>

                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                         Long Description
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        Status
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                         Translation Type
                       </TableCell>
                       <TableCell width="15%" style={{ fontWeight: 700 }}>
                        
                       </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {this.state.detaileddata.map((option,index) => (
                    <TableRow key={option.id}>
                      {/* <TableCell >{row.application}</TableCell> */}
                        {/* { v=} */}
                      <TableCell> <Checkbox {...label} /></TableCell>
                      <TableCell>
                 { option.languageCode?null:<InputLabel id="demo-simple-select-outlined-label" required>
               Choose Language
             </InputLabel>}
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               // size="small"
               name="languageCode"
               value={option.languageCode?this.props.languages.filter(ele=> ele.langaugeCode===option.languageCode)[0].languageDescription :null}
             > 
             {this.props.languages.map((option1,index1) => (
                 
                 <MenuItem
                   value={option1.languageDescription}
                    onClick={(evt) =>  this.changedetaillanguage(evt,option,index)}
                  
                  
                 >
                   {option1.languageDescription}
                 </MenuItem>
               ))}
              </Select>
              </TableCell>
                      <TableCell>    <TextField variant='outlined' required   value={option.shortDescription}
                onChange={(evt)=>this.changedetails(evt,option,index)} name="shortDescription"/></TableCell>
                      <TableCell><TextField variant='outlined' required   value={option.mediumDescription}
                onChange={(evt)=>this.changedetails(evt,option,index)} name="mediumDescription"/></TableCell>
                      <TableCell><TextField variant='outlined' required   value={option.longDescription}
                onChange={(evt)=>this.changedetails(evt,option,index)} name="longDescription"/></TableCell>
                      <TableCell style={{color:'red'}}>{option.status}</TableCell>

                    

                     <TableCell> {option.translationType}</TableCell>
                     <TableCell> <IconButton  disabled={!editProperties} onClick={()=>{this.deleteproperty(option)}}><DeleteIcon/></IconButton>
             </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                 </Table></TableContainer>
                     
             
                      
                
                
                
                
                </div>}
               

                {
             this.props.showproperty &&    <div className="footerdiv" style={{position:"fixed", right:"10px" ,bottom:"10px"}}>
                    <Button
                      style={{ margin: "0 0.5rem" }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick ={()=>{this.props.changeshowtable();this.changeshowproperty()}}
                    >
                      Cancel
                    </Button>
                    <Button
                     style={{ margin: "0 0.5rem" }}
                      variant="contained"
                      color="primary"
                      disabled={!editProperties}
                      size="small"
                      onClick={() => this.savethedata()}
                    >
                      Save
                    </Button>
                    
                    
                  </div>}
              
                <MessageBoxComponentSave
                  title="save"
                  open={this.state.showDialogSave}
                  actions={["SAVE", "CANCEL"]}
                  onClose={(evt)=>this.closeDialog(evt)}
                />
                <Snackbar open={this.state.messageOpen} message={this.state.messageContent} autoHideDuration={3000} onClose={() => {this.setState({...this.state,messageOpen:false})}}>
        <Alert severity="success"
          sx={{ width: '100%' }} 
          style={{backgroundColor:"lightgreen"}} 
          >
  {this.state.messageContent}
  </Alert>
        </Snackbar>
            </div>
        );
    }
}


export default CwUIELEMENT;