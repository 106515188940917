import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ValueHelp from './ValueHelp';
import DataElementDialog from './DataElementsDetails';
import MessageBoxComponentDiscard from "./CwWfDiscardBasicInfo";
// import MessageBoxComponentSubmit from "./CwWfSubmitConfirmation";
import MessageBoxComponentSave from "./CwWfSaveConfirmation";
import InfoSharp from '@material-ui/icons/InfoSharp';
import InputBase from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputIcon from '@material-ui/icons/Input';
import ValueHelpDialog from './valueHelpDialog';
import ValueHelpDynamicDialog from './valueHelpDynamicDialog';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@material-ui/core/Chip';
import ListItemText from '@mui/material/ListItemText';



class CwMSFieldsDetails extends React.Component {

    constructor(props) {
        
        console.log(props);
        super(props);
        this.settingsPopoverRef = React.createRef();
        let emptyRow = this.emptyRow();
        this.state = {
            value: "ValueHelp",
            tableVisible: true,
            showDialog: false,
            showDialogDiscard: false,
            showDialogSubmit: false,
            showDialogSave: false,
            showValueHelpDialog: false,
            showDataElementDialog: false,
            showVHTableDialog: false,
            showValueHelpDynamicDialog: false,
            selectedRow: emptyRow,
            constraints: [],
            taggingapp: [],
            fields: [],
            dataElementRow: {
                attributeId: "",
                createdBy: "",
                createdOn: null,
                dataType: "",
                description: "",
                destinations: null,
                isLookup: false,
                label: "",
                length: "",
                lookUpId: "",
                lookUpType: "",
                lookupConfig: null,
                name: "",
                source: "",
                updatedBy: "",
                updatedOn: null,

            },
            apiMetadata: {
                "url": "",
                "destination": "",
                "apiType": "",
                "resultPath": null,
                "constraints": null,

            },
            dbMetadata: {},
            fileMetadata: {
                fileType: null,
                properties: {
                    "parentTag": null,
                    "sheets": []
                }
            },
            valueList: [],
            applications: [],
            fieldCatalogArray: [],
            selectedApplication: "",
            tagged: [],
            taggedDetails: [],
            dataElement: [],
            permission: false,
            existing: false,
            lookupType: "VL",
            destinations: [],
            dataTable: [],
            sheets: [],
            compositeArray: [],
            dynamicColumn: [],
            rows: []
        };
    }
    getData = (data, index) => {
        
        console.log(data);
        var s = 100;
        let arr = [...this.state.compositeArray];
        arr[index].referenceLookup = data;
        this.setState({
            ...this.state,
            compositeArray: arr
        });
        let constaintLookups = [...this.state.compositeArray];
        console.log(constaintLookups, "compositeArray")
        // let arr=[];
        // constaintLookups[index]
        // constaintLookups.filter(function(element,i){
        //     let obj= {
        //         "constraintValue": element.value,
        //         "id": element.id,
        //         "isDefaulted": i===0?true:false,
        //         "lookupId": data.lookupId,
        //         "referenceId": element.referenceId,
        //         "referenceLookup":element.referenceLookup
        //       }
        //       arr.push(obj);
        // })
    }
    // state = {
    //     tableVisible: true,

    // };
    emptyRow = () => {

        const selectedRow = {
            attributeId: null,
            attributeTag: [],
            dataElementId: null,
            dataElementName: null,
            isTagged: false,
            lookUp: {
                "lookupId": "",
                "objectId": "",
                "objectType": ""
            },
            createdBy: "",
            createdOn: null,
            description: "",
            label: "",
            name: "",
            updatedBy: "",
            updatedOn: null,
            metaData: {
                applicationId: null,
                isMandatory: true,
                isVisible: true,
                isNullable: true,
                defaultValue: "",
                isFilterable: true,
                isSortable: true,
                isEditable: true,
                isLookup: false
            },
            workText: false
        };


        // "attributeTag": [
        //   {
        //     "attributeId": "string",
        //     "destinationApp": "string",
        //     "permission": "string",
        //     "sourceApp": "string",
        //     "tagId": "string",
        //     "taggedBy": "string",
        //     "taggedOn": "2021-10-25T06:03:01.963Z"
        //   }
        // ],


        return selectedRow;
    };
    componentDidMount(prevProps) {

        let t;
        fetch("workbox-field-catalog/v1/applications",{headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {
                console.log(this.props.applicationName);
                if (this.props.applicationName) {
                    let t = result.data.filter(ele => ele.name === this.props.applicationName);
                    this.getAttributeList(t[0].applicationId);
                    this.setState({
                        ...this.state, applications: t,
                        taggingapp: result.data ? result.data : []
                    })
                }
                else {
                    this.setState({
                        ...this.state, applications: result.data ? result.data : [],
                        taggingapp: result.data ? result.data : []

                    });
                }

            })
            .catch(({ message }) => {
                alert(message)
            })


    }
    getDestinationList = () => {
        let url;

        url = "workbox-field-catalog/v1/lapi/destination"
        fetch(url,{headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state, destinations: result.data ? result.data : []
                });

            })
    }
    getDataTableList = () => {
        let url;

        url = "workbox-field-catalog/v1/lapi/databaseValuehelp"
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state, dataTable: result.data ? result.data : []
                });

            })
    }
    getAttributeList = (appId) => {
        let url;

        url = "workbox-field-catalog/v1/attribute-master?applicationId=" + appId
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state,
                    selectedApplication: appId,
                    fieldCatalogArray: result.data ? result.data : []
                });

            })
    }
    createNewField = (event, newValue) => {
        
        this.setState({
            ...this.state,
            tableVisible: false,
            tagged: [],
            constraints: [],
            compositeArray: [],
            valueList: [],
            apiMetadata: [],
            lookupType: "VL",
            attributeLapiDataInput: false

        });
    };
    editFields = (row) => {
        
        let tagged = [];
        let applList = this.state.applications;
        if (row.attributeTag) {
            row.attributeTag.filter(function (element) {
                applList.filter(function (appl) {
                    if (element.destinationApp === appl.applicationId) {
                        tagged.push(appl.name)
                    }
                })

            })

        }
        this.setState({
            ...this.state,
            existing: true,
            tagged: tagged,
            tableVisible: false,
            permission: row.attributeTag ? (row.attributeTag[0].permission == "Write" ? true : false) : false,
            selectedRow: row
        });
        if (row.metaData.isLookup && row.metaData.lookupId) {
            let url = "workbox-field-catalog/v1/lapi?lookupId=" + row.metaData.lookupId
            fetch(url, {headers:{"Authorization":this.props.authorization}})
                .then((res) => res.json())
                .then((result) => {

                    //let  constraints= [];
                    let valueList, fields, apiMetadata, constraints = {};
                    if (result.data.lookupType.toUpperCase() === "VL") {
                        valueList = result.data.valueList;
                        this.setState({
                            ...this.state,
                            apiMetadata: result.data.apiMetaData ? result.data.apiMetaData : [],
                            valueList: valueList ? valueList : [],
                            lookupType: "VL"
                        });
                    } else if (result.data.lookupType === "API") {
                        constraints = result.data.apiMetadata.constraints;
                        fields = result.data.apiMetadata.fields;
                        apiMetadata = {
                            "url": result.data.apiMetadata.url,
                            "destination": result.data.apiMetadata.destination,
                            "apiType": result.data.apiMetadata.apiType,
                            "resultPath": result.data.apiMetadata.resultPath,
                        }
                        this.setState({
                            ...this.state,
                            constraints: constraints ? constraints : [],
                            fields: fields,
                            lookupType: "API",
                            apiMetadata: apiMetadata,
                            valueList: result.data.valueList ? [] : []

                        });
                    } else if (result.data.lookupType === "DB") {
                        constraints = result.data.dbMetadata.constraints;
                        fields = result.data.dbMetadata.fields;
                        var dbMetadata = {

                            "destination": result.data.dbMetadata.destination,
                            "lookupTable": result.data.dbMetadata.lookupTable,

                        }
                        apiMetadata = {

                            "destination": result.data.dbMetadata.destination

                        }
                        this.setState({
                            ...this.state,
                            constraints: constraints ? constraints : [],
                            fields: fields,
                            lookupType: "DB",
                            dbMetadata: dbMetadata,
                            //destination:result.data.dbMetadata.destination,
                            //lookupTable: result.data.dbMetadata.lookupTable,
                            apiMetadata: apiMetadata,
                            valueList: result.data.valueList ? [] : []

                        });
                    } else if (result.data.lookupType === "COMPOSITE") {
                        constraints = result.data.compositeMetadata.constraint;

                        var constraint = [];
                        if (constraints) {
                            constraint.push(constraints);
                        }
                        var constraintColumn = result.data.compositeMetadata.constraint.constraintColumn;
                        var fieldCatalogArray = this.state.fieldCatalogArray;
                        var Data = {};
                        fieldCatalogArray.filter(function (element) {
                            if (element.name === constraintColumn) {
                                Data = element;
                            }
                        });
                        var constaintLookups = result.data.compositeMetadata.constaintLookups;

                        if (constaintLookups) {
                            for (var i = 0; i < constaintLookups.length; i++) {
                                constaintLookups[i]["value"] = constaintLookups[i].constraintValue;
                            }


                        }

                        this.setState({
                            ...this.state,
                            constraints: constraint,
                            lookupType: "COMPOSITE",
                            valueList: result.data.valueList ? [] : [],
                            apiMetadata: result.data.apiMetadata ? [] : [],
                            compositeArray: constaintLookups,
                            attributeLapiDataInput: Data.metaData.isLookup
                        });
                        this.getLapiData(Data.metaData);

                    } else if (result.data.lookupType === "FILE") {


                        this.setState({
                            ...this.state,
                            sheets: result.data.fileMetadata.properties.sheets

                        })
                        var sheets = this.state.sheets;
                        sheets.filter(function (element, index) {
                            var num = element.columnStart + 1;
                            let s = '', t;

                            while (num > 0) {
                                t = (num - 1) % 26;
                                s = String.fromCharCode(65 + t) + s;
                                num = (num - t) / 26 | 0;
                            }
                            element.columnStart = s || undefined;
                        });
                        this.setState({
                            ...this.state,
                            apiMetadata: result.data.apiMetaData ? result.data.apiMetaData : [],
                            valueList: result.data.apiMetaData ? result.data.apiMetaData : [],
                            fileMetadata: result.data.fileMetadata ? result.data.fileMetadata : [],
                            constraints: result.data.fileMetadata.constraints ? result.data.fileMetadata.constraints : [],
                            fields: result.data.fileMetadata.fields ? result.data.fileMetadata.fields : [],
                            lookupType: "FILE"
                        });


                    }


                })
        }
    };
    getColumnDescription = (i) => {
        // var sheets = this.state.sheets;
        //                     sheets.filter(function (element,index) {
        //                         var i =  element.columnStart ;
        //    for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        //     ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
        //   }
        //function getColumnDescription(i) {
        const m = i % 26;
        const c = String.fromCharCode(65 + m);
        const r = i - m;
        return r > 0 ? `${this.getColumnDescription((r - 1) / 26)}${c}` : `${c}`;

        //}

        // });
    }

    backToTable = (event) => {
        
        let emptyRow = this.emptyRow();
        this.setState({
            ...this.state,
            existing: false,
            tableVisible: true,
            selectedRow: emptyRow
        }, () => console.log(this.state.tableVisible));

    };
    handleChangeValueHelpType = (event) => {
        
        let state = event.target.value;
        let fields = [];
        if (event.target.value !== "VL") {
            if (event.target.value === "DB" || event.target.value === "API") {
                this.getDestinationList();
                this.getDataTableList();
            }
            this.getAttributeList(this.state.selectedRow.metaData.applicationId);
            if (this.state.fields.length === 0) {
                fields = [
                    {
                        "columnName": this.state.selectedRow.name,
                        "displayName": this.state.selectedRow.label,
                        "id": null,
                        "isDisplayName": true,
                        "mappedName": null,
                        "searchable": true
                    }
                ]

            } else {
                fields = this.state.fields;
                fields[0].columnName = this.state.selectedRow.name
                fields[0].displayName = this.state.selectedRow.label
            }
            // this.setState({
            //     ...this.state,
            //     fields: fields
            // })
        }
        // this.state.selectedRow.isLookup=state;
        this.setState({
            ...this.state,
            fields: fields,
            lookupType: state,
            selectedRow: {
                ...this.state.selectedRow,
                lookupType: state,
                constraints: [],
                compositeArray: [],
                attributeLapiDataInput: false
                // metaData:{
                //     ...this.state.selectedRow.metaData,
                //     lookUpType: state
                // }


            }
        });
    };

    handleChange = (evt) => {
        let value;
        if (evt.target.name !== "defaultValue") {
            value = evt.target.checked;
        } else {
            value = evt.target.value;
        }
        this.setState({
            ...this.state,
            selectedRow: {
                ...this.state.selectedRow,
                metaData: {
                    ...this.state.selectedRow.metaData,
                    [evt.target.name]: value
                }


            }
        })
    }
    workTextSwitch = (event) => {
        let state = event;
        // this.state.selectedRow.isLookup=state;
        this.setState({
            ...this.state,
            selectedRow: {
                ...this.state.selectedRow,
                workText: state

            }
        });
    };
    onValueHelpSwitch = (event) => {
        
        let state = event.target.checked;

        if ((this.state.selectedRow.name === "" || this.state.selectedRow.label === "" || this.state.selectedRow.description === "") && state === true) {
            this.setState({
                ...this.state,

                selectedRow: {
                    ...this.state.selectedRow,
                    metaData: {
                        ...this.state.selectedRow.metaData,
                        isLookup: false
                    }

                },
                snackbarOpen: true,
                snackbarMessg: 'Please fill name, label, description',


            });
            console.log(this.state.selectedRow.metaData.isLookup);
        } else if (this.state.selectedRow.metaData.applicationId === null && state === true) {
            this.setState({
                ...this.state,

                selectedRow: {
                    ...this.state.selectedRow,
                    metaData: {
                        ...this.state.selectedRow.metaData,
                        isLookup: false
                    }

                },
                snackbarOpen: true,
                snackbarMessg: 'Please select application',


            });
            console.log(this.state.selectedRow.metaData.isLookup);
        } else {
            this.setState({
                ...this.state,
                // permission: state,
                selectedRow: {
                    ...this.state.selectedRow,
                    metaData: {
                        ...this.state.selectedRow.metaData,
                        isLookup: state
                    }

                }

            });
            console.log(this.state.selectedRow.metaData.isLookup, "8");
        }
    };
    onPermissionSwitch = (event) => {
        let state = event.target.checked;
        // this.state.selectedRow.isLookup=state;
        this.setState({
            ...this.state,
            permission: state,

        });
    }

    inputChangeHandler = (evt, keyProp) => {
        let value = evt.target.value, isValid, errorMssg = "", isValidObj = {}, lengthVal;
        if (evt.target.name === "name") {
            value = value.toUpperCase();
            this.setState({
                ...this.state,
                selectedRow: {
                    ...this.state.selectedRow,
                    [evt.target.name]: value

                }
            })

        } else if (evt.target.name === "applicationId") {
            this.setState({
                ...this.state,
                selectedRow: {
                    ...this.state.selectedRow,
                    metaData: {
                        ...this.state.selectedRow.metaData,
                        applicationId: keyProp.key.split("$")[1]
                    }

                }

            })
        } else {
            this.setState({
                ...this.state,
                selectedRow: {
                    ...this.state.selectedRow,
                    [evt.target.name]: value

                }
            })
        }

        //this.state.obj[evt.target.name] = evt.target.value;




    };
    inputChangeHandler1 = (evt, val) => {
        
        let value = evt.target.value, key, name;
        let fileTypeProp;
        name = evt.target.name;
        if (val) {
            value = val.name;
            name = evt.target.id.split("-")[0];
        }
        if (this.state.lookupType === "API") {
            key = "apiMetadata";
        } else if (this.state.lookupType === "DB") {
            key = "dbMetadata";
        } else if (this.state.lookupType === "FILE") {
            key = "fileMetadata";
            fileTypeProp = {
                "parentTag": this.state.fileMetadata.properties.parentTag,
                "sheets": this.state.sheets
            };
            if (name === "parentTag") {
                fileTypeProp.parentTag = value;
            }

            // if (value === ".csv") {
            //     fileTypeProp = {
            //         "parentTag": null,
            //         "sheets": null
            //     };
            // } else if (value === ".xml") {
            //     fileTypeProp = {
            //         "parentTag": "",
            //         "sheets": null

            //     }
            // } else if (value === ".xlsx") {
            //     fileTypeProp = {
            //         "parentTag": null,
            //         "sheets": []
            //     }
            // }
        }
        if (this.state.lookupType === "COMPOSITE" && name === "fileType") {
            //this.setState({
            // ...this.state,
            // if(name = "fileType"){
            key = "fileMetadata"
            // },
        }
        if (this.state.lookupType !== "FILE") {
            this.setState({
                ...this.state,
                // if(name = "fileType"){
                //     key = "fileMetadata"
                // },
                [key]: {
                    ...this.state[key],

                    [name]: value

                }
            })
        } else {
            this.setState({
                ...this.state,

                [key]: {
                    ...this.state[key],
                    [name]: value,
                    properties: fileTypeProp

                }
            })
        }
        console.log(this.state.fileMetadata, "fileMetadata")
    }
    inputChangeHandler2 = (evt, val) => {
        
        let value = val.lookupTableId;
        let fileTypeProp;

        this.setState({
            ...this.state,

            dbMetadata: {
                ...this.state.dbMetadata,

                lookupTable: value

            }
        })

    }
    valueHelpTableInputs = (evt, index) => {
        
        let value;
        if (evt.target.name !== "isDisplayName" && evt.target.name !== "searchable") {
            value = evt.target.value;
        } else {
            value = evt.target.checked;
        }
        if (this.state.lookupType === "API" || this.state.lookupType === "DB" || this.state.lookupType === "FILE" || this.state.lookupType === "COMPOSITE") {
            if (evt.target.id === "fieldsTable") {
                let fields = [...this.state.fields];

                if (evt.target.name == "isDisplayName") {
                    fields.filter(function (element) {
                        element.isDisplayName = false;
                    });
                }

                fields[index][evt.target.name] = value;
                if (evt.target.name === "columnName" && evt.target.row) {
                    fields[index]["displayName"] = evt.target.row.label
                }
                this.setState({
                    ...this.state,
                    fields: fields,
                    showVHTableDialog: false
                })
            } else if (evt.target.id === "constraintTable") {
                let constraints = [...this.state.constraints];

                constraints[index][evt.target.name] = value;
                if (evt.target.name === "constraintColumn" && evt.target.row) {
                    constraints[index]["constraintName"] = evt.target.row.label
                }
                this.setState({
                    ...this.state,
                    constraints: constraints,
                    showVHTableDialog: false,
                    // selectedField: evt.target.row.name,
                    // attributeLapiDataInput: evt.target.row.metaData.isLookup
                })
            }
            console.log(this.state.constraints, "constraints");
        } else if (this.state.lookupType === "DB") {

        } else if (this.state.lookupType === "VL") {
            let valueList = [...this.state.valueList];
            valueList[index][evt.target.name] = value;
            this.setState({
                ...this.state,
                valueList: valueList
            })

        }

    }
    addNewConstraint = (event) => {
        let constraints = [...this.state.constraints];
        let obj = {

            "constraintColumn": null,
            "constraintName": null,
            "constraintOperator": "EQ",
            "constraintType": null,
            "constraintValue": null,
            "id": null,
            "mappedName": null
        };

        constraints.push(obj);
        this.setState({
            ...this.state,
            constraints: constraints,
        });
    };
    addNewFields = (event) => {
        let fields = [...this.state.fields];
        let obj = {
            "id": "",
            "columnName": "",
            "displayName": "",
            "searchable": true,
            "isDisplayName": false,
            "mappedName": "",
            "tempId": fields.length - 1
        };

        fields.push(obj);
        this.setState({
            ...this.state,
            fields: fields,
        });
    };
    deleteFieldRows = (row, index) => {

        let fields = [...this.state.fields];
        //  let i;
        // if (row) {
        //     fields.filter(function (element, index) {
        //         if (element.id === row) {
        //             i = index;
        //         }
        //     });
        // }else{
        //     fields.filter(function (element, index) {
        //         if (element.tempId === tempId) {
        //             i = index;
        //         }
        //     });
        // }
        fields.splice(index, 1);
        this.setState({
            ...this.state,
            fields: fields,
        });

    }
    deleteConstraintRows = (row, index) => {
        let constraints = [...this.state.constraints];
        //if(!row.id){
        constraints.splice(index, 1);
        this.setState({
            ...this.state,
            constraints: constraints,
        });


    }
    addNewStaticVL = (event) => {
        let staticVL = [...this.state.valueList];
        let obj = {
            key: "",
            value: "",
            additionalValue: "",

        };

        staticVL.push(obj);
        this.setState({
            ...this.state,
            valueList: staticVL,
        });
    };
    deleteStaticVL = (row, index) => {
        let fields = [...this.state.valueList];
        fields.splice(index, 1);
        this.setState({
            ...this.state,
            valueList: fields,
        });

    }
    discardFieldCatalog() {
        this.setState({
            ...this.state,
            tableVisible: true
        }, () => {
            console.log(this.state.tableVisible, "discard")
        }
        )
    }
    // this.setState({
    //     ...this.state,
    //     //existing: false,
    //     tableVisible: true
    //     //selectedRow: emptyRow
    // }, ()=>console.log(this.state.tableVisible,"discard"));
    //}
    closeDialog = (action) => {
        
        if (action === "DISCARD") {
            this.discardFieldCatalog();
        }
        else if (action === "SUBMIT") {
            //   this.onSaveMappingRoles();
        }
        else if (action === "SAVE") {
            //   this.onSaveMappingRolesDraft();
            this.onSaveFieldCatalog();
        }

        this.setState(
            {
                ...this.state,
                showDialog: false,
                showDialogDiscard: false,
                showDialogSubmit: false,
                showDialogSave: false,
                showValueHelpDialog: false,
                showDataElementDialog: false,
                showVHTableDialog: false,
                showValueHelpDynamicDialog: false
            });

    }
    // permissionChange = () => {



    // }
    onSaveFieldCatalog = () => {
        
        console.log(this.state.existing);
        let createdOn, createdBy, method, url;
        var dDate = new Date().getTime()
        if (this.state.existing) {
            createdOn = this.state.selectedRow.createdOn;
            createdBy = this.state.selectedRow.createdBy;
            method = "PATCH";
            url = "workbox-field-catalog/v1/attribute-master/" + this.state.selectedRow.attributeId + "?applicationId=" + this.state.selectedRow.metaData.applicationId

        } else {
            createdOn = dDate;
            createdBy = this.props.pid;
            method = "POST"
            url = "workbox-field-catalog/v1/attribute-master?applicationId=" + this.state.selectedRow.metaData.applicationId;


        }

        // const payload = {
        //     "createdBy": createdBy,
        //     "createdOn": createdOn,
        //     "dataElementId": this.state.selectedRow.dataElementId,
        //     "dataType": this.state.selectedRow.dataType,
        //     "description": this.state.selectedRow.description,
        //     "isLookup": this.state.selectedRow.isLookup,
        //     "label": this.state.selectedRow.label,
        //     "length": this.state.selectedRow.length,
        //     "lookupId": this.state.selectedRow.lookupId,
        //     "name": this.state.selectedRow.name,
        //     "updatedBy": this.props.pid,
        //     "updatedOn": dDate
        // }
        let attributeTag = this.state.selectedRow.attributeTag;
        let arr = [];
        var permission = this.state.permission === true ? "Write" : this.state.permission === false ? "Read" : this.state.permission;

        // this.setState(
        //     {
        //         ...this.state,
        //         permission: permission
        //     }, () => {
        if (attributeTag.length !== 0) {
            let that = this;

            attributeTag.filter(function (element) {

                let tagObj = {
                    "attributeId": that.state.selectedRow.attributeId,
                    "destinationApp": element.destinationApp,
                    "permission": permission,
                    "sourceApp": that.state.selectedRow.metaData.applicationId,
                    "tagId": element.tagId,
                    "taggedBy": element.taggedBy,
                    "taggedOn": element.taggedOn
                }
                arr.push(tagObj);
            });
        }


        const payload = {
            "attributeId": this.state.selectedRow.attributeId,
            "attributeTag": arr,
            "createdBy": createdBy,
            "createdOn": createdOn,
            "dataElementId": this.state.selectedRow.dataElementId,
            "dataElementName": this.state.selectedRow.dataElementName,
            "description": this.state.selectedRow.description,
            "isTagged": this.state.selectedRow.attributeTag.length > 0 ? true : false,
            "label": this.state.selectedRow.label,
            // "lookUp": {
            //     "lookupId": "string",
            //     "objectId": "string",
            //     "objectType": "string"
            // },
            "metaData": {
                "applicationId": this.state.selectedRow.metaData.applicationId,
                "attributeId": this.state.selectedRow.attributeId,
                "defaultValue": this.state.selectedRow.metaData.defaultValue,
                "isDefaulted": this.state.selectedRow.metaData.defaultValue ? true : false,
                "isEditable": this.state.selectedRow.metaData.isEditable,
                "isFilterable": this.state.selectedRow.metaData.isFilterable,
                "isLookup": this.state.selectedRow.metaData.isLookup,
                "isMandatory": this.state.selectedRow.metaData.isMandatory,
                "isSortable": this.state.selectedRow.metaData.isSortable,
                "isVisible": this.state.selectedRow.metaData.isVisible,
                "isNullable": this.state.selectedRow.metaData.isNullable,
                "lookupId": this.state.selectedRow.metaData.lookupId
            },
            "name": this.state.selectedRow.name,
            "updatedBy": this.props.pid,
            "updatedOn": dDate
        }

        const requestParam1 = {
            method: method,
            headers: { "Content-Type": "application/json", "Authorization":this.props.authorization},
            body: JSON.stringify(payload),
        }
        fetch(url, requestParam1)
            .then((response) => response.json())
            .then((data, message) => {
                console.log(data);
                if (!data.data) {
                    this.setState({
                        ...this.state,

                        snackbarOpen: true,
                        snackbarMessg: data.message,

                    })
                }
                else {
                    if (this.state.selectedRow.metaData.isLookup) {
                        this.fnLookupsave(data.data);
                    }
                    this.getAttributeList(this.state.selectedRow.metaData.applicationId);
                    this.setState({
                        ...this.state,
                        tableVisible: true,
                        snackbarOpen: true,
                        snackbarMessg: data.message

                    })
                }
            })
        //});




    }

    onCancel = () => {
        // this.props.history.push("/roles");
        // this.props.setNameVis(false);
        this.setState({
            ...this.state,

            showDialogDiscard: true

        })
    }

    onSaveDataElement = () => {

        var payload1 = this.state.rolesData;
        var a = this.state.isLookup;
        if (this.state.selectedRow.name === "" || this.state.selectedRow.label === "" || this.state.selectedRow.description === "" || !this.state.selectedRow.metaData.applicationId) {
            this.setState({
                ...this.state,
                //tableVisible: true,
                snackbarOpen: true,
                snackbarMessg: "Please fill name, label, description and application."

            })
        }
        else if (!this.state.selectedRow.dataElementId) {
            this.setState({
                ...this.state,
                //tableVisible: true,
                snackbarOpen: true,
                snackbarMessg: "Please select data element"

            })
        }
        //if(this.state.isLookup)
        else if (this.state.selectedRow.metaData.isLookup) {
            var sheets = this.state.sheets;
            var bFlag = false;
            if (this.state.sheets.length !== 0) {
                sheets.filter(function (element, i) {
                    if (!element.columnStart || !element.rowStart || !element.sheetName) {
                        bFlag = true
                    }
                })
            }
            if (this.state.lookupType === "VL" && (this.state.valueList.length === 0)) {
                this.setState({
                    ...this.state,
                    //tableVisible: true,
                    snackbarOpen: true,
                    snackbarMessg: "Please fill the value list details"

                })
            }
            else if (this.state.lookupType === "API" && (!this.state.apiMetadata.destination || !this.state.apiMetadata.url || !this.state.apiMetadata.apiType)) {
                this.setState({
                    ...this.state,
                    //tableVisible: true,
                    snackbarOpen: true,
                    snackbarMessg: "Please fill destination, valuehelp Url and Api Type."

                })
            }
            else if (this.state.lookupType === "DB" && (!this.state.dbMetadata.destination || !this.state.dbMetadata.lookupTable)) {
                this.setState({
                    ...this.state,
                    //tableVisible: true,
                    snackbarOpen: true,
                    snackbarMessg: "Please fill destination and data Table details."

                })
            }

            else if (this.state.lookupType === "FILE" && (!this.state.fileMetadata.fileType || !this.state.fileMetadata.properties || this.state.sheets.length === 0 || bFlag)) {
                this.setState({
                    ...this.state,
                    //tableVisible: true,
                    snackbarOpen: true,
                    snackbarMessg: "Please fill file type and sheet details by adding sheets."

                })
            }
            else if (this.state.lookupType === "COMPOSITE" && (this.state.constraints.length === 0 || !this.state.constraints[0].constraintColumn)) {
                this.setState({
                    ...this.state,
                    //tableVisible: true,
                    snackbarOpen: true,
                    snackbarMessg: "Please fill the constraint details"

                })
            }
            else {
                this.setState({
                    ...this.state,

                    showDialogSave: true

                })
            }
        } else {
            this.setState({
                ...this.state,

                showDialogSave: true

            })
        }
    }
    fnLookupsave = (data) => {
        
        let method, lookupType = this.state.lookupType, valueList, oPayload, sUrl;
        if (data.metaData.lookupId) {
            method = "PATCH"
            sUrl = "workbox-field-catalog/v1/lapi/" + data.metaData.lookupId + "?objectId=" + data.attributeId + "&objectType=FC"
        } else {
            method = "POST";
            sUrl = "workbox-field-catalog/v1/lapi" + "?objectId=" + data.attributeId + "&objectType=FC";
        }
        if (lookupType === "VL") {
            oPayload = {
                "lookupId": data.metaData.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "valueList": this.state.valueList
            }
        } else if (lookupType === "API") {

            oPayload = {
                "lookupId": data.metaData.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "apiMetadata": {
                    "apiType": this.state.apiMetadata.apiType,
                    "constraints": this.state.constraints,
                    "destination": this.state.apiMetadata.destination,
                    "fields": this.state.fields,
                    "pagination": {
                        "allowed": true,
                        "recordsPerPage": "30"
                    },
                    "resultPath": this.state.apiMetadata.resultPath,
                    "url": this.state.apiMetadata.url
                },

            }
        } else if (lookupType === "DB") {
            oPayload = {
                "lookupId": data.metaData.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "dbMetadata": {
                    "constraints": this.state.constraints,
                    "databasePropertiesDto": {
                        "dbType": "string",
                        "lookupId": "string",
                        "password": "string",
                        "url": "string",
                        "userName": "string"
                    },
                    "destination": this.state.dbMetadata.destination,
                    "fields": this.state.fields,
                    "lookupTable": this.state.dbMetadata.lookupTable,
                    "pagination": {
                        "allowed": true,
                        "recordsPerPage": "30"
                    }
                },

            }
        } else if (lookupType === "FILE") {
            let sheets = [...this.state.sheets]
            if (this.state.sheets.length !== 0) {
                sheets.filter(function (element, i) {
                    if (element.columnStart) {
                        element.columnStart = element.columnStart.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1;
                    }
                })
            }

            oPayload = {
                "lookupId": data.metaData.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "fileMetadata": {
                    "constraints": this.state.constraints,
                    "fields": this.state.fields,
                    "fileType": this.state.fileMetadata.fileType.toUpperCase(),
                    "lookupId": data.metaData.lookupId,
                    "properties": {
                        "parentTag": this.state.fileMetadata.properties.parentTag,
                        "sheets": this.state.sheets
                    }
                },
            }
        }
        else if (lookupType === "COMPOSITE") {
            let constaintLookups = this.state.compositeArray;
            console.log(constaintLookups, "compositeArray2");
            let arr = [];
            constaintLookups.filter(function (element, i) {

                let obj = {
                    "constraintValue": element.value,
                    "id": element.id,
                    "isDefaulted": i === 0 ? true : false,
                    "lookupId": data.metaData.lookupId,
                    "referenceId": element.referenceId,
                    "referenceLookup": element.referenceLookup
                }
                arr.push(obj);
            })


            oPayload = {
                "lookupId": data.metaData.lookupId,
                "lookupName": data.name + "_" + lookupType,
                "lookupType": lookupType,
                "compositeMetadata": {
                    "constaintLookups": arr,
                    "constraint": {
                        "constraintColumn": this.state.constraints[0].constraintColumn,
                        "constraintName": this.state.constraints[0].constraintName,
                        "constraintOperator": this.state.constraints[0].constraintOperator,
                        "constraintType": this.state.constraints[0].constraintType,
                        "constraintValue": this.state.constraints[0].constraintValue,
                        "id": this.state.constraints[0].id,
                        "mappedName": this.state.constraints[0].mappedName
                    }
                },

            }
        }
        const requestParam1 = {
            method: method,
            headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
            body: JSON.stringify(oPayload),
        }
        if (this.state.constraints) {
            fetch(sUrl, requestParam1)
                .then((response) => response.json())
                .then((data, message) => {
                    console.log(data);

                    this.setState({
                        ...this.state,
                        snackbarOpen: true,
                        snackbarMessg: 'Data Element is Saved!'

                    })
                }
                )
        } else {
            this.setState({
                ...this.state,
                snackbarOpen: true,
                snackbarMessg: 'Select constraint!'

            })
        }

    }
    onValueHelp = () => {
        let url = "workbox-field-catalog/v1/data-element"
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {
                this.setState({
                    ...this.state,
                    dataElement: result.data ? result.data : [],
                    showValueHelpDialog: true
                });

            })

    }
    onDataElementDialog = () => {
        // let url = "workbox-field-catalog/v1/data-element"
        // fetch(url)
        //     .then((res) => res.json())
        //     .then((result) => {
        this.setState({
            ...this.state,
            // dataElement: result.data ? result.data : [],
            showDataElementDialog: true
        });

        // })

    }
    applicationChangeHandler = (evt, keyProp) => {
        
        let url;

        url = "workbox-field-catalog/v1/attribute-master?applicationId=" + keyProp.props.value
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state,
                    selectedApplication: evt.target.value,
                    fieldCatalogArray: result.data ? result.data : []
                });

            })
    }
    fnTagging = (event, keyProp) => {
        var taggedValue = [];
        let createdOn = new Date().getTime();
        let createdBy = this.props.pid;
        let id = keyProp.key.split("$")[1];

        let value = event.target.value
        taggedValue.push();
        // this.setState({
        //     ...this.state,
        //     tagged: value,

        // });

        let exist = false;
        let arr1 = this.state.selectedRow.attributeTag;
        if (arr1.length !== 0 && arr1[0].destinationApp === null) {
            arr1.splice(0);
        }
        let exElement = arr1.filter(function (element, index) {
            if (element.destinationApp === id || element.sourceApp === id) {
                exist = true;
                return index;
            }
        })
        if (id !== this.state.selectedRow.metaData.applicationId) {
            if (!exist) {

                let obj =
                {
                    "destinationApp": keyProp.key.split("$")[1],
                    "tagId": null,
                    "taggedBy": createdOn,
                    "taggedOn": createdOn
                }
                arr1.push(obj);
                this.setState({
                    ...this.state,
                    tagged: value,
                    // taggedDetails: arr,
                    selectedRow: {
                        ...this.state.selectedRow,
                        attributeTag: arr1

                    }
                });
            } else {
                arr1.splice(exElement);
                this.setState({
                    ...this.state,
                    tagged: value,
                    // taggedDetails: arr,
                    selectedRow: {
                        ...this.state.selectedRow,
                        attributeTag: arr1

                    }
                });
                // exElement.tag
            }
        }


    }
    setTaggedApplication = (evt, option, index) => {
        let exist = false;
        let arr = [...this.state.taggedDetails];
        let arr1 = this.state.selectedRow.attributeTag;
        let exIndex = arr1.filter(function (element, index) {
            if (element.destinationApp === option.applicationId) {
                exist = true;
                return index;
            }
        })
        if (!exist) {
            var dDate = new Date().getTime()
            let createdOn = dDate;
            let createdBy = this.props.pid;
            let obj =
            {
                "destinationApp": option.applicationId,
                "tagId": null,
                "taggedBy": createdOn,
                "taggedOn": createdOn
            }
            arr1.push(obj);
            arr.push(option);
            this.setState({
                ...this.state,
                taggedDetails: arr,
                selectedRow: {
                    ...this.state.selectedRow,
                    attributeTag: arr1

                }
            });
        } else {
            arr1.splice(exIndex);
        }

    }
    rowSelected = (row) => {
        
        this.setState({
            ...this.state,
            showValueHelpDialog: false,
            dataElementRow: row,
            selectedRow: {
                ...this.state.selectedRow,
                dataElementId: row.dataElementId,
                dataElementName: row.name

            }
        })
    }
    rowSelectedDynamic = (row, i) => {
        
        let key = row[this.state.selectedField];
        let arr = [...this.state.compositeArray];
        if (!key) {
            key = row[this.state.dynamicColumn[0].mappedName]
        }
        let value;
        var that = this;
        this.state.dynamicColumn.filter(function (element) {
            if (element.isDisplayName) {
                arr[that.state.compositeArrayIndex].value = row[element.mappedName]
            }
        });


        arr[this.state.compositeArrayIndex].key = key

        this.setState({
            ...this.state,
            showValueHelpDynamicDialog: false,
            compositeArray: arr

        })
    }
    rowSelectedValueHelp = (row) => {
        
        if (this.state.tableType === "constraintTable") {
            let evt = {
                target: {
                    row: row,
                    value: row.name,
                    id: "constraintTable",
                    name: "constraintColumn"
                }
            }
            this.setState({
                ...this.state,
                selectedField: row.name,
                attributeLapiDataInput: row.metaData.isLookup

            }, () => this.valueHelpTableInputs(evt, this.state.index))
            //     setTimeout(() => {



            // }, 2000)

        } else {
            let evt = {
                target: {
                    row: row,
                    value: row.name,
                    id: "fieldsTable",
                    name: "columnName"
                }
            }
            // this.setState({
            //     ...this.state,
            //     selectedField: row.name,
            //     attributeLapiDataInput: row.metaData.isLookup
            // })
            this.valueHelpTableInputs(evt, this.state.index)


        }

        //if (this.state.lookupType === "COMPOSITE" && row.metaData.isLookup) {
        console.log(row, "row");
        this.getLapiData(row.metaData);
        console.log(row.metaData, "row.metaData");
        //}

    }
    rowSelectedValueHelpFields = (row) => {
        let evt = {
            target: {
                row: row,
                value: row.name,
                id: "fieldsTable",
                name: "columnName"
            }
        }
        // this.setState({
        //     ...this.state,
        //     selectedField: row.name,
        //     attributeLapiDataInput: row.metaData.isLookup
        // })
        this.valueHelpTableInputs(evt, this.state.index)

        if (this.state.lookupType === "COMPOSITE" && row.metaData.isLookup) {
            this.getLapiData(row.metaData);
        }

    }
    getLapiConfig = (row) => {
        let sUrl = "workbox-field-catalog/v1/lapi?lookupName=" + row.name
        fetch(sUrl, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {
                if (result.data.lookupType.toUpperCase() === "VL") {
                    this.setState({
                        ...this.state,
                        valueList: result.valueList
                    })
                } else {
                    this.getLapiData(result)
                }



            })
    }
    getLapiData = (data) => {
        console.log(data, "data");
        if (data.lookupId && data.isLookup === true) {
            let sUrl = "workbox-field-catalog/v1/lapi/data?lookupId=" + data.lookupId

            fetch(sUrl, {headers:{"Authorization":this.props.authorization}})
                .then((res) => res.json())
                .then((result) => {
                    let dynamicColumn, row;
                    console.log(result);
                    if (result.data.metaData) {
                        dynamicColumn = result.data.metadata;
                        row = result.data.values;
                    } else {
                        row = result.data.values;
                        dynamicColumn = [{
                            "id": null,
                            "columnName": "key",
                            "displayName": "Key",
                            "searchable": true,
                            "isDisplayName": false,
                            "mappedName": "key"
                        }, {
                            "id": "1",
                            "columnName": "value",
                            "displayName": "Text",
                            "searchable": false,
                            "isDisplayName": true,
                            "mappedName": "value"
                        }, {
                            "id": "2",
                            "columnName": "additionalValue",
                            "displayName": "Additional Text",
                            "searchable": false,
                            "isDisplayName": false,
                            "mappedName": "additionalValue"
                        }];
                    }
                    this.setState({
                        ...this.state,
                        dynamicColumn: dynamicColumn,
                        rows: row ? row : []
                    })



                })
        }
    }
    sheetsInputs = (evt, index) => {
        
        let value, name;
        name = evt.target.name
        value = evt.target.value;
        let sheets = [...this.state.sheets];
        if (evt.target.name === "sheetIndex") {
            if (parseInt(value)) {
                name = "sheetIndex";
                sheets[index].sheetName = null;
            } else {
                name = "sheetName";
                sheets[index].sheetIndex = null;
            }
        }
        sheets[index][name] = value;
        this.setState({
            ...this.state,
            sheets: sheets
        })
    }
    deleteSheet = (evt, index) => {
        let sheets = [...this.state.sheets];

        sheets.splice(index, 1);
        this.setState({
            ...this.state,
            sheets: sheets,
        });
    }
    deleteComposite = (row, index) => {
        
        let compositeArray = [...this.state.compositeArray];
        //let compositeData = this.state.selectedRow;

        // const requestParam = {
        //     method: "DELETE",
        //     headers: { "Content-Type": "application/json" },
        // }
        //if(!compositeArray[index].referenceId){
        compositeArray.splice(index, 1);
        this.setState({
            ...this.state,
            compositeArray: compositeArray,
        });
        //}else{
        //fetch("/workbox-field-catalog/v1/lapi/" + compositeArray[index].referenceId, requestParam)
        // .then((res) => res.json())
        // .then((result) => {

        //     this.editFields(compositeData);
        // this.setState({
        //     ...this.state, applications: result.data ? result.data : []
        // });

        //})

        //}
    }
    addSheets = (event) => {
        var sheet = this.state.sheets;
        let obj = {
            "sheetName": null,
            "sheetIndex": null,
            "rowStart": null,
            "columnStart": null
        }
        sheet.push(obj);
        this.setState({
            ...this.state,

            sheets: sheet

        })
    }
    addComposite = () => {
        var compositeArray = this.state.compositeArray;
        let obj = {
            "key": null,
            "value": null,
            "lookupType": null,
            "referenceId": null
        }
        compositeArray.push(obj);
        this.setState({
            ...this.state,

            compositeArray: compositeArray

        })
    }
    fnAttrValueHelp = (index, evt, table) => {
        
        console.log(index, evt);
        this.setState({
            ...this.state,
            showVHTableDialog: true,
            index: index,
            tableType: table

        });

    }
    compositeValueHelp = (index) => {
        
        this.setState({
            ...this.state,
            showValueHelpDynamicDialog: true,
            compositeArrayIndex: index


        });
    }
    onLookupType = (evt, keyProps, index) => {

        let arr = [...this.state.compositeArray];




        arr[index].lookupType = keyProps.props.value

        this.setState({
            ...this.state,
            lookupTypeC: false,
            compositeArray: arr

        })
    }
    render() {

        const userAccess = this.props.userAccess;
        console.log(userAccess);
        const CreateFieldCatalog = userAccess.activities.filter(
            ele => ele === "Create Field Catalog"
        ) ? true : false;
        // ) ? true : true;
        return (
            <div>

                {(this.state.tableVisible) && <div>
                    <span className="iconBtn" style={{ float: 'right', margin: '1rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disabled={!CreateFieldCatalog}
                            style={{ marginLeft: 10 , padding: 2}}
                            onClick={this.createNewField}
                        //className={classes.buttonAdd}
                        >
                            Create
                        </Button>
                    </span>
                    <span style={{ float: 'left', margin: '1rem' }}>


                        {this.props.applicationName ? <FormControl
                            style={{ width: 220 }}
                            variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-outlined-label">Application</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Application"
                                value={this.state.selectedApplication}
                                // size="small"
                                name="selectedApplication"
                                style={{ width: "100%" , height: "2rem"}}
                                disabled
                            >
                                {this.state.applications.map(option =>
                                    <MenuItem value={option.applicationId} key={option.applicationId}
                                    >{option.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl> :
                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-outlined-label">Application</InputLabel>
                                <Select

                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Application"
                                    value={this.state.selectedApplication}
                                    // size="small"
                                    name="selectedApplication"
                                    style={{ width: "100%", height: "2rem" }}

                                    onChange={(evt, keyProp) => this.applicationChangeHandler(evt, keyProp)}
                                >
                                    {this.state.applications.map(option =>
                                        <MenuItem value={option.applicationId} key={option.applicationId}
                                        >{option.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>}

                    </span>

                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Name</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Label</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Description</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> Data Element</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.fieldCatalogArray.map((row) => {
                                    return (


                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}


                                            <TableCell >{row.name}</TableCell>
                                            <TableCell >{row.label}</TableCell>
                                            <TableCell >{row.description}</TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            <TableCell>{row.dataElementName}</TableCell>
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton aria-label="Edit" style={{ color: "green" }}
                                                    onClick={() => this.editFields(row)}
                                                >
                                                    <ArrowForwardIosIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>}
                {(!this.state.tableVisible) && <div>
                    <span style={{ float: 'left', margin: '0.1rem' }}>
                        <IconButton aria-label="Edit" style={{ color: "blue", size: "2rem" }}
                            onClick={this.backToTable}
                        >
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <label className="customLabel"  >Create Field Catalog </label>
                    </span>
                    <Grid container spacing={0} hSpacing="0rem" 
                        style={{
                            paddingLeft: "1rem", width: "100%", margin: "0rem", paddingBottom: "0px"
                        }}
                    >

                        <Grid item xs={12} sm={3} md={3} 
                        // style={{
                        //     paddingLeft: "0rem", width: "100%", margin: "0rem"
                        // }}
                        >

                            <TextField
                                required
                                id="filled-disabled"
                                label="Name"
                                name="name"
                                value={this.state.selectedRow.name}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />

                        </Grid>
                        <Grid item xs={12} sm={3} md={3} 
                        // style={{
                        //     paddingLeft: "1rem", width: "100%", margin: "0rem"
                        // }}
                        >
                            <TextField
                                required
                                id="filled-disabled"
                                label="Label"
                                name="label"
                                value={this.state.selectedRow.label}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                id="filled-disabled"
                                label="Description"
                                name="description"
                                value={this.state.selectedRow.description}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.inputChangeHandler(evt)}
                                inputProps={{ maxLength: 30 }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            {/* <Paper>
                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-outlined-label" required>Data Element</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.selectedRow.applicationId}
                                    // size="small"
                                    name="dataElement"
                                    style={{ width: "100%" }}
                                    onChange={(evt) => this.inputChangeHandler(evt)}
                                > */}
                            {/* {this.state.applicationList.map(option =>
                                        <MenuItem value={option.id} key={option.id}
                                        // onClick={() =>
                                        //     this.applicationChangeHandler(option.id, index)

                                        // }
                                        // style={{ width: 30 }}
                                        >{option.name}</MenuItem>
                                    )} */}

                            {/* </Select>
                            </FormControl>
                            <IconButton 
                                onClick={(event) => this.handlePopoverOpen(event)}
                                
                               
                                
                            >
                                <InfoSharp />
                            </IconButton>
                            </Paper> */}
                            {/* <Paper
                                component="form"
                                style={{ width: 250 }}
                                
                            > */}
                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                {/* <InputLabel shrink required  id="outlined-read-only-input">
                                    Data Element
                                </InputLabel> */}

                                <TextField
                                    // labelId="outlined-input"
                                    InputLabelProps={{ shrink: true }}
                                    //  id="outlined-read-only-input"
                                    // id="filled-disabled"
                                    id="outlined-basic"
                                     label="Data Element"
                                     value={this.state.selectedRow.dataElementName}
                                     variant="outlined"
                                    //  defaultValue= {this.state.selectedRow.dataElementName}
                                    // disabled
                                    InputProps={{
                                         readOnly: true,
                                          shrink: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={!CreateFieldCatalog}
                                                    onClick={this.onValueHelp}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    <SvgIcon >

                                                        <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                    </SvgIcon >
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}

                                >

                                </TextField>
                                {/* <Link
                                    variant="body2"
                                    component="button"
                                    underline="hover"
                                    onClick={() => this.props.navigateToDataElement()}
                                >
                                    Create dataElement
                                </Link> */}

                            </FormControl>
                            <IconButton
                                disabled={!CreateFieldCatalog}
                                onClick={this.onDataElementDialog}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                <InfoSharp />
                            </IconButton>
                            {/* </Paper> */}
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>

                            {/* <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel  >Application</InputLabel>
                                <Select
                                    
                                    value={this.state.selectedApplication}
                                   
                                    name="selectedApplication"
                                    style={{ width: "100%" }}
                                    onChange={(evt) => this.inputChangeHandler(evt)}
                                >
                                    {this.state.applications.map(option =>
                                        <MenuItem value={option.id} key={option.applicationId}
                                        // onClick={() =>
                                        //     this.applicationChangeHandler(option)

                                        // }
                                       
                                        >{option.label}</MenuItem>
                                    )}

                                </Select>
                            </FormControl> */}
                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-outlined-label">Application</InputLabel>
                                <Select

                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Application"
                                    value={this.state.selectedRow.metaData.applicationId}
                                    // size="small"
                                    name="applicationId"
                                    style={{ width: "100%" }}
                                    onChange={(evt, value) => this.inputChangeHandler(evt, value)}
                                >
                                    {this.state.applications.map(option =>
                                        <MenuItem value={option.applicationId} key={option.applicationId}

                                        >{option.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-outlined-label">Tagging</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Tagging"
                                    value={this.state.tagged}
                                    multiple
                                    // size="small"
                                    name="tagging"
                                    style={{ width: "100%" }}
                                    onChange={(evt, value) => this.fnTagging(evt, value)}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {this.state.taggingapp.map(option =>

                                        <MenuItem value={option.label} key={option.applicationId}
                                            selected={this.state.tagged.indexOf(option.applicationId) > -1}
                                        // onClick={(event) =>
                                        //     this.setTaggedApplication(event, option, this.state.tagged.indexOf(option.applicationId))

                                        // }

                                        >
                                            {/* <Checkbox checked={this.state.tagged.indexOf(option.name) > -1} /> */}
                                            <ListItemText primary={option.label} /></MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={3} md={3}>
                            <FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} >
                               
                                <FormGroup aria-label="position" row >
                                    <FormControlLabel
                                        label='Value Help'
                                        size="small"
                                        checked={this.state.selectedRow.isLookup}
                                        //checked={this.state.obj.active}
                                        name="active"
                                        onChange={(evt) => this.onValueHelpSwitch(evt)}
                                        //value={row.active}
                                        labelPlacement="start"
                                        control={<Switch color="primary" />}
                                    />
                                </FormGroup>
                            </FormControl >
                        </Grid> */}
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField

                                id="filled-disabled"
                                label="Default Value"
                                name="defaultValue"
                                value={this.state.selectedRow.metaData.defaultValue}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.handleChange(evt)}
                            // style={{ marginTop: '1rem' }}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            {/* <FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} > */}
                            <InputLabel id="demo-simple-select-outlined-label" >Permission</InputLabel>
                            {/* <FormGroup aria-label="position" row > */}

                            {/* <FormControlLabel
                                        label='Permission'
                                        size="small"
                                        checked={this.state.selectedRow.isLookup}
                                        //checked={this.state.obj.active}
                                        name="active"
                                        onChange={(evt) => this.onValueHelpSwitch(evt)}
                                        //value={row.active}
                                        labelPlacement="top"
                                        control={<Switch color="primary" />}
                                    /> */}
                            <Stack direction="row" spacing={1} alignItems="center"
                                onChange={(evt) => this.onPermissionSwitch(evt)}
                                label='Permission'>
                                <Typography>Read</Typography>
                                <Switch state={this.state.permission} checked={this.state.permission} color="primary" inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>Write</Typography>
                            </Stack>

                            {/* </FormGroup> */}
                            {/* </FormControl > */}
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">Properties</FormLabel>
                                <FormGroup row={true}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.selectedRow.metaData.isEditable} onChange={this.handleChange} name="isEditable"
                                                color="primary" />
                                        }
                                        label="Editable"
                                        style={{ marginRight: '1rem' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.selectedRow.metaData.isVisible} onChange={this.handleChange} name="isVisible"
                                                color="primary" />
                                        }
                                        label="Visible"
                                        style={{ marginRight: '1rem' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.selectedRow.metaData.isMandatory} onChange={this.handleChange} name="isMandatory" color="primary" />
                                        }
                                        label="Mandatory"
                                        style={{ marginRight: '1rem' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.selectedRow.metaData.isFilterable} onChange={this.handleChange} name="isFilterable" color="primary" />
                                        }
                                        label="Filterable"
                                        style={{ marginRight: '1rem' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.selectedRow.metaData.isSortable} onChange={this.handleChange} name="isSortable" color="primary" />
                                        }
                                        label="Sortable"
                                        style={{ marginRight: '1rem' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.state.selectedRow.metaData.isNullable} onChange={this.handleChange} name="isNullable" color="primary" />
                                        }
                                        label="Nullable"
                                        style={{ marginRight: '1rem' }}
                                    />
                                </FormGroup>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControl component="fieldset" style={{ width: "100%" }} alignItems="left">
                                {/* <InputLabel id="demo-simple-select-outlined-label" ></InputLabel> */}
                                <FormGroup aria-label="position" row >

                                    <FormControlLabel
                                        
                                        label='Text Translation'
                                        size="small"
                                        //checked={row.active}
                                        checked={this.state.selectedRow.workText}
                                        name="active"
                                        onChange={(evt) => this.workTextSwitch(evt.target.checked)}
                                        //value={row.active}
                                        labelPlacement="top"
                                        alignItems="left"
                                        control={<Switch color="primary" />}


                                    />


                                </FormGroup>
                            </FormControl >
                        </Grid>

                        <Grid item xs={12} sm={3} md={3}>
                            <FormGroup aria-label="position" row >

                                <FormControlLabel

                                    label='is FieldCatalog lookup'
                                    size="small"
                                    //checked={row.active}
                                    disabled={!CreateFieldCatalog}
                                    checked={this.state.selectedRow.metaData.isLookup}
                                    name="active"
                                    onChange={(evt) => this.onValueHelpSwitch(evt)}
                                    //value={row.active}
                                    labelPlacement="top"
                                    control={<Switch color="primary" />}


                                />


                            </FormGroup>
                        </Grid>
                    </Grid>
                    {(this.state.selectedRow.metaData.isLookup || this.state.selectedRow.workText) && <div>
                        <TabContext value={this.state.value} >
                            <AppBar position="static">
                                <TabList onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                                    <Tab label="Value Help" value="ValueHelp" hidden={!this.state.selectedRow.metaData.isLookup} />
                                    <Tab label="Text Translation" value="WorkText" hidden={!this.state.selectedRow.workText} />

                                </TabList>
                            </AppBar>
                            <div style={{ "overflow-y": "scroll", "overflow-x": "hidden" }}>
                                <TabPanel value="ValueHelp" hidden={!this.state.selectedRow.metaData.isLookup}>
                                    <ValueHelp  {...this.props} selectedRow={this.state.selectedRow} ref={this.valuehelpref}
                                        constraints={this.state.constraints} fields={this.state.fields} parentState={this.state}
                                        handleChangeValueHelpType={this.handleChangeValueHelpType} addNewConstraint={this.addNewConstraint}
                                        addNewFields={this.addNewFields}
                                        deleteFieldRows={(row, index) => this.deleteFieldRows(row, index)}
                                        deleteConstraintRows={(row, index) => this.deleteConstraintRows(row, index)}
                                        addNewStaticVL={this.addNewStaticVL}
                                        deleteStaticVL={(row, index) => this.deleteStaticVL(row, index)}
                                        inputChangeHandler1={(evt, index) => this.inputChangeHandler1(evt, index)}
                                        inputChangeHandler2={(evt, index) => this.inputChangeHandler2(evt, index)}
                                        valueHelpTableInputs={(evt, index) => this.valueHelpTableInputs(evt, index)}
                                        addSheets={this.addSheets}
                                        deleteSheet={(row, index) => this.deleteSheet(row, index)}
                                        sheetsInputs={(evt, index) => this.sheetsInputs(evt, index)}
                                        rowSelected={(row, index) => this.rowSelectedValueHelp(row, index)}
                                        rowSelectedFields={(row, index) => this.rowSelectedValueHelpFields(row, index)}
                                        fnAttrValueHelp={(index, evt, table) => this.fnAttrValueHelp(index, evt, table)}
                                        compositeValueHelp={(index) => this.compositeValueHelp(index)}
                                        closeDialog={this.closeDialog}
                                        addComposite={this.addComposite}
                                        onLookupType={(row, props, index) => this.onLookupType(row, props, index)}
                                        deleteComposite={(row, index) => this.deleteComposite(row, index)}
                                        getData={(payload, index) => this.getData(payload, index)} />


                                </TabPanel>
                                {(this.state.selectedRow.workText) && <div>
                                    <TabPanel value="WorkText" hidden={this.state.selectedRow.workText}>

                                        {/* <DataElementDetails {...this.props} fieldCatalogs={this.state.dataElement}/> */}

                                    </TabPanel>
                                </div>}
                            </div>

                        </TabContext>
                    </div>}



                </div>}
                {(!this.state.tableVisible) && <div className="footerdiv">
                    <Button variant="contained" color="primary" size="small" onClick={this.onSaveDataElement} disabled={!CreateFieldCatalog}>Save</Button>
                    <Button style={{ margin: '0 0.5rem' }} variant="outlined" color="primary" size="small" onClick={this.onCancel}>Cancel</Button>
                </div>}
                <MessageBoxComponentSave title="save" open={this.state.showDialogSave} actions={["SAVE", "CANCEL"]} onClose={this.closeDialog} />
                <MessageBoxComponentDiscard title="discard" open={this.state.showDialogDiscard} actions={["DISCARD", "CANCEL"]} onClose={this.closeDialog} />
                <ValueHelpDialog title="Value Help" open={this.state.showValueHelpDialog} actions={["DISCARD", "CANCEL"]} onClose={this.closeDialog} dataElement={this.state.dataElement}
                    rowSelected={(row) => this.rowSelected(row)} />
                <ValueHelpDynamicDialog title="Value Help" open={this.state.showValueHelpDynamicDialog} actions={["DISCARD", "CANCEL"]} onClose={this.closeDialog} dataElement={this.state.rows}
                    rowSelected={(row, i) => this.rowSelectedDynamic(row, i)} dynamicColumn={this.state.dynamicColumn} />
                <Snackbar open={this.state.snackbarOpen} autoHideDuration={3000} onClose={() => this.setState({ ...this.state, snackbarOpen: false })}
                    message={this.state.snackbarMessg} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} />
                <DataElementDialog title="Data Element" open={this.state.showDataElementDialog} actions={["DISCARD", "CANCEL"]} onClose={this.closeDialog} rowData={this.state.dataElementRow}
                />
            </div>
        );
    }
}

export default withRouter(CwMSFieldsDetails);