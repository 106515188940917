import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FieldsDetails from './CwMSFieldsDetails';
import DataElementDetails from './CwMSDataElementDetails';
import Email from './CwMSEmailtemplate';

class CwMSFields extends React.Component {

    constructor(props) {
        super(props);
        this.settingsPopoverRef = React.createRef();
        this.state = {
            // value: this.props.fieldCatalogArr[0],
            value: "DataElement",
            fieldCatalogs: [{
                attributeId: "APP",
                createdBy: "P000032",
                createdOn: 1619198922671,
                dataType: "NVARCHAR",
                description: "Application ID",
                destinations: null,
                isLookup: false,
                label: "Application ID",
                length: "100",
                lookUpId: "67f8943-c47a-425d-9f9a-06e4ef996bu",
                lookUpType: "API",
                lookupConfig: null,
                name: "APP",
                source: "WR",
                updatedBy: "P000032",
                updatedOn: 1619198922671,
                propertyDto: {
                    isMandatory: true,
                    isVisible: true,
                    isNullable: true,
                    defaultValue: "abc",
                    isFilterable: true,
                    isSortable: true,
                    isEditable: true
                },
                workText: true
            }],
            dataElement: [{
                attributeId: "ADD",
                createdBy: "P000032",
                createdOn: 1619198922671,
                dataType: "NVARCHAR",
                description: "Address",
                destinations: null,
                isLookup: true,
                label: "Address",
                length: "100",
                lookUpId: "67f8943-c47a-425d-9f9a-06e4ef996bu",
                lookUpType: "VL",
                lookupConfig: null,
                name: "ADD",
                source: "WR",
                updatedBy: "P000032",
                updatedOn: 1619198922671,
               
            }]
        };
    }

    handleChange = (event, newValue) => {
        this.setState({
            ...this.state,
            value: newValue
        });
    };



    goToMsgComp = event => {
        this.props.history.push('/messageSummary');
    }

    goToThemeComp = event => {
        this.props.history.push('/themeStyling');
    }
    render() {
        const fieldCatalogArr = this.props.fieldCatalogArr;
         const userAccess = this.props.userAccess;
        const fieldCatalog = userAccess.entities.find(ele => ele === "Field Catalog") ? true : false;
        const dataElement = userAccess.entities.find(ele => ele === "Data Elements") ? true : false;
        // const configurationEntity = userAccess.entities.find(ele => ele.name === "Configuration") ? true : false;
        // const rulesEntity = userAccess.entities.find(ele => ele.name === "Rules") ? true : false;

        return (
            <div>

                <TabContext value={this.state.value}>
                    <AppBar position="static">
                        <TabList onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                        {/* {fieldCatalogArr.map((item) => {
                                    if (item === "Data Elements")
                                        return (<Tab label="Data Elements" value="Data Elements" />)
                                    
                                    else 
                                        return (<Tab label="Field Catalog" value="Field Catalog" />)
                                        
                                    
                                })} */}
                        <Tab label="Field Catalog" hidden ={!fieldCatalog} value="FieldsCatalog" />
                            <Tab label="DataElement" hidden ={!dataElement} value="DataElement" />
                        </TabList>
                    </AppBar>
                    {/* {fieldCatalogArr.map((item) => {
                            
                            if (item === "Data Elements")
                                return (<TabPanel value="Data Elements">
                                <DataElementDetails {...this.props} fieldCatalogs={this.state.dataElement}/>
                                
                             </TabPanel>)
                            
                            else 
                                return (<TabPanel value="Field Catalog">
                                <FieldsDetails {...this.props} fieldCatalogs={this.state.fieldCatalogs}/>
                            </TabPanel>)
                        })}  */}
                     <TabPanel value="FieldsCatalog" >
                        <FieldsDetails {...this.props} fieldCatalogs={this.state.fieldCatalogs}/>
                    </TabPanel>
                    <TabPanel value="DataElement" >
                        <DataElementDetails {...this.props} fieldCatalogs={this.state.dataElement}/> 
            
                      </TabPanel> 
                   

                    

                </TabContext>
               
            </div>
        );
    }
}

export default withRouter(CwMSFields);