import React, { Component, KeyboardEventHandler } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Table from "@material-ui/core/Table";
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
//import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MessageBoxComponentDelete from "./CwWfDelete";
import MessageBoxComponentSave from "./CwWfSaveConfirmation";
import Snackbar from '@material-ui/core/Snackbar';
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import './CwMSEntity.css';

const components = {
  DropdownIndicator: null,
};


// interface State {
//   readonly inputValue: string;
//   readonly value: readonly Option[];
// }

const createOption = (label) => ({
  label,
  value: label,
});


class CwMSEntity extends React.Component {
  constructor(props) {
    super(props);
    
    this.settingsPopoverRef = React.createRef();

    this.state = {
      applicationmenu: [],
      applicationlist:[],
      application:'',
      appDesc:'',
      hierarchy:[],
      hierarchy1:[],
      isLoaded: false,
      isLoaded1: false,
      tableVisible: true,
      inputValue: '',
      value: "",
      items: [],
      temp_index:"",
      temp_index_entity:"",
      temp_index_process:"",
      messageOpen:false,
      messageContent:"",
      error: null,
      showDialog: false,
          showDialogDiscard: false,
          showDialogDiscardentity: false,
          showDialogSubmit: false,
          showDialogSave: false,
          showValueHelpDialog: false,
          showDataElementDialog: false,
          showVHTableDialog: false,
          showValueHelpDynamicDialog: false
     


      
    };
    this.handleDelete = this.handleDelete.bind(this);
  }
  deletetemplate=(evt,index,index_entity,index_process)=>{
    if(evt==="DISCARD"){
      
      this.deleteConstraint(index,index_entity,index_process)
    }
    this.setState(
      {
          ...this.state,
          showDialog: false,
          showDialogDiscard: false,
          showDialogSubmit: false,
          showDialogSave: false,
          showValueHelpDialog: false,
          showDataElementDialog: false,
          showVHTableDialog: false,
          showValueHelpDynamicDialog: false
      });

  }
  deletetemplateentity=(evt,index,index_entity)=>{
    if(evt==="DISCARD"){
      
      this.deleteentity(index,index_entity)
    }
    this.setState(
      {
          ...this.state,
          showDialog: false,
          showDialogDiscardentity: false,
          showDialogSubmit: false,
          showDialogSave: false,
          showValueHelpDialog: false,
          showDataElementDialog: false,
          showVHTableDialog: false,
          showValueHelpDynamicDialog: false
      });

  }
  // state = {
  //     tableVisible: true,

  // };
  applicationChangeHandler = (option) => {
  
    this.setState({
      ...this.state,
     
     
     
      hierarchy:[],
      
    }, () => this.hierarchyListGet(this.state.application,this.state.appDesc)) 
  };
  
  hierarchyListGet = (option) => {
    fetch('/workbox-mail-configuration/v1/application/hierarchy?application='+option.name+'&structureType=Hierarchy',{headers:{"Authorization":this.props.authorization}})
    .then(res =>res.json())
    .then(json=>
        {this.setState
            ({...this.state,
             isLoaded1:true,
            hierarchy:json.data,
            application: option.name,
            appDesc:option.description,
            tableVisible:false,
            hierarchy1:[{application:option.name,applicationDesc:option.description,entitities:[{
            
              entity: "",
              entityDesc: "",
              processes: [
                { process: "", process_description: "", variables: [] },
              ],
            }]}],
    },
    ()=>this.check(option.name,option.description)
    );
        })
        
        
        
    //     if(!this.state.hierarchy.length){
    //       console.log(this.state.hierarchy.length);
    //       var temp={application:appName,applicationDesc:appDesc,entitities:[{
            
    //         entity: "",
    //         entityDesc: "",
    //         processes: [
    //           { process: "", process_description: "", variables: [] },
    //         ],
    //       }]};
    //       this.setState
    //         ({...this.state,
    //         hierarchy:temp
    // });
    // console.log(this.state.hierarchy)
    
        }
     ;
     check =(appName,appDesc)=>{
      var temp1=[];
       if(!this.state.hierarchy.length){ 
    
          var temp={application:appName,applicationDesc:appDesc,entitities:[{
            
        entity: "",
        entityDesc: "",
        processes: [
          { process: "", process_description: "", variables: [] },
        ],
      }]};
      temp1.push(temp);
//       this.setState
//         ({...this.state,
//         hierarchy1:temp1
// });
    const payload=[];
     let i=0;var j=0; var k=0;
          temp1={
            application:temp.application,
            applicationDesc: temp.applicationDesc,
            entity: null,
            entityDesc:null,
            id: null,
            process:null,
            processDesc: null,
            variables:null,
            
             }
            payload.push(temp);
            
     
        
       

      
       

    
    
  let method,url;
	
  
    method="POST"
    url= '/workbox-mail-configuration/v1/application/hierarchy';
    

    const requestParam1 = {
      method: method,
      headers: { "Content-Type": "application/json",Authorization:this.props.authorization},
      body: JSON.stringify(payload),
  }
// fetch(url,requestParam1)
//   .then((response) => response.json())
//   .then(console.log("Done"))
//   .then(this.hierarchyListGet(appName,appDesc))

	
  };





       
     };

  inputChangeHandler = (evt,index,index_entity) => {
    let value = evt.target.value,
      isValid,
      errorMssg = "",
      isValidObj = {},
      lengthVal;

    //this.state.obj[evt.target.name] = evt.target.value;

    this.setState({
      ...this.state,
      validLength: !isValid,
      errorMssg: errorMssg,
     
    })
    
    if(evt.target.name==="entity"  )
    console.log(evt.target.name);
    var tempArr= this.state.hierarchy;
    tempArr[index].entities[index_entity].entity = evt.target.value;
    this.setState({
      ...this.state,
      hierarchy:tempArr
    });
    };

    inputChangeHandler1 = (evt,index,index_entity) => {
      let value = evt.target.value,
        isValid,
        errorMssg = "",
        isValidObj = {},
        lengthVal;
  
      //this.state.obj[evt.target.name] = evt.target.value;
  
      this.setState({
        ...this.state,
        validLength: !isValid,
        errorMssg: errorMssg,
       
      })
      var tempArr= this.state.hierarchy;
      tempArr[index].entities[index_entity].entityDesc = value;
      this.setState({
        ...this.state,
        hierarchy:tempArr
      });};
      inputChangeHandler_process = (evt,index,index_entity,index_process) => {
        let value = evt.target.value,
          isValid,
          errorMssg = "",
          isValidObj = {},
          lengthVal;
    
        //this.state.obj[evt.target.name] = evt.target.value;
    
        this.setState({
          ...this.state,
          validLength: !isValid,
          errorMssg: errorMssg,
         
        })
        var tempArr= this.state.hierarchy;
        tempArr[index].entities[index_entity].processes[index_process].process = value;
        this.setState({
          ...this.state,
          hierarchy:tempArr
        });};
        inputChangeHandler_processDesc = (evt,index,index_entity,index_process) => {
          let value = evt.target.value,
            isValid,
            errorMssg = "",
            isValidObj = {},
            lengthVal;
      
          //this.state.obj[evt.target.name] = evt.target.value;
      
          this.setState({
            ...this.state,
            validLength: !isValid,
            errorMssg: errorMssg,
           
          })
          var tempArr= this.state.hierarchy;
          tempArr[index].entities[index_entity].processes[index_process].processDesc = value;
          this.setState({
            ...this.state,
            hierarchy:tempArr
          });};
  onSaveDataElement = e => {
		
    let temp;
    const payload=[];
     for (var i = 0; i < this.state.hierarchy.length; i++) 
    {
      for (var j = 0; j < this.state.hierarchy[i].entities.length; j++) 
      {
        for (var k = 0; k < this.state.hierarchy[i].entities[j].processes.length; k++) 
        {
          temp={
            application:this.state.hierarchy[i].application,
            applicationDesc: this.state.hierarchy[i].applicationDesc,
            entity: this.state.hierarchy[i].entities[j].entity,
            entityDesc:this.state.hierarchy[i].entities[j].entityDesc,
            id: this.state.hierarchy[i].entities[j].processes[k].id,
            process: this.state.hierarchy[i].entities[j].processes[k].process,
            processDesc: this.state.hierarchy[i].entities[j].processes[k].processDesc,
            variables:this.state.hierarchy[i].entities[j].processes[k].variables,
            
             }
            payload.push(temp);
            console.log(payload);
     
        }
       

      }
       

    }
    
  let method,url;
	
  
    method="PATCH"
    url= '/workbox-mail-configuration/v1/application/hierarchy';
    

    const requestParam1 = {
      method: method,
      headers: { "Content-Type": "application/json",Authorization:this.props.authorization},
      body: JSON.stringify(payload),
  }
fetch(url,requestParam1)
  .then((response) => response.json())
  .then((response) => {
    this.setState({...this.state,messageOpen:true,messageContent:response.message});
  })
  .then(console.log("Done"))

	
  };
  closeDialog = (evt) => {
   
       
    
   
    if (evt === "SAVE" ) {
       //   this.onSaveMappingRolesDraft();
       this.onSaveDataElement();
   }


   this.setState(
       {
           ...this.state,
           showDialog: false,
           showDialogDiscard: false,
           showDialogSubmit: false,
           showDialogSave: false,
           showValueHelpDialog: false,
           showDataElementDialog: false,
           showVHTableDialog: false,
           showValueHelpDynamicDialog: false
       });

}
  addNewEntity = (event,index) => {
    let entity = [...this.state.hierarchy[index].entities];
    let obj = {
      entity: "",
      entityDesc: "",
      processes: [
        { process: "", process_description: "", variables: [] },
      ],
    };
    entity.push(obj);

    var tempArr= this.state.hierarchy;
    tempArr[index].entities = entity;
    this.setState({
      ...this.state,
      hierarchy:tempArr
    });
  };
  // handleChange = (value) => {
    
  //   this.setState({ value });
  // };
  handleKeyDown = (evt,index,index_entity,index_process) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
       var val;
       val = this.state.value.trim() ;
       
       
        this.setState({...this.state,value:[]}) 

    
 ;
      if (val ) {
        let tempvariable=this.state.hierarchy[index].entities[index_entity].processes[index_process].variables;
        let m={variable:val};
        tempvariable.push(m);
        var tempArr= this.state.hierarchy;
        tempArr[index].entities[index_entity].processes[index_process].variables = tempvariable;
        this.setState({
          ...this.state,
          hierarchy:tempArr,
        
        });
     
      }
      console.log(this.state.value)
    }
   
  };

  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      
    });
    

  };

  handleDelete = (item,index,index_entity,index_process) => {
    let tempv=this.state.hierarchy[index].entities[index_entity].processes[index_process].variables;
    tempv=tempv.filter(i=>i.variable !== item);
    var tempArr= this.state.hierarchy;
    tempArr[index].entities[index_entity].processes[index_process].variables = tempv;
    this.setState({
      ...this.state,
      hierarchy:tempArr
    });
    
  };
  
  deleteConstraint=(index,index_entity,index_process)=>{
    
    let url='/workbox-mail-configuration/v1/application/hierarchy?application='+ this.state.hierarchy[index].application +"&entity="+ this.state.hierarchy[index].entities[index_entity].entity +"&process="+ this.state.hierarchy[index].entities[index_entity].processes[index_process].process;
   
    fetch(url, { method: 'DELETE',headers:{"Authorization":this.props.authorization} })
    .then((response)=>response.json())
    .then((response) => {
      this.setState({...this.state,messageOpen:true,messageContent:response.message});
    })
        .then(  setTimeout(() => {
          this.hierarchyListGet(this.state.application)       
  },300 
  
));}
   deleteentity=(index,index_entity)=>{
     let url='/workbox-mail-configuration/v1/application/hierarchy?application='+ this.state.hierarchy[index].application +"&entity="+ this.state.hierarchy[index].entities[index_entity].entity ;
     fetch(url, { method: 'DELETE',headers:{"Authorization":this.props.authorization}})
     .then((response)=>response.json())
     .then((response) => {
       this.setState({...this.state,messageOpen:true,messageContent:response.message});
     })
         .then(  setTimeout(() => {
           this.hierarchyListGet(this.state.application)       
   },300 
   
 ));}
  
  onDelete=(index,index_entity,index_process)=> {
        
    this.setState({
        ...this.state,

        showDialogDiscard: true,
        temp_index:index,
        temp_index_entity:index_entity,
        temp_index_process:index_process,

    })
};
onDeleteentity=(index,index_entity)=> {
        
  this.setState({
      ...this.state,

      showDialogDiscardentity: true,
      temp_index:index,
      temp_index_entity:index_entity,
      
  })
};
  addNewConstraint = (event, index,index_entity) => {
  

    let processes = [...this.state.hierarchy[index].entities[index_entity].processes];
    let entities = [...this.state.hierarchy[index].entities];
    let tempEntity = entities;

    let obj = {
      process: "",
      process_description: "",
      variables: [],
    };

    processes.push(obj);
    let entityobj = {
      entity: this.state.hierarchy[index].entities[index_entity].entity,
      entityDesc: this.state.hierarchy[index].entities[index_entity].entityDesc,
      processes: processes,
    };
    tempEntity[index_entity] = entityobj;
    var tempArr= this.state.hierarchy;
    tempArr[index].entities = entities;
    this.setState({
      ...this.state,
      hierarchy:tempArr
    });
  };
  savethedata=()=>{
    this.setState({
      ...this.state,

      showDialogSave: true

  })
   }

  goToMsgComp = (event) => {
    this.props.history.push("/messageSummary");
  };

  goToThemeComp = (event) => {
    this.props.history.push("/themeStyling");
  };
  componentDidMount() {
    
      
      if(this.props.applicationName){
        fetch("workbox-mail-configuration/v1/application/hierarchy",{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ...this.state,
          isLoaded: true,
          applicationlist: json.data.filter(app=>app.application.includes(this.props.applicationName)),
        });
      });
        this.setState({
          ...this.state,
          applicationmenu:[{applicationId: "657ff7d9-945a-4548-b49f-6ebeeea3cd5c",
          createdBy: "P000241",
          createdOn: 1635154894957,
          description: "IPM",
          isDefaulted: null,
          label: "IPM",
          name: "IPM",
          status: null,
          updatedBy: "P000241",
          updatedOn: 1635154894958}]
        })
      }
      else{
        fetch("workbox-mail-configuration/v1/application/hierarchy",{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ...this.state,
          isLoaded: true,
          applicationlist: json.data,
        });
      });
        fetch("workbox-field-catalog/v1/applications",{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ...this.state,
          isLoaded: true,
          applicationmenu: json.data,
        });
      });
      }
  }

  render() {
   
    var {  value } = this.state;
    var {  hierarchy } = this.state;
    let temp=this.state.hierarchy.length;
    const userAccess = this.props.userAccess;
    console.log(userAccess);
    const CreateEmailtemp = userAccess.activities.filter(
        ele => ele === "Create Email Template"
        ) ? true : false;
    // const CreateEmailtemp = true;

    return (
      <div>
        <Grid item xs={8} sm={3} md={4} style={{float:"right",marginTop:"2.5rem"}}>
           <FormControl style={{width: 180,margin:"-3rem 2rem 0rem 0rem" }} variant="outlined" size="small">
             <InputLabel id="demo-simple-select-outlined-label" required>
               Application
             </InputLabel>
             <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               //value={row.applicationId}
               // size="small"
               label="Application"
               name="dataElement"
              
             
             >
               {/* {this.state.applicationList.map(option =>
                                   <MenuItem value={option.id} key={option.id}
                                   // onClick={() =>
                                   //     this.applicationChangeHandler(option.id, index)

                                   // }
                                   // style={{ width: 30 }}
                                   >{option.name}</MenuItem>
                               )}  */}

               {this.state.applicationmenu.map((option,index) => (
                 
                 <MenuItem
                   value={option.name}
                   key={option.id}
                   onClick={() =>  this.hierarchyListGet(option)}
                  
                 >
                   {option.description}
                 </MenuItem>
               ))}
             </Select>
           </FormControl>
         </Grid>
        {this.state.tableVisible && (<div>
         {/* <Grid container  hSpa  cing="2rem" vSpacing="2rem" style={{ width:"10%" }}> */}
         <div style={{marginTop:'-2.5rem'}}>
         <TableContainer
              component={Paper}
              style={{ margin: "0.5rem", width: "99%" }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                      Application
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                      Entity
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                    Process
                    </TableCell>
                             
                  </TableRow>
                </TableHead>
                <TableBody>
                 {this.state.applicationlist.map((option) => (
                  // 
                     
                    
                    <TableRow key={option.id}>  

                      { option.applicationDesc &&  option.entityDesc &&  option.processDesc&& <TableCell>{option.applicationDesc}</TableCell>}
                      { option.applicationDesc &&  option.entityDesc &&  option.processDesc&&  <TableCell>{option.entityDesc}</TableCell>}
                      { option.applicationDesc &&  option.entityDesc &&  option.processDesc&&  <TableCell>{option.processDesc}</TableCell>}
                      

                    

                     
                     
                    </TableRow>//)}
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>
         
        </div>)}
      {!this.state.tableVisible && (
        <div style={{marginTop:'-2.5rem'}}>
       
          
       
       
        {(this.state.hierarchy.length > 0) &&(
          <div>
           {this.state.hierarchy.map(
          (option, index) => (
            
             
              <div className='hierarchy'>
                
              
                 {this.state.hierarchy[index].entities.map(
          (option_entity, index_entity) => (
            
            (
             

              <TableContainer
                component={Paper}
                style={{ margin: "0.5rem", width: "99%" }}
              >
               <Grid container style={{ padding: "1rem" }}
                >
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      required
                      id="filled-disabled"
                      label="ENTITY NAME"
                      name="entity"
                      value={hierarchy[index].entities[index_entity].entity}
                      variant="outlined"
                      //style={{ marginLeft: 30 }}
                      size="small"
                      onChange={(event) => this.inputChangeHandler(event,index,index_entity)}
                      // className="customInput2"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      required
                      id="filled-disabled"
                      label="ENTITY Description"
                      name="Entity Description"
                      value={hierarchy[index].entities[index_entity].entityDesc}
                      variant="outlined"
                      //style={{ marginLeft: 30 }}
                      size="small"
                      onChange={(event) => this.inputChangeHandler1(event,index,index_entity)}
                    //  onChange={(evt) => this.props.inputChangeHandler1(evt)}
                      // className="customInput2"
                    />
                  </Grid> 
                  
                  <span
          className="iconBtn"
          //style={{ float: "right", marginBottom: "0.5rem" }}
         >
          <IconButton
            size="small"
           
            color="primary"
            style={{ marginLeft: "30rem"}}
            disabled={!CreateEmailtemp}
            onClick={(evt) => this.addNewEntity(evt,index)}
            //className={classes.buttonAdd}
          >
           <AddIcon/>
          </IconButton>
         
          <IconButton
            size="small"
           
            color="primary"
           // style={{ marginLeft: "30%",width:"6.5rem", marginTop:"5%" }}
           style={{margin:"0 0.5rem"}}
            onClick={()=>this.onDeleteentity(index,index_entity)}
            //className={classes.buttonAdd}
          >
            <DeleteRoundedIcon />
          </IconButton>
         </span>
             
                </Grid>

               
                <Table
                  size="small"
                  aria-label="a dense table"
                  name="fieldsTable"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%" style={{ fontWeight: 700 }}>
                        
                        PROCESS
                      </TableCell>
                      <TableCell width="10%" style={{ fontWeight: 700 }}>
                       
                        PROCESS DESCRIPTION
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        VARIABLES
                      </TableCell>

                      <TableCell width="10%" style={{ fontWeight: 700 }}>
                         
                      </TableCell>
                    </TableRow>
                  </TableHead>

                 <TableBody>
           {this.state.hierarchy[index].entities[index_entity].processes.map(
          (option_process, index_process) => (
            
            (
              
                      <TableRow>
                         
                        <TableCell>
                        {(hierarchy[index].entities[index_entity].processes[index_process].process||!(hierarchy[index].entities[index_entity].processes[index_process].id))&&
                          <TextField
                            required
                            id="fieldTable"
                            name="columnName"
                           value={hierarchy[index].entities[index_entity].processes[index_process].process}
                            variant="outlined"
                            //style={{ marginLeft: 30 }}
                            size="small"
                            onChange={(evt) => this.inputChangeHandler_process(evt,index,index_entity,index_process)}
                            // className="customInput2"
                          />}
                        </TableCell>
                        <TableCell> 
                        {(hierarchy[index].entities[index_entity].processes[index_process].process||!(hierarchy[index].entities[index_entity].processes[index_process].id))&&
                          <TextField
                            required
                            id="fieldTable"
                            name="displayName"
                           value={hierarchy[index].entities[index_entity].processes[index_process].processDesc}
                            variant="outlined"
                            //style={{ marginLeft: 30 }}
                            size="small"
                            onChange={(evt) => this.inputChangeHandler_processDesc(evt,index,index_entity,index_process)}
                            // onChange={(evt) => this.props.valueHelpTableInputs(evt,index)}
                            // className="customInput2"
                          />
                        }
                        </TableCell>
                        {(hierarchy[index].entities[index_entity].processes[index_process].process||!(hierarchy[index].entities[index_entity].processes[index_process].id))&&
                     
                        <TableCell> 
                        
            {this.state.hierarchy[index].entities[index_entity].processes[index_process].variables.map(
          (option_variables, index_variables) => (
            
            ( <div className="tag-item">
                {this.state.hierarchy[index].entities[index_entity].processes[index_process].variables[index_variables].variable}
                
            <button
              type="button"
               onClick={() => this.handleDelete(this.state.hierarchy[index].entities[index_entity].processes[index_process].variables[index_variables].variable,index,index_entity,index_process)}
            >
              &times;
            </button> 
            </div>
          
             )  ))}
            
         <input
          className={"input "}
          value={value[100]}
          placeholder=""
          onKeyDown={(evt)=>this.handleKeyDown(evt,index,index_entity,index_process)}
          onChange={this.handleChange}
          // onPaste={this.handlePaste}
         />
          </TableCell>}
                        <TableCell>
                        {(hierarchy[index].entities[index_entity].processes[index_process].process||!(hierarchy[index].entities[index_entity].processes[index_process].id))&&
                        <span
          className="iconBtn"
          //style={{ float: "right", marginBottom: "0.5rem" }}
         >
           <IconButton
                    size="small"
                    disabled={!CreateEmailtemp}
                    color="primary"
                    style={{ marginRight: "1rem"}}
                    onClick={(evt) => this.addNewConstraint(evt, index,index_entity)}
                    //className={classes.buttonAdd}
                  >
                  <AddIcon />
                  </IconButton>
          <IconButton
            size="small"
            disabled={!CreateEmailtemp}
            color="primary"
             style={{ width:"0.5rem" }}
            onClick={()=>this.onDelete(index,index_entity,index_process)}
            //className={classes.buttonAdd}
          >
            <DeleteRoundedIcon />
          </IconButton>
         
         
          </span>}
        <MessageBoxComponentDelete
          title="delete"
          open={this.state.showDialogDiscard}
          actions={["DISCARD", "CANCEL"]}
          onClose={(evt)=>this.deletetemplate(evt,this.state.temp_index,this.state.temp_index_entity,this.state.temp_index_process)}
        /> 
        <MessageBoxComponentDelete
          title="delete"
          open={this.state.showDialogDiscardentity}
          actions={["DISCARD", "CANCEL"]}
          onClose={(evt)=>this.deletetemplateentity(evt,this.state.temp_index,this.state.temp_index_entity)}
        /> 
                        </TableCell>
                      </TableRow>
                    )))} 
                  </TableBody>
                </Table>
              </TableContainer>
               )))} 
              
          <div className="footerdiv" style={{position:"fixed", right:"10px" ,bottom:"10px"}}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>this.savethedata()}
            >
              Save
             </Button>
             <MessageBoxComponentSave
          title="save"
          open={this.state.showDialogSave}
          actions={["SAVE", "CANCEL"]}
          onClose={(evt)=>this.closeDialog(evt)}
        />
            {/*<Button
              style={{ margin: "0 0.5rem" }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.onCancel}
            >
              Cancel
            </Button> */}
          </div>
         <Snackbar open={this.state.messageOpen} message={this.state.messageContent} autoHideDuration={3000} onClose={() => {this.setState({...this.state,messageOpen:false})}}> 
         <Alert severity="success"
          sx={{ width: '100%' }} 
          style={{backgroundColor:"lightgreen"}} 
          >
      {this.state.messageContent}
      </Alert>
      {/* <Alert severity="error">This is an error message!</Alert> */}
          </Snackbar>
              </div>
            
          )
        )}
        </div>
          )}
          <div>
          {(this.state.hierarchy.length<1)&& 
          (<div>
             {this.state.hierarchy1.map(
          (option, index) => (
            
          
             
             <div className="new hierarchy"> 
              
              {this.state.hierarchy1[index].entities.map(
          (option_entity, index_entity) => (
            
            (

              <TableContainer
                component={Paper}
                style={{ margin: "0.5rem", width: "99%" }}
              >
               <Grid container style={{ padding: "1rem" }}
                >
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      required
                      id="filled-disabled"
                      label="ENTITY NAME"
                      name="entity"
                      value={this.state.hierarchy1[index].entities[index_entity].entity}
                      variant="outlined"
                      //style={{ marginLeft: 30 }}
                      size="small"
                      onChange={(event) => this.inputChangeHandler(event,index,index_entity)}
                      // className="customInput2"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      required
                      id="filled-disabled"
                      label="ENTITY Description"
                      name="Entity Description"
                      value={this.state.hierarchy1[index].entities[index_entity].entityDesc}
                      variant="outlined"
                      //style={{ marginLeft: 30 }}
                      size="small"
                      onChange={(event) => this.inputChangeHandler1(event,index,index_entity)}
                    //  onChange={(evt) => this.props.inputChangeHandler1(evt)}
                      // className="customInput2"
                    />
                  </Grid> 
                  
                  <span
          className="iconBtn"
          //style={{ float: "right", marginBottom: "0.5rem" }}
         >
          <IconButton
            size="small"
           
            color="primary"
            style={{ marginLeft: "30rem"}}
            disabled={!CreateEmailtemp}
            onClick={(evt) => this.addNewEntity(evt,index)}
            //className={classes.buttonAdd}
          >
           <AddIcon/>
          </IconButton>
         
          <IconButton
            size="small"
           
            color="primary"
           // style={{ marginLeft: "30%",width:"6.5rem", marginTop:"5%" }}
           style={{margin:"0 0.5rem"}}
            onClick={()=>this.onDeleteentity(index,index_entity)}
            //className={classes.buttonAdd}
          >
            <DeleteRoundedIcon />
          </IconButton>
         </span>
             
                </Grid>

               
                <Table
                  size="small"
                  aria-label="a dense table"
                  name="fieldsTable"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%" style={{ fontWeight: 700 }}>
                        
                        PROCESS
                      </TableCell>
                      <TableCell width="10%" style={{ fontWeight: 700 }}>
                       
                        PROCESS DESCRIPTION
                      </TableCell>
                      <TableCell width="15%" style={{ fontWeight: 700 }}>
                        VARIABLES
                      </TableCell>

                      <TableCell width="10%" style={{ fontWeight: 700 }}>
                         
                      </TableCell>
                    </TableRow>
                  </TableHead>

                 <TableBody>
           {this.state.hierarchy1[index].entities[index_entity].processes.map(
          (option_process, index_process) => (
            
            (
              
                      <TableRow>
                         
                        <TableCell>
                        {(this.state.hierarchy1[index].entities[index_entity].processes[index_process].process||!(this.state.hierarchy1[index].entities[index_entity].processes[index_process].id))&&
                          <TextField
                            required
                            id="fieldTable"
                            name="columnName"
                           value={this.state.hierarchy1[index].entities[index_entity].processes[index_process].process}
                            variant="outlined"
                            //style={{ marginLeft: 30 }}
                            size="small"
                            onChange={(evt) => this.inputChangeHandler_process(evt,index,index_entity,index_process)}
                            // className="customInput2"
                          />}
                        </TableCell>
                        <TableCell> 
                        {(this.state.hierarchy1[index].entities[index_entity].processes[index_process].process||!(this.state.hierarchy1[index].entities[index_entity].processes[index_process].id))&&
                          <TextField
                            required
                            id="fieldTable"
                            name="displayName"
                           value={this.state.hierarchy1[index].entities[index_entity].processes[index_process].processDesc}
                            variant="outlined"
                            //style={{ marginLeft: 30 }}
                            size="small"
                            onChange={(evt) => this.inputChangeHandler_processDesc(evt,index,index_entity,index_process)}
                            // onChange={(evt) => this.props.valueHelpTableInputs(evt,index)}
                            // className="customInput2"
                          />
                        }
                        </TableCell>
                        {(this.state.hierarchy1[index].entities[index_entity].processes[index_process].process||!(this.state.hierarchy1[index].entities[index_entity].processes[index_process].id))&&
                     
                        <TableCell> 
                        
            {this.state.hierarchy1[index].entities[index_entity].processes[index_process].variables.map(
          (option_variables, index_variables) => (
            
            ( <div className="tag-item">
                {this.state.hierarchy1[index].entities[index_entity].processes[index_process].variables[index_variables].variable}
                
            <button
              type="button"
               onClick={() => this.handleDelete(this.state.hierarchy1[index].entities[index_entity].processes[index_process].variables[index_variables].variable,index,index_entity,index_process)}
            >
              &times;
            </button> 
            </div>
          
             )  ))}
            
         <input
          className={"input "}
          value={value[100]}
          placeholder=""
          onKeyDown={(evt)=>this.handleKeyDown(evt,index,index_entity,index_process)}
          onChange={this.handleChange}
          // onPaste={this.handlePaste}
         />
          </TableCell>}
                        <TableCell>
                        {(this.state.hierarchy1[index].entities[index_entity].processes[index_process].process||!(this.state.hierarchy1[index].entities[index_entity].processes[index_process].id))&&
                        <span
          className="iconBtn"
          //style={{ float: "right", marginBottom: "0.5rem" }}
         >
           <IconButton
                    size="small"
                    disabled={!CreateEmailtemp}
                    color="primary"
                    style={{ marginRight: "1rem"}}
                    onClick={(evt) => this.addNewConstraint(evt, index,index_entity)}
                    //className={classes.buttonAdd}
                  >
                  <AddIcon />
                  </IconButton>
          <IconButton
            size="small"
            disabled={!CreateEmailtemp}
            color="primary"
             style={{ width:"0.5rem" }}
            onClick={()=>this.onDelete(index,index_entity,index_process)}
            //className={classes.buttonAdd}
          >
            <DeleteRoundedIcon />
          </IconButton>
         
         
          </span>}
        <MessageBoxComponentDelete
          title="delete"
          open={this.state.showDialogDiscard}
          actions={["DISCARD", "CANCEL"]}
          onClose={(evt)=>this.deletetemplate(evt,this.state.temp_index,this.state.temp_index_entity,this.state.temp_index_process)}
        /> 
        <MessageBoxComponentDelete
          title="delete"
          open={this.state.showDialogDiscardentity}
          actions={["DISCARD", "CANCEL"]}
          onClose={(evt)=>this.deletetemplateentity(evt,this.state.temp_index,this.state.temp_index_entity)}
        /> 
                        </TableCell>
                      </TableRow>
                    )))} 
                  </TableBody>
                </Table>
              </TableContainer>
               )))} 
              
          <div className="footerdiv" style={{position:"fixed", right:"10px" ,bottom:"10px"}}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={!CreateEmailtemp}
              onClick={()=>this.savethedata()}
            >
              Save
             </Button>
             <MessageBoxComponentSave
          title="save"
          open={this.state.showDialogSave}
          actions={["SAVE", "CANCEL"]}
          onClose={(evt)=>this.closeDialog(evt)}
        />
            {/*<Button
              style={{ margin: "0 0.5rem" }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.onCancel}
            >
              Cancel
            </Button> */}
          </div>
         <Snackbar open={this.state.messageOpen} message={this.state.messageContent} autoHideDuration={3000} onClose={() => {this.setState({...this.state,messageOpen:false})}}> 
         <Alert severity="success"
          sx={{ width: '100%' }} 
          style={{backgroundColor:"lightgreen"}} 
          >
      {this.state.messageContent}
      </Alert>
      {/* <Alert severity="error">This is an error message!</Alert> */}
          </Snackbar>
           </div>   
            
          )
        )}
        
            </div>)}
            </div>
         
            </div>
      )}
      </div>
      
    );
  }
}

export default withRouter(CwMSEntity);
