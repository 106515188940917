import React from 'react';
import { withRouter } from 'react-router-dom';

import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
//import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@mui/material/Autocomplete';

import InputBase from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import ValueHelpDialog from './valueHelpDialog';
import Composite from './CompositeValueHelpDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

class ValueHelp extends React.Component {

    constructor(props) {
        super(props);
        this.valuehelp = React.createRef();
        let emptyRow = this.emptyRow();
        this.state = {
            // value: "DataElement",
            // tableVisible: true,
            busyIndicator: true,
            compositeRow: {},
            dataElement: [],
            valueList: [],
            showValueHelpDialog: false,
            selectedRow: props.selectedRow,
            constraints: [],
            fields: [],
            apiType: [{
                name: "REST"
            }, {
                name: "ODATA"
            }
            ],
            constraintType: [{
                name: "PATH VARIABLE"
            }, {
                name: "QUERY PARAMETER"
            }, {
                name: "HEADER PARAMETER"
            }
            ],
            fileTypeDropDown: [{
                name: ".XLSX"
            }, {
                name: ".CSV"
            }, {
                name: ".XML"
            }
            ],
            valueHelpType: [{
                name: "Static",
                key: "VL"
            }, {
                name: "API",
                key: "API"
            }, {
                name: "Database",
                key: "DB"
            }, {
                name: "File",
                key: "FILE"
            }
            ],
            destinations: [],
            dataTable:[],
            apiMetaData: {
                "url": "",
                "destination": "",
                "apiType": "",
                "resultPath": null,
                "constraints": null,

            },

        };
    }
    // state = {
    //     tableVisible: true,

    // };
    emptyRow = () => {

        const selectedRow = {
            attributeId: "",
            createdBy: "",
            createdOn: null,
            dataType: "",
            description: "",
            destinations: null,
            isLookup: true,
            label: "",
            length: "",
            lookUpId: "",
            lookUpType: "",
            lookupConfig: null,
            name: "",
            source: "",
            updatedBy: "",
            updatedOn: null,
            propertyDto: {
                isMandatory: true,
                isVisible: true,
                isNullable: true,
                defaultValue: "abc",
                isFilterable: true,
                isSortable: true,
                isEditable: true
            }
        };
        return selectedRow;
    };
    createNewField = (event, newValue) => {
        this.setState({
            ...this.state,
            tableVisible: false,
        });
    };
    handleSubmit = (evt) => {
        let a = evt;
    }
    editFields = (row) => {
        this.setState({
            ...this.state,
            tableVisible: false,
            selectedRow: row
        });
    };


    onValueHelpType = (event) => {
        let value = event.target.value;
        this.props.lookupType = value;
    };

    testFunc = () => {

    }

    fnComposite = (row, index) => {
        
        let type = row.lookupType

        if (this.props.parentState.lookupType === "COMPOSITE") {
            let url = "workbox-field-catalog/v1/lapi?lookupId=" + row.referenceId;
            fetch(url, {headers:{"Authorization":this.props.authorization}})
                .then((res) => res.json())
                .then((result) => {
                    // destination = result.data ? result.data : [];
                    var valueList = [];
                    if (row.lookupType === "API" || row.lookupType === "DB") {
                        // this.getAttributeList(this.props.selectedRow.metaData.applicationId);
                        let url = "workbox-field-catalog/v1/lapi/destination"
                        fetch(url, {headers:{"Authorization":this.props.authorization}})
                            .then((res) => res.json())
                            .then((result) => {
                                // destination = result.data ? result.data : [];
                                this.setState({
                                    ...this.state, 
                                    destinations: result.data ? result.data : [],
                                    //  fields: [],
                                    //  constraints:[]
                                });

                            })

                    }
                     if(row.lookupType === "DB"){
                        let url = "workbox-field-catalog/v1/lapi/databaseValuehelp"
                        fetch(url, {headers:{"Authorization":this.props.authorization}})
                            .then((res) => res.json())
                            .then((lookupTable) => {
                                // destination = result.data ? result.data : [];
                                this.setState({
                                    ...this.state, 
                                    dataTable: lookupTable.data ? lookupTable.data : [],
                                    //  fields: [],
                                    //  constraints:[]
                                });

                            })
                    }
                    if (result.data) {
                        if (result.data.valueList) {
                            this.setState({
                                ...this.state,
                                valueList: result.data.valueList,
                                busyIndicator: false
                                // fields: fields
                            });
                        }
                        else if (result.data.fileMetadata) {
                            this.setState({
                                ...this.state,
                                sheets: result.data.fileMetadata.properties.sheets,
                                busyIndicator: false

                            }, () => {
                                var sheets = this.state.sheets;
                                sheets.filter(function (element, index) {
                                    var num = element.columnStart + 1;
                                    let s = '', t;

                                    while (num > 0) {
                                        t = (num - 1) % 26;
                                        s = String.fromCharCode(65 + t) + s;
                                        num = (num - t) / 26 | 0;
                                    }
                                    element.columnStart = s || undefined;
                                });
                            })

                            this.setState({
                                ...this.state,

                                fileMetadata: result.data.fileMetadata,
                                busyIndicator: false,
                                fields: result.data.fileMetadata.fields ? result.data.fileMetadata.fields : [],
                                constraints: result.data.fileMetadata.constraints ? result.data.fileMetadata.constraints : []
                            });
                            console.log(this.state.fileMetadata, "filemetadata");
                        }
                        else if (result.data.apiMetadata) {

                            var apiMetadata = {
                                "url": result.data.apiMetadata.url,
                                "destination": result.data.apiMetadata.destination,
                                "apiType": result.data.apiMetadata.apiType,
                                "resultPath": result.data.apiMetadata.resultPath,
                                //"constraints": null,

                            };
                            this.setState({
                                ...this.state,
                                busyIndicator: false,
                                apiMetaData: apiMetadata,
                                tempdata: apiMetadata,
                                constraints: result.data.apiMetadata.constraints ? result.data.apiMetadata.constraints : [],
                                fields: result.data.apiMetadata.fields ? result.data.apiMetadata.fields : []
                            });
                        }else if (result.data.dbMetadata) {

                           
                            this.setState({
                                ...this.state,
                                busyIndicator: false,
                                dbMetaData: result.data.dbMetadata,
                                //tempdata: apiMetadata,
                                constraints: result.data.dbMetadata.constraints ? result.data.dbMetadata.constraints : [],
                                fields: result.data.dbMetadata.fields ? result.data.dbMetadata.fields : []
                            });
                        }

                    }else{
                        this.setState({
                            ...this.state, 
                            //destinations: result.data ? result.data : [],
                              fields: [],
                              constraints:[]
                        });
                    }

                   
                })
        }
        //setTimeout(() => {

        this.setState({
            ...this.state,
            showVHTableDialog: true,
            index: index,
            lookupTypeC: type,
            compositeRow: row
            // selectedRow: row
        });

        //}, 3000)

        // else if(this.props.parentState.lookupType==="COMPOSITE"){
        // 
        //    
        // }
        // this.getAttributeList(this.props.parentState.selectedApplication);
    }
    getAttributeList = (appId) => {
        let url;

        url = "workbox-field-catalog/v1/attribute-master?applicationId=" + appId
        fetch(url, {headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state,
                    selectedApplication: appId,
                    fieldCatalogArray: result.data ? result.data : []
                });

            })
    }
    // discardFieldCatalog(){
    //     
    //     this.setState({
    //         ...this.state,
    //         //existing: false,
    //         tableVisible: true
    //         //selectedRow: emptyRow
    //     }, ()=>console.log(this.state.tableVisible,"discard"));
    // }
    closeDialog = (action) => {
        
        if (action === "DISCARD") {
            //    this.discardFieldCatalog();
        }
        else if (action === "SUBMIT") {
            //   this.onSaveMappingRoles();
        }
        else if (action === "SAVE") {
            //   this.onSaveMappingRolesDraft();
            this.onSaveFieldCatalog();
        }

        this.setState(
            {
                ...this.state,
                showVHTableDialog: false,
            });

    }
    onLookupType = (evnt, keyProps) => {
        this.setState({
            ...this.state,
            lookupType: keyProps.props.value,
        });

    }
    getData = () => {
        this.props.getData();
    }
    render() {

        // const userAccess = this.props.userAccess;
        // const modellingEntity = userAccess.entities.find(ele => ele.name === "Modelling") ? true : false;
        // const userFormsEntity = userAccess.entities.find(ele => ele.name === "UserForms") ? true : false;
        // const configurationEntity = userAccess.entities.find(ele => ele.name === "Configuration") ? true : false;
        // const rulesEntity = userAccess.entities.find(ele => ele.name === "Rules") ? true : false;

        return (
            <div >
                <FormControl component="fieldset">

                    <RadioGroup row name="row-radio-buttons-group"
                        value={this.props.parentState.lookupType}
                        style={{ marginLeft: "1rem" }}
                        onChange={(e, newvalue) => this.props.handleChangeValueHelpType(e, newvalue)}
                    >
                        <FormControlLabel value="VL"
                            control={<Radio color="primary" />} label="Static Values Help" />
                        <FormControlLabel value="API" control={<Radio color="primary" />} label="Api Based" />
                        <FormControlLabel value="DB" control={<Radio color="primary" />} label="Database Based" />
                        <FormControlLabel value="FILE" control={<Radio color="primary" />} label="File Type" />
                        <FormControlLabel value="COMPOSITE" control={<Radio color="primary" />} label="Composite" />

                    </RadioGroup>
                </FormControl>

                {(this.props.parentState.lookupType === "API" || this.props.parentState.lookupType === "DB") && <div>

                    <Grid container spacing={3} hSpacing="2rem"
                        style={{ margin: "1rem" }}
                    >

                        <Grid item xs={12} sm={3} md={3} hidden={this.props.parentState.lookupType !== "API"}>
                            {/* <TextField
                                required
                                id="filled-disabled"
                                label={this.props.translation.DESTINATION.longDescription}
                                name="destination"
                                value={this.props.parentState.apiMetadata.destination}
                                variant="outlined"
                               
                                size="small"
                                onChange={(evt) => this.props.inputChangeHandler1(evt)}
                            // className="customInput2" 
                            /> */}
                            {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={this.props.parentState.apiMetadata.destination}
                                name="destination"
                                size="small"
                                options={this.props.parentState.destinations}
                                // onChange={(evt) => this.props.inputChangeHandler1(evt)}

                                renderInput={(params) => <TextField  required label={this.props.translation.DESTINATION.longDescription}
                                variant="outlined"
                                size="small"
                                    name="destination" />}
                            /> */}
                            <Autocomplete
                                style={{ width: 220 }}
                                name="destination"
                                id="destination"
                                // value={this.props.parentState.apiMetadata.destination}
                                inputValue={this.props.parentState.apiMetadata.destination}
                                options={this.props.parentState.destinations}
                                getOptionLabel={(option) => option.name}
                                // renderOption={(option) => option.name}
                                onChange={(evt, val) => this.props.inputChangeHandler1(evt, val)}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                    value={this.props.parentState.apiMetadata.destination}
                                    label={this.props.translation.DESTINATION.longDescription}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} hidden={this.props.parentState.lookupType !== "DB"}>
                            {/* <TextField
                                required
                                id="filled-disabled"
                                label={this.props.translation.DESTINATION.longDescription}
                                name="destination"
                                value={this.props.parentState.apiMetadata.destination}
                                variant="outlined"
                               
                                size="small"
                                onChange={(evt) => this.props.inputChangeHandler1(evt)}
                            // className="customInput2" 
                            /> */}
                            {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={this.props.parentState.apiMetadata.destination}
                                name="destination"
                                size="small"
                                options={this.props.parentState.destinations}
                                // onChange={(evt) => this.props.inputChangeHandler1(evt)}

                                renderInput={(params) => <TextField  required label={this.props.translation.DESTINATION.longDescription}
                                variant="outlined"
                                size="small"
                                    name="destination" />}
                            /> */}
                            <Autocomplete
                                style={{ width: 220 }}
                                name="destination"
                                id="destination"
                                // value={this.props.parentState.apiMetadata.destination}
                                inputValue={this.props.parentState.dbMetadata.destination}
                                options={this.props.parentState.destinations}
                                getOptionLabel={(option) => option.name}
                                // renderOption={(option) => option.name}
                                onChange={(evt, val) => this.props.inputChangeHandler1(evt, val)}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                    value={this.props.parentState.dbMetadata.destination}
                                    label={this.props.translation.DESTINATION.longDescription}
                                />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} hidden={this.props.parentState.lookupType !== "DB"}>
                            
                        <Autocomplete
                                style={{ width: 220 }}
                                name="lookupTable"
                                id="lookupTable"
                                required
                                // value={this.props.parentState.apiMetadata.destination}
                                inputValue={this.props.parentState.dbMetadata.lookupTable}
                                options={this.props.parentState.dataTable}
                                getOptionLabel={(option) => option.lookupTableName}
                                // renderOption={(option) => option.name}
                                onChange={(evt, val) => this.props.inputChangeHandler2(evt, val)}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                    value={this.props.parentState.dbMetadata.lookupTable}
                                    label={this.props.translation.DATA_TABLE.longDescription}
                                />}
                            />
                            {/* <TextField
                                required
                                // hidden={this.props.parentState.lookupType !== "DB"}
                                id="filled-disabled"
                                label={this.props.translation.DATA_TABLE.longDescription}
                                name="lookupTable"
                                value={this.props.parentState.dbMetadata.lookupTable}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.props.inputChangeHandler1(evt)}
                            // className="customInput2" 
                            /> */}
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} hidden={this.props.parentState.lookupType === "DB"}>
                            <TextField
                                required
                                id="filled-disabled"
                                label={this.props.translation.VALUE_HELP_URL.longDescription}
                                name="url"
                                value={this.props.parentState.apiMetadata.url}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.props.inputChangeHandler1(evt)}
                            // className="customInput2" 
                            />
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} hidden={this.props.parentState.lookupType === "DB"}>

                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-outlined-label" required>{this.props.translation.API_TYPE.longDescription}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label={this.props.translation.API_TYPE.longDescription}
                                    value={this.props.parentState.apiMetadata.apiType}
                                    // size="small"
                                    name="apiType"
                                    style={{ width: "100%" }}
                                    onChange={(evt) => this.props.inputChangeHandler1(evt)}
                                // hidden={true}
                                >
                                    {this.state.apiType.map(option =>
                                        <MenuItem value={option.name} key={option.name}
                                        // onClick={() =>
                                        //     this.applicationChangeHandler(option.id, index)

                                        // }
                                        // style={{ width: 30 }}
                                        >{option.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} hidden={this.props.parentState.lookupType === "DB"}>
                            <TextField

                                id="filled-disabled"
                                label={this.props.translation.RESPONSE_PATH.longDescription}
                                name="resultPath"
                                value={this.props.parentState.apiMetadata.resultPath}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.props.inputChangeHandler1(evt)}
                            // className="customInput2" 
                            />
                        </Grid>
                    </Grid>
                    <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.props.addNewConstraint()}
                            disabled={this.props.constraints.length !== 0 ? true : false}
                        //className={classes.buttonAdd}
                        >
                            Add
                        </Button>
                    </span>
                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table" name="constraintTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT_TYPE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.OPERATOR.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.VALUE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.constraints.length !== 0 && this.props.constraints.map((row, index) => {
                                    return (


                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}


                                            <TableCell >

                                                <FormControl
                                                    // style={{ width: 220 }}
                                                    variant="outlined" size="small">

                                                    <InputBase
                                                        name="constraintColumn"
                                                        id="constraintTable"
                                                        value={row.constraintColumn}
                                                        variant="outlined"
                                                        // disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={(evt) => this.props.fnAttrValueHelp(index, evt, "constraintTable")}
                                                                    // onMouseDown={handleMouseDownPassword}
                                                                    edge="end" >
                                                                    <SvgIcon >
                                                                        <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                    </SvgIcon >
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }>
                                                    </InputBase>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="constraintTable"
                                                disabled
                                                name="constraintName"
                                                value={row.constraintName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="constraintTable"

                                                name="mappedName"
                                                value={row.mappedName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"

                                                name="constraintType"
                                                value={row.constraintType}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"
                                                disabled
                                                name="constraintOperator"
                                                value={row.constraintOperator}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"

                                                name="constraintValue"
                                                value={row.constraintValue}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton aria-label="Edit" style={{ color: "red" }}
                                                    onClick={() => this.props.deleteConstraintRows(row.id, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.props.addNewFields()}
                        //className={classes.buttonAdd}
                        >
                            Add
                        </Button>
                    </span>
                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table" name="fieldsTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.FIELDS.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.SEARCHABLE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.UI_DISPLAY_NAME.longDescription}</TableCell>

                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.fields.map((row, index) => {
                                    return (


                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}


                                            <TableCell >
                                                <FormControl
                                                    // style={{ width: 220 }}
                                                    variant="outlined" size="small">

                                                    <InputBase

                                                        name="columnName"
                                                        id="fieldsTable"
                                                        value={row.columnName}
                                                        variant="outlined"
                                                        // disabled
                                                        disabled={!index}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                 disabled={!index}
                                                                    onClick={(evt) => this.props.fnAttrValueHelp(index, evt, "fieldsTable")}
                                                                    // onMouseDown={handleMouseDownPassword}
                                                                    edge="end" >
                                                                    <SvgIcon >
                                                                        <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                    </SvgIcon >
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }>
                                                    </InputBase>
                                                </FormControl>
                                            </TableCell >
                                            <TableCell ><TextField
                                                required
                                                disabled={!index}
                                                id="fieldsTable"

                                                name="displayName"
                                                value={row.displayName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            <TableCell><TextField
                                                required
                                                id="fieldsTable"

                                                name="mappedName"
                                                value={row.mappedName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} >
                                                {/* <InputLabel id="demo-simple-select-outlined-label" ></InputLabel> */}
                                                <FormGroup aria-label="position" row >

                                                    <FormControlLabel
                                                        required

                                                        // id="fieldsTable"
                                                        size="small"
                                                        value={row.searchable}
                                                        //checked={this.state.obj.active}
                                                        name="searchable"
                                                        onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                                        //value={row.active}
                                                        labelPlacement="start"
                                                        control={<Switch disabled={!index} color="primary"
                                                            checked={row.searchable}
                                                            id="fieldsTable" />}


                                                    />


                                                </FormGroup>
                                            </FormControl ></TableCell>
                                            <TableCell>
                                                <RadioGroup row

                                                    style={{ marginLeft: "1rem" }}

                                                    name="isDisplayName"
                                                >
                                                    <Radio id="fieldsTable"
                                                        checked={row.isDisplayName}

                                                        onChange={(evt) => this.props.valueHelpTableInputs(evt, index)} />
                                                </RadioGroup>

                                            </TableCell>
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton hidden={!index} aria-label="Edit" style={{ color: "red" }}
                                                    onClick={() => this.props.deleteFieldRows(row.id, index)}
                                                    disabled={!index}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>



                </div>}
                {(this.props.parentState.lookupType === "VL") && <div>

                    <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.props.addNewStaticVL()}
                        //className={classes.buttonAdd}
                        >
                            Add
                        </Button>
                    </span>
                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.KEY.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.VALUE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.ADDITIONAL_VALUE.longDescription}</TableCell>

                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.parentState.valueList.map((row, index) => {
                                    return (


                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}


                                            <TableCell >
                                                {/* <FormControl
                                                style={{ width: 220 }}
                                                variant="outlined" size="small">

                                                <Select
                                                    id="demo-simple-select-outlined"
                                                    value={row.label}
                                                    // size="small"
                                                    name="dataElement"
                                                    style={{ width: "100%" }}
                                                    onChange={(evt) => this.inputChangeHandler(evt)}
                                                >

                                                </Select>
                                            </FormControl> */}
                                                <TextField
                                                    required
                                                    id="filled-disabled"

                                                    name="key"
                                                    value={row.key}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                />
                                            </TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="filled-disabled"

                                                name="value"
                                                value={row.value}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="filled-disabled"

                                                name="additionalValue"
                                                value={row.additionalValue}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton aria-label="Edit" style={{ color: "red" }}
                                                    onClick={() => this.props.deleteStaticVL(row, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>



                </div>}
                {(this.props.parentState.lookupType === "FILE") && <div>

                    <Grid container spacing={3} hSpacing="2rem"
                        style={{ margin: "1rem" }}
                    >

                        <Grid item xs={12} sm={3} md={3}>

                            <FormControl
                                style={{ width: 220 }}
                                variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-outlined-label" required>{this.props.translation.FILE_TYPE.longDescription}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label={this.props.translation.FILE_TYPE.longDescription}
                                    value={this.props.parentState.fileMetadata.fileType}
                                    // size="small"
                                    name="fileType"
                                    style={{ width: "100%" }}
                                    onChange={(evt) => this.props.inputChangeHandler1(evt)}
                                // hidden={true}
                                >
                                    {this.state.fileTypeDropDown.map(option =>
                                        <MenuItem value={option.name} key={option.name}
                                        // onClick={() =>
                                        //     this.applicationChangeHandler(option.id, index)

                                        // }
                                        // style={{ width: 30 }}
                                        >{option.name}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} hidden={this.props.parentState.fileMetadata.fileType !== ".XML"}>
                            <TextField
                                required
                                // hidden={this.props.parentState.fileMetadata.fileType !== ".xml"}
                                id="filled-disabled"
                                label="ParentTag"
                                name="parentTag"
                                value={this.props.parentState.fileMetadata.properties.parentTag}
                                variant="outlined"
                                //style={{ marginLeft: 30 }}
                                size="small"
                                onChange={(evt) => this.props.inputChangeHandler1(evt)}
                            // className="customInput2" 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} >
                            <Button
                                hidden={this.props.parentState.fileMetadata.fileType !== ".XLSX"}
                                size="small"
                                variant="contained"
                                color="primary"

                                onClick={() => this.props.addSheets()}
                            //className={classes.buttonAdd}
                            >
                                Add Sheets
                            </Button>
                        </Grid>

                    </Grid>
                    {this.props.parentState.sheets.map((row, index) => {
                        return (
                            <Grid container spacing={3} hSpacing="2rem"
                                style={{ margin: "1rem" }} hidden={this.props.parentState.fileMetadata.fileType !== ".XLSX"}>
                                <Grid item xs={12} sm={3} md={3} >
                                    <TextField
                                        required

                                        id="filled-disabled"
                                        label={this.props.translation.SHEET_INDEX.longDescription}
                                        name="sheetIndex"
                                        value={row.sheetName ? row.sheetName : row.sheetIndex}
                                        variant="outlined"

                                        size="small"
                                        onChange={(evt) => this.props.sheetsInputs(evt, index)}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={3} md={3} >
                                    <TextField
                                        required
                                        id="filled-disabled"
                                        label={this.props.translation.ROW_START.longDescription}
                                        name="rowStart"
                                        value={row.rowStart}
                                        variant="outlined"
                                        //style={{ marginLeft: 30 }}
                                        size="small"
                                        onChange={(evt) => this.props.sheetsInputs(evt, index)}
                                    // className="customInput2" 
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3} md={3} >
                                    <TextField

                                        id="filled-disabled"
                                        label="Column Start"
                                        name="columnStart"
                                        value={row.columnStart}
                                        variant="outlined"
                                        //style={{ marginLeft: 30 }}
                                        size="small"
                                        onChange={(evt) => this.props.sheetsInputs(evt, index)}
                                    // className="customInput2" 
                                    />
                                </Grid>
                                <IconButton aria-label="Edit" style={{ color: "red" }}
                                    onClick={() => this.props.deleteSheet(row.id, index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        );
                    })}
                    <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.props.addNewConstraint()}
                            disabled={this.props.constraints.length !== 0 ? true : false}
                        //className={classes.buttonAdd}
                        >
                            Add
                        </Button>
                    </span>
                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table" name="constraintTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT_TYPE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.OPERATOR.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.VALUE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.constraints.length !== 0 && this.props.constraints.map((row, index) => {
                                    return (


                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}


                                            <TableCell >

                                                <FormControl
                                                    // style={{ width: 220 }}
                                                    variant="outlined" size="small">

                                                    <InputBase
                                                        name="constraintColumn"
                                                        id="constraintTable"
                                                        value={row.constraintColumn}
                                                        variant="outlined"
                                                        // disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={(evt) => this.props.fnAttrValueHelp(index, evt, "constraintTable")}
                                                                    // onMouseDown={handleMouseDownPassword}
                                                                    edge="end" >
                                                                    <SvgIcon >
                                                                        <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                    </SvgIcon >
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }>
                                                    </InputBase>
                                                </FormControl>

                                            </TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="constraintTable"
                                                disabled
                                                name="constraintName"
                                                value={row.constraintName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="constraintTable"

                                                name="mappedName"
                                                value={row.mappedName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"

                                                name="constraintType"
                                                value={row.constraintType}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"
                                                disabled
                                                name="constraintOperator"
                                                value={row.constraintOperator}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"

                                                name="constraintValue"
                                                value={row.constraintValue}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton aria-label="Edit" style={{ color: "red" }}
                                                    onClick={() => this.props.deleteConstraintRows(row.id, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.props.addNewFields()}
                        //className={classes.buttonAdd}
                        >
                            Add
                        </Button>
                    </span>
                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table" name="fieldsTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.FIELDS.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.SEARCHABLE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.UI_DISPLAY_NAME.longDescription}</TableCell>

                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.fields.map((row, index) => {
                                    return (
                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}
                                            <TableCell > <FormControl
                                                // style={{ width: 220 }}
                                                variant="outlined" size="small">

                                                <InputBase
                                                    name="columnName"
                                                    id="fieldsTable"
                                                    value={row.columnName}
                                                    variant="outlined"
                                                    // disabled
                                                    disabled={!index}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                            disabled={!index}
                                                                onClick={(evt) => this.props.fnAttrValueHelp(index, evt, "fieldsTable")}
                                                                // onMouseDown={handleMouseDownPassword}
                                                                edge="end" >
                                                             <SvgIcon  >
                                                                    <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                </SvgIcon >
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }>
                                                </InputBase>
                                            </FormControl>
                                            </TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="fieldsTable"
                                                disabled={!index}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                name="displayName"
                                                value={row.displayName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            <TableCell><TextField
                                                required
                                                id="fieldsTable"

                                                name="mappedName"
                                                value={row.mappedName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} >
                                                {/* <InputLabel id="demo-simple-select-outlined-label" ></InputLabel> */}
                                                <FormGroup aria-label="position" row >

                                                    <FormControlLabel
                                                        required
                                                        // id="fieldsTable"
                                                        size="small"
                                                        disabled={!index}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        value={row.searchable}
                                                        //checked={this.state.obj.active}
                                                        name="searchable"
                                                        onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                                        //value={row.active}
                                                        labelPlacement="start"
                                                        control={<Switch color="primary"
                                                            checked={row.searchable}
                                                            id="fieldsTable" />}


                                                    />


                                                </FormGroup>
                                            </FormControl ></TableCell>
                                            <TableCell>
                                                <RadioGroup row

                                                    style={{ marginLeft: "1rem" }}

                                                    name="isDisplayName"
                                                >
                                                    <Radio id="fieldsTable"
                                                        checked={row.isDisplayName}

                                                        onChange={(evt) => this.props.valueHelpTableInputs(evt, index)} />
                                                </RadioGroup>

                                            </TableCell>
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton aria-label="Edit" style={{ color: "red" }}
                                                    onClick={() => this.props.deleteFieldRows(row.id, index)}
                                                    disabled={!index}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>




                </div>}

                {(this.props.parentState.lookupType === "COMPOSITE") && <div>


                    <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.props.addNewConstraint()}
                            disabled={this.props.constraints.length !== 0 ? true : false}
                        //className={classes.buttonAdd}
                        >
                            Add
                        </Button>
                    </span>
                    <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                        <Table size="small" aria-label="a dense table" name="constraintTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT_TYPE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.OPERATOR.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.VALUE.longDescription}</TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.constraints.length !== 0 && this.props.constraints.map((row, index) => {
                                    return (


                                        <TableRow key={row.id}>

                                            {/* <TableCell >{row.application}</TableCell> */}


                                            <TableCell >
                                                <FormControl
                                                    // style={{ width: 220 }}
                                                    variant="outlined" size="small">

                                                    <InputBase
                                                        name="constraintColumn"
                                                        id="constraintTable"
                                                        value={row.constraintColumn}
                                                        variant="outlined"
                                                        // disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={(evt) => this.props.fnAttrValueHelp(index, evt, "constraintTable")}
                                                                    // onMouseDown={handleMouseDownPassword}
                                                                    edge="end" >
                                                                    <SvgIcon >
                                                                        <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                    </SvgIcon >
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }>
                                                    </InputBase>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="constraintTable"
                                                disabled
                                                name="constraintName"
                                                value={row.constraintName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell ><TextField
                                                required
                                                id="constraintTable"

                                                name="mappedName"
                                                value={row.mappedName}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>

                                            {/* <TableCell >{row.permissionType}</TableCell> */}
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"

                                                name="constraintType"
                                                value={row.constraintType}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"
                                                disabled
                                                name="constraintOperator"
                                                value={row.constraintOperator}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell><TextField
                                                required
                                                id="constraintTable"

                                                name="constraintValue"
                                                value={row.constraintValue}
                                                variant="outlined"
                                                //style={{ marginLeft: 30 }}
                                                size="small"
                                                onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                            // className="customInput2" 
                                            /></TableCell>
                                            <TableCell >
                                                {/* <Tooltip title= 'Edit'> */}
                                                <IconButton aria-label="Edit" style={{ color: "red" }}
                                                    onClick={() => this.props.deleteConstraintRows(row.id, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                {/* </Tooltip> */}

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <span className="iconBtn" style={{ float: 'left', marginBottom: '0.5rem' }}
                        hidden={!(this.props.parentState.attributeLapiDataInput && this.props.constraints.length !== 0)}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.props.addComposite()}
                        //className={classes.buttonAdd}
                        >
                            Add
                        </Button>
                    </span>
                    <span hidden={!(this.props.parentState.attributeLapiDataInput && this.props.constraints.length !== 0)}>
                    {this.props.parentState.compositeArray.map((row, index) => {
                        return (
                            <Grid container spacing={3} hSpacing="2rem"
                                style={{ margin: "1rem" }} hidden={!(this.props.parentState.attributeLapiDataInput && this.props.constraints.length !== 0)}>
                                <Grid item xs={12} sm={3} md={3} >
                                    <FormControl

                                        variant="outlined" size="small">
                                        <InputBase
                                            name=""
                                            id={row.key}
                                            label=""
                                            value={row.value}
                                            variant="outlined"
                                            // disabled
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.props.compositeValueHelp(index)}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end" >
                                                        <SvgIcon >
                                                            <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                        </SvgIcon >
                                                    </IconButton>
                                                </InputAdornment>
                                            }>
                                        </InputBase>
                                    </FormControl>


                                </Grid>

                                <Grid item xs={12} sm={3} md={3} >
                                    <FormControl
                                        style={{ width: 220 }}
                                        variant="outlined" size="small">
                                        <InputLabel id="demo-simple-select-outlined-label" required>Value Help Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Value Help Type"
                                            value={row.lookupType}
                                            // size="small"
                                            name="apiType"
                                            style={{ width: "100%" }}
                                            onChange={(evt, keyProps) => this.props.onLookupType(evt, keyProps, index)}

                                        >
                                            {this.state.valueHelpType.map(option =>
                                                <MenuItem value={option.key} key={option.key}

                                                >{option.name}</MenuItem>
                                            )}

                                        </Select>

                                    </FormControl>
                                    <IconButton aria-label="Edit" color="primary"
                                        hidden={row.lookupType === null ? true : false}
                                        onClick={() => this.fnComposite(row, index)}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>

                                </Grid>


                                <IconButton aria-label="Edit" style={{ color: "red" }}
                                    onClick={() => this.props.deleteComposite(row, index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        );
                    })}
                    </span>

{/* {this.state.busyIndicator ? <div style={{ display: "flex", float: "center", paddingBottom: 20, marginLeft: 500, marginTop: 200 }}><CircularProgress /></div> : */}
                    <Composite open={this.state.showVHTableDialog} actions={["DISCARD", "CANCEL"]} onClose={() => this.closeDialog()}
                        rowSelected={(row) => this.props.rowSelected(row, this.state.index)} {...this.props}
                        lookupType={this.state.lookupTypeC} getData1={(data) => this.props.getData(data, this.state.index)}
                        data={this.props.parentState.selectedRow}
                        lookupId={this.state.compositeRow.referenceId}
                        name={this.props.parentState.selectedRow.name}
                        compositeRow={this.state.compositeRow}
                        destinations={this.state.destinations}
                        dataTable={this.state.dataTable}
                        sheets={this.state.sheets}
                        fileMetadata={this.state.fileMetadata}
                        apiMetadata1={this.state.tempdata}
                        dbMetaData={this.state.dbMetaData}
                        valueList={this.state.valueList} constraints={this.state.constraints} fields={this.state.fields} />

                {/* } */}
                </div>}
                <ValueHelpDialog title="Value Help" open={this.props.parentState.showVHTableDialog} actions={["DISCARD", "CANCEL"]} onClose={() => this.props.closeDialog()} dataElement={this.props.parentState.fieldCatalogArray}
                    rowSelected={(row) => this.props.rowSelected(row, this.state.index)} />

            </div>
        );
    }
}

export default withRouter(ValueHelp);