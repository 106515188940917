import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Alert from '@mui/material/Alert';
import Table from "@material-ui/core/Table";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from '@material-ui/core/Link';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { MentionsInput, Mention } from 'react-mentions'
//import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import MessageBoxComponentDelete from "./CwWfDelete";
import MessageBoxComponentSubmit from "./CwWfSubmit";
import MessageBoxComponentSave from "./CwWfSaveConfirmation";
import MessageBoxComponentcombination from "./CwWfcombination";
import MessageBoxComponentcopy from "./CwWfcopy";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import { convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Snackbar from '@material-ui/core/Snackbar';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const content = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "Initialized from content state.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};
  const defaultMentionStyle = {
    backgroundColor: "#cee4e5",
  };
const defaultStyle = {
  control: {
    backgroundColor: "#cee4e5",
    fontSize: 14,
    fontWeight: "normal",
  },

  input: {
    margin: 0,
    overflow: "hidden",
    height: 30,
  },
  "&multiLine": {
    control: {
      fontFamily: "monospace",
      border: "1px solid silver",
    },
    highlighter: {
      padding: 9,
    },
    input: {
      padding: 9,
      minHeight: 3,
      outline: 0,
      border: 0,
    },
  },
  suggestions: {
    bottom: "0",
    top: "unset",
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};




 

class CwMSEmailtemplate extends React.Component {
  constructor(props) {
    super(props);
    this.settingsPopoverRef = React.createRef();
    let emptyRow = this.emptyRow();
    const contentState = convertFromRaw(content);

    this.state = {
      tableVisible: true,
      selectedRow: emptyRow,
      applicationmenu: [],
      mailDefination: [],
      entity: ['a'],
      process: [],
      newentity:false,
      tempid:"",
      variablename: [],
      uploadedImages:[],
      isLoaded: false,
      tempvar:[],
      tempvar1:[],
      finalprocess:[],
      finalapplication:[],
      finalentity:[],
      showDialogSave:false,
      application: [],
      entityname: [],
      processname: [],
      existing: false,
      editorState: EditorState.createEmpty(),
      contentState,
      value: "",
      mentions: [],
      users: [],
      combodialog:"Add Details",
      temp: "",
      tempdelete:"",
      tempvalid:[],
      defaultValue: EditorState.createEmpty(),
      messageOpen:false,
      messageContent:"",
      tempName:"",
      showDialogDiscard: false,
            showDialogSubmit: false,
            showDialogcopy:false,
            
            showValueHelpDialog: false,
            showDataElementDialog: false,
            showVHTableDialog: false,
            showValueHelpDynamicDialog: false,
            showDialogcombination:false
    };
  }
  // state = {
  //     tableVisible: true,

  // };
  emptyRow = () => {
    const selectedRow = {
      attributeId: "",
      application: "",
      applicationDesc: "",
      entity: "",
      entityDesc: "",
      process: "",
      emailDefinitionId: "",
      processDesc: "",
      attachments:[],
      createdBy: "",
      content: "",
      createdOn: null,
      subject: "",
      status:"Draft",
      description: "",
      destinations: null,
      bccAllowed: false,
      name: "",
      source: "",
      updatedBy: "",
      updatedOn: null,
    };
    return selectedRow;
  };
  createNewField = (event, newValue) => {
    this.setState({
      ...this.state,
      showDialogcombination:true,
      combodialog:"Add Details",
     // tableVisible: false,
      value: "",
      defaultValue: EditorState.createEmpty(),
      editorState:EditorState.createEmpty(),
      selectedRow:this.emptyRow(),
    });
    if(this.props.applicationName){
      this.setState({...this.state,selectedRow:{...this.state.selectedRow,
        application:this.props.applicationName},
        showDialogcombination:true,
      
  
      },()=>  this.entityNameListGet(this.state.selectedRow.application),)
      
   }
  };
   uploadCallback = (file) => {
    let uploadedImages = this.state.attachments;
console.log(file);
    const imageObject = {
      name:file.name,
      type: file.type,
      localSrc: URL.createObjectURL(file),
    }
    uploadedImages.push(imageObject);
    this.setState({...this.state,attachments: uploadedImages})
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      }
    );}
  onContentStateChange = (editorState) => {
    let value = draftToHtml(editorState);
    this.setState({
      ...this.state,
      selectedRow: {
        ...this.state.selectedRow,
        content: value,
      },
    });
  };
  statusHandler = (evt) => {
    let status = evt.target.checked;

    this.setState({
      ...this.state,
      selectedRow: { ...this.state.selectedRow, bccAllowed: status },
    });
  };

  inputChangeHandler1 = (e) => {
    this.setState({
      ...this.state,
      selectedRow: { ...this.state.selectedRow, subject: e.target.value },
    });
  };
  inputchangehandlerprocess=(e,value)=>{
    let t=this.state.process.filter(ele=>ele.processDesc===value.label)
    this.setState({...this.state,selectedRow:{...this.state.selectedRow,
     processDesc:value.label,
    process:t[0].process
     },},  ()=> this.variableNameListGet(
      this.state.selectedRow.application,
      this.state.selectedRow.entity,
      this.state.selectedRow.process
    ),)
    
  }
  oninputchangehandlerprocess=(e,value)=>{
    this.setState({...this.state,newentity:true,
      selectedRow:{...this.state.selectedRow,
      process:value,
    processDesc:value
     },


    },()=> this.variableNameListGet(
      this.state.selectedRow.application,
      this.state.selectedRow.entity,
      this.state.selectedRow.process
    ),)
    
  }
  inputchangehandlerappliacation=(e,value)=>{
    
    this.setState({...this.state,selectedRow:{...this.state.selectedRow,
      application:value.label},
    

    },()=>  this.entityNameListGet(this.state.selectedRow.application),)
    
  }
  inputchangehandlerentity=(e,value)=>{
    let t=this.state.entity.filter(ele=>ele.entityDesc===value.label)
    this.setState({...this.state,selectedRow:{...this.state.selectedRow,
     entityDesc:value.label,
     entity:t[0].entity

     },

    },()=> this.processNameListGet(
      this.state.selectedRow.application,
      this.state.selectedRow.entity
    ),)
     console.log(this.state.selectedRow.entity)
  }
  oninputchangehandlerentity=(e,value)=>{
    let t=this.state.entity.filter(ele=>ele.entityDesc===value)
   
    if(t.length===0){
    this.setState({...this.state,newentity:true,selectedRow:{...this.state.selectedRow,
     entity:value,
     entityDesc:value,
   },
    }
    ,()=> this.processNameListGet(
      this.state.selectedRow.application,
      this.state.selectedRow.entity
    ),)}
    else{
    
      this.setState({...this.state,selectedRow:{...this.state.selectedRow,
        entityDesc:value,
        entity:t[0].entity
        },
   
       },()=> this.processNameListGet(
         this.state.selectedRow.application,
         this.state.selectedRow.entity
       ),)
    }
     console.log(this.state.selectedRow.entity)
  }
  createtemplate=(evt)=>{if(evt==="CANCEL")this.setState({...this.state,showDialogcombination:false})
else this.setState({...this.state,showDialogcombination:false,tableVisible:false})};
  deletetemplate=(evt,row)=>{
    if(evt==="DISCARD"){
      
    this.deleteConstraint(row);}
    this.setState(
      {
          ...this.state,
          showDialog: false,
          showDialogDiscard: false,
          showDialogSubmit: false,
          showDialogSave: false,
          showValueHelpDialog: false,
          showDataElementDialog: false,
          showVHTableDialog: false,
          showValueHelpDynamicDialog: false
      });

  }
  copytemplate=(evt)=>{if(evt==="CANCEL")this.setState({...this.state,showDialogcopy:false,selectedRow:this.emptyRow()})
else{ 
  if(this.state.newentity){ 
    
      //this.convert();
      let artemp; const tempvarvar=[];
      for(var i=0;i<this.state.tempvar.length;++i){
        artemp={variable:this.state.tempvar[i]}
        tempvarvar.push(artemp)
      }
    
    var epayload=[
      {
        application: this.state.selectedRow.application,
        applicationDesc: this.state.selectedRow.application,
        entity: this.state.selectedRow.entity,
        entityDesc: this.state.selectedRow.entityDesc,
        
        process: this.state.selectedRow.process,
    processDesc: this.state.selectedRow.processDesc,
        variables:tempvarvar,}
    ]
    let method,url;
	
  
    method="POST"
    url= '/workbox-mail-configuration/v1/application/hierarchy';
    

    const requestParam1 = {
      method: method,
      headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
      body: JSON.stringify(epayload),
  }
fetch(url,requestParam1)
  .then((response) => response.json())
  .then((response) => {
    this.setState({...this.state,messageOpen:true,messageContent:response.message});
  })
  .then( setTimeout(() => {
    console.log("Done");
  }, 300))

	
  }
  
 
  var  payload = {
    application: this.state.selectedRow.application,
    applicationDesc: this.state.selectedRow.application,
    attachments: [
      { 
        content: "string",
        fileName: "string",
        type: "string",
      },
    ],
    bccAllowed: this.state.selectedRow.bccAllowed,
    bccList: ["string"],
    ccList: ["string"],
    content: this.state.selectedRow.content,
    createdBy: this.state.selectedRow.createdBy,
    createdOn: "2021-12-01T09:32:38.783Z",
    emailDefinitionId: this.state.selectedRow.emailDefinitionId,
    entity: this.state.selectedRow.entity,
    entityDesc: this.state.selectedRow.entityDesc,
    fromAddress: "string",
    name: this.state.selectedRow.name,
    objectd: "string",
    process: this.state.selectedRow.process,
    processDesc: this.state.selectedRow.processDesc,
    signature: "string",
    status: this.state.selectedRow.status,
    subject: this.state.value,
    toList: ["string"],
    updatedBy: this.state.selectedRow.updatedBy,
    updatedOn: "2021-12-01T09:32:38.783Z",
  };
  let method = "POST";
  let url = "/workbox-mail-configuration/v1/mail-definition/";
  const requestParam1 = {
    method: method,
    headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
    body: JSON.stringify(payload),
  };
  fetch(url, requestParam1)
    .then((response)=>response.json())
    .then((response) => {
      this.setState({...this.state,messageOpen:true,messageContent:response.message});
    })
    .then(() => this.mailDefination());
this.setState(
  {...this.state,showDialogcopy:false,selectedRow:this.emptyRow()}
  )
  }};


  deletetemplate=(evt,row)=>{
    if(evt==="DISCARD"){
      
    this.deleteConstraint(row);}
    this.setState(
      {
          ...this.state,
          showDialog: false,
          showDialogDiscard: false,
          showDialogSubmit: false,
          showDialogSave: false,
          showValueHelpDialog: false,
          showDataElementDialog: false,
          showVHTableDialog: false,
          showValueHelpDynamicDialog: false
      });

  }
  closeDialog = (evt) => {
   
       
    
     if (evt === "SUBMIT") {
      this.onSubmitDataElement();
    }
    else if (evt === "SAVE" || evt==="NO") {
        //   this.onSaveMappingRolesDraft();
        this.onSaveDataElement();
    }


    this.setState(
        {
            ...this.state,
            showDialog: false,
            showDialogDiscard: false,
            showDialogSubmit: false,
            showDialogSave: false,
            showValueHelpDialog: false,
            showDataElementDialog: false,
            showVHTableDialog: false,
            showValueHelpDynamicDialog: false
        });

}
  inputChangeHandler = (evt) => {
    let value = evt.target.value,
      isValid,
      errorMssg = "",
      isValidObj = {},
      lengthVal;

    //this.state.obj[evt.target.name] = evt.target.value;

    this.setState(
      {
        ...this.state,
        validLength: !isValid,
        errorMssg: errorMssg,
        selectedRow: {
          ...this.state.selectedRow,
          [evt.target.name]: value,
        },
      },
      () => {
        if (evt.target.name == "application") {
         
        }
        
        if (evt.target.name == "process") {
         
        }
      }
    );

    // console.log(this.state.selectedRow.application);
  };
  // applicationChangeHandler = (option) => {
  //   console.log(option.application)
  //   this.setState({
  //     ...this.state,
  //     selectedRow:
  //     { ...this.state.selectedRow,
  //       application: option.application
  //     }

  //   }, () => this.entityNameListGet(this.state.selectedRow.application));
  //   console.log(this.state.selectedRow.application)
  // };
  entityNameListGet = (appName) => {
    fetch(
      "/workbox-mail-configuration/v1/application/entity?application=" + appName
      ,{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({ ...this.state, isLoaded: true, entity: json.data },()=>this.changeentity());
      });
  };
  handleChange = (event) => {
    
    const value = event.target.value;
    const regex = /[^{}]+(?=})/g;
    const mentions = value.match(regex);
    console.log("mentions: ", mentions);
    this.setState({ value: event.target.value });
  };
  onSaveDataElement = (e) => {
  var tempmessage="Add all the fields" ;
  var  payload = {
      application: this.state.selectedRow.application,
      applicationDesc: this.state.selectedRow.applicationDesc,
      attachments: [
        {
          content: "string",
          fileName: "string",
          type: "string",
        },
      ],
      bccAllowed: this.state.selectedRow.bccAllowed,
      bccList: ["string"],
      ccList: ["string"],
      content: this.state.selectedRow.content,
      createdBy: this.state.selectedRow.createdBy,
      createdOn: "2021-12-01T09:32:38.783Z",
      emailDefinitionId: this.state.selectedRow.emailDefinitionId,
      entity: this.state.selectedRow.entity,
      entityDesc: this.state.selectedRow.entityDesc,
      fromAddress: "string",
      name: this.state.selectedRow.name,
      objectd: "string",
      process: this.state.selectedRow.process,
      processDesc: this.state.selectedRow.processDesc,
      signature: "string",
      status: this.state.selectedRow.status,
      subject: this.state.value,
      toList: ["string"],
      updatedBy: this.state.selectedRow.updatedBy,
      updatedOn: "2021-12-01T09:32:38.783Z",
    };
    let method, url;
    if (this.state.selectedRow.emailDefinitionId === "") {
      method = "POST";
      url = "/workbox-mail-configuration/v1/mail-definition/";
    } else {
      method = "PATCH";
      url =
        "/workbox-mail-configuration/v1/mail-definition/" +
        this.state.selectedRow.emailDefinitionId;
        payload =
        {
          application: this.state.selectedRow.application,
          applicationDesc: this.state.selectedRow.applicationDesc,
          attachments: [
            {
              content: "string",
              fileName: "string",
          type: "string",
            },
          ],
          bccAllowed: this.state.selectedRow.bccAllowed,
          bccList: ["string"],
          ccList: ["string"],
          content: this.state.selectedRow.content,
          createdBy: this.state.selectedRow.createdBy,
          createdOn: "2021-12-01T09:32:38.783Z",
          emailDefinitionId: this.state.selectedRow.emailDefinitionId,
          entity: this.state.selectedRow.entity,
          entityDesc: this.state.selectedRow.entityDesc,
          fromAddress: "string",
          name: this.state.selectedRow.name,
          objectd: "string",
          process: this.state.selectedRow.process,
          processDesc: this.state.selectedRow.processDesc,
          signature: "string",
          status: "Draft",
          subject: this.state.value,
          toList: ["string"],
          updatedBy: this.state.selectedRow.updatedBy,
          updatedOn: "2021-12-01T09:32:38.783Z",
        };
    }
    if(payload.application && payload.entity && payload.process && payload.name ) {
    this.setState({
      ...this.state,
      existing: "false",
    });
    const requestParam1 = {
      method: method,
      headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
      body: JSON.stringify(payload),
    };
    fetch(url, requestParam1)
      .then((response)=>response.json())
      .then((response) => {
        this.setState({...this.state,messageOpen:true,messageContent:response.message});
      })
      .then(() => this.mailDefination());}
      else{this.setState({
        ...this.state,
        existing: "false",
      });
      const requestParam1 = {
        method: method,
        headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
        body: JSON.stringify(payload),
      };
      fetch(url, requestParam1)
        .then((response)=>response.json())
        .then((response) => {
          this.setState({...this.state,messageOpen:true,messageContent:response.message});
        })
        
      }
  };

  mailDefination = () => {
    
      if(this.props.applicationName)
      {
        fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
            mailDefination: json.data.filter(app=>app.application.includes(this.props.applicationName)),
            tableVisible: true,
          });
        });
      }
      else{
        fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
            mailDefination: json.data,
            tableVisible: true,
          });
        });
      }
  };
  changeprocess=()=>{
    let temp;const tempp=[];
      for(var i=0;i<this.state.process.length;++i)
      {temp={label:this.state.process[i].processDesc}
      tempp.push(temp)};
     
      this.setState({
        ...this.state,
        finalprocess:tempp,

      })
   
  }
  changeentity=()=>{
    let temp;const tempp=[];
      for(var i=0;i<this.state.entity.length;++i)
      {temp={label:this.state.entity[i].entityDesc}
      tempp.push(temp)};
     
      this.setState({
        ...this.state,
        finalentity:tempp,

      })
    console.log(this.state.finalprocess);
  }
  changeapplication=()=>{
    let temp;const tempp=[];
      for(var i=0;i<this.state.applicationmenu.length;++i)
      {temp={label:this.state.applicationmenu[i].application}
      tempp.push(temp)};
     
      this.setState({
        ...this.state,
        finalapplication:tempp,

      })
    console.log(this.state.finalprocess);
  }
  processNameListGet = (appname, entityname) => {
    fetch(
      "/workbox-mail-configuration/v1/application/process?application=" +
        appname +
        "&entity=" +
        entityname,{headers:{"Authorization":this.props.authorization}}
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ ...this.state, isLoaded: true, process: json.data },()=>this.changeprocess());
        
      });

  };
  variablecall = (option) => {
    this.setState(
      {
        ...this.state,
        processname: option.process,
      },
      () =>
        this.variableNameListGet(
          this.state.application,
          this.state.entityname,
          this.state.processname
        )
    );
  };
  variableNameListGet = (appName, entityname, process) => {
    fetch(
      "/workbox-mail-configuration/v1/application/variable?application=" +
        appName +
        "&entity=" +
        entityname +
        "&process=" +
        process,{headers:{"Authorization":this.props.authorization}}
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ...this.state,
          isLoaded: true,
          variablename: json.data,
        });
      })
      .then(() => this.change1());
  };

  change1 = () => {
    var temp = [],
      arr = {},
      id,
      display;
    for (var i = 0; i < this.state.variablename.length; i++) {
      arr = {
       id:null,
        display:this.state.variablename[i].variable,
      };
      temp.push(arr);
    }
    this.setState({
      ...this.state,
      users: temp,
    });
  };
  deleteConstraint = (row) => {
    let url =
      "/workbox-mail-configuration/v1/mail-definition/" + row.emailDefinitionId;

    fetch(url,{headers:{"Authorization":this.props.authorization}}, { method: "DELETE" })
    .then((response)=>response.json())
    .then((response) => {
      this.setState({...this.state,messageOpen:true,messageContent:response.message},()=>{this.refreshfield()});
    })
      ;
  };

  editFields = (row) => {
    this.entityNameListGet(row.application);
    this.processNameListGet(row.application, row.entity);
    this.variableNameListGet(row.application, row.entity, row.process);
    this.setState(
      {
        ...this.state,
        tableVisible: false,
        existing: true,
        selectedRow: row,
        value: row.subject,
        // temp:htmlToDraft(row.content),
        // editorState:htmlToDraft(row.content)
        // editorState:htmlToDraft(this.state.selectedRow.content)
      },
      () => {this.setDefault(row.content);console.log(this.state);}
    );
    //this.onEditorStateChange(this.state.temp);
  };
  // converStateChange=(content)=>{
  //  this.setState({

  //   ...this.state,
  //   editorState:htmlToDraft(content)});
  // };
  //   update=(row)=>  {
  //   this.entityNameListGet(this.state.selectedRow.application),
  //   this.processNameListGet(row.application,row.entity),
  //   this.variableNameListGet(row.application,row.entity,row.processname)
  //  };
  editcombo=(evt)=>{
    console.log("clicked")
    this.setState
    ({...this.state,
      showDialogcombination:true,
      combodialog:"Edit Details"
    })}
    copycombo=(row)=>{
      var t=this.props.applicationName;
      fetch("/workbox-mail-configuration/v1/application/variables?id="+row.emailDefinitionId,{headers:{"Authorization":this.props.authorization}})
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              ...this.state,
              isLoaded: true,
              tempvar: json.data,
            });
          });
         
          if(this.props.applicationName){
            this.setState(
              {
                ...this.state,
                showDialogcopy: true,
               
                existing: true,
                value:row.subject,
                tempid:row.emailDefinitionId,
                
                selectedRow: {...this.state.selectedRow,
                  content:row.content,
                  status:row.status,
                  bccAllowed:row.bccAllowed,
                  application:this.props.applicationName
                 
                 
      
      
                }
             
                
                
              },
              () => {this.setDefault(row.content);this.entityNameListGet(this.props.applicationName)}
            );
           
             
            
         }

      //console.log("clicked")
      // this.entityNameListGet(row.application);
      // this.processNameListGet(row.application, row.entity);
      // this.variableNameListGet(row.application, row.entity, row.process);
     else{this.setState(
        {
          ...this.state,
          showDialogcopy: true,
         
          existing: true,
          value:row.subject,
          tempid:row.emailDefinitionId,
          
          selectedRow: {...this.state.selectedRow,
            content:row.content,
            status:row.status,
            bccAllowed:row.bccAllowed
           
           


          }
       
          
          
        },
        () => {this.setDefault(row.content)}
      );}
     }
     convert=()=>{let artemp; const tempvarvar=[];
      for(var i=0;i<this.state.tempvar.length;++i){
        artemp={variable:this.state.tempvar[i]}
        tempvarvar.push(artemp)
      }
      this.setState({
        ...this.state,
        tempvar1:tempvarvar,
      })
      }
  backToTable = (event) => {
    let emptyRow = this.emptyRow();
    this.setState({
      ...this.state,
      tableVisible: true,
      selectedRow: emptyRow,
    });
  };
   savethedata=()=>{
    this.setState({
      ...this.state,

      showDialogSave: true

  })
   }
  goToMsgComp = (event) => {
    this.props.history.push("/messageSummary");
  };

  goToThemeComp = (event) => {
    this.props.history.push("/themeStyling");
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };
  validatethedata=()=>{
    fetch("/workbox-mail-configuration/v1/mail-definition/validate?application="+ this.state.selectedRow.application+ "&entity="+this.state.selectedRow.entity+"&process="+this.state.selectedRow.process,{headers:{"Authorization":this.props.authorization}})
    .then(res =>res.json())
    .then(json=>
        {this.setState
            ({...this.state,
             isLoaded1:true,
            tempvalid:json.data,
           

    },()=>this.validation()
    );})

  };
  validation=()=>{
    
    if(!this.state.tempvalid){
      this.setState({
        ...this.state,
  
        showDialogSave: true,
        selectedRow:
        {
          ...this.state.selectedRow,
          status:"Active"
        }
  
    })
    }

    else{
      let rowObj = this.state.tempvalid.find((item) => item.status.toLowerCase() === "active")
      let draftobj= this.state.tempvalid.find((item) => item.status.toLowerCase() === "draft")
      let templateName="" ;
      if(rowObj){templateName="Active Template("+rowObj.name+ ") already exists ,Submitting this will deactivate the older template"}
      else
      { if(draftobj) templateName="Draft Template already exists , Press continue to create a active template" };
      
      this.setState({
        ...this.state,
        tempName:templateName,
        showDialogSubmit: true,
    })
    }
  }
  onSubmitDataElement = (e) => {
    
    const payload = {
      application: this.state.selectedRow.application,
      applicationDesc: this.state.selectedRow.applicationDesc,
      attachments: [
        {
          content: "string",
          fileName: "string",
          type: "string",
        },
      ],
      bccAllowed: this.state.selectedRow.bccAllowed,
      bccList: ["string"],
      ccList: ["string"],
      content: this.state.selectedRow.content,
      createdBy: this.state.selectedRow.createdBy,
      createdOn: "2021-12-01T09:32:38.783Z",
      emailDefinitionId: this.state.selectedRow.emailDefinitionId,
      entity: this.state.selectedRow.entity,
      entityDesc: this.state.selectedRow.entityDesc,
      fromAddress: "string",
      name: this.state.selectedRow.name,
      objectd: "string",
      process: this.state.selectedRow.process,
      processDesc: this.state.selectedRow.processDesc,
      signature: "string",
      status: "Active",
      subject: this.state.value,
      toList: ["string"],
      updatedBy: this.state.selectedRow.updatedBy,
      updatedOn: "2021-12-01T09:32:38.783Z",
    };
    let method, url;
    if (this.state.selectedRow.emailDefinitionId === "") {
      method = "POST";
      url = "/workbox-mail-configuration/v1/mail-definition/";
      
    } else {
      method = "PATCH";
      url =
        "/workbox-mail-configuration/v1/mail-definition/" +
        this.state.selectedRow.emailDefinitionId;
       
    }
    if(payload.application && payload.entity && payload.process && payload.name ) {
      this.setState({
        ...this.state,
        existing: "false",
      });
      const requestParam1 = {
        method: method,
        headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
        body: JSON.stringify(payload),
      };
      fetch(url, requestParam1)
        .then((response)=>response.json())
        .then((response) => {
          this.setState({...this.state,messageOpen:true,messageContent:response.message});
        })
        .then(() => this.mailDefination());}
        else{this.setState({
          ...this.state,
          existing: "false",
        });
        const requestParam1 = {
          method: method,
          headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
          body: JSON.stringify(payload),
        };
        fetch(url, requestParam1)
          .then((response)=>response.json())
          .then((response) => {
            this.setState({...this.state,messageOpen:true,messageContent:response.message});
          })
          
        }
   
   
    
    };
  updated = () => {
    fetch("/workbox-mail-configuration/v1/application",{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ...this.state,
          isLoaded: true,
          applicationmenu: json.data,
        });
      });
   
      if(this.props.applicationName)
      {
        fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
            mailDefination: json.data.filter(app=>app.application.includes(this.props.applicationName)),
            tableVisible: true,
          });
        });
      }
      else{
        fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
            mailDefination: json.data,
            tableVisible: true,
          });
        });
      }
  };
  onDelete=(option)=> {
        
    this.setState({
        ...this.state,

        showDialogDiscard: true,
        selectedRow:option

    })
};
refreshfield=()=>{fetch("workbox-field-catalog/v1/applications",{headers:{"Authorization":this.props.authorization}})
.then((res) => res.json())
.then((json) => {
  this.setState({
    ...this.state,
    isLoaded: true,
    applicationmenu: json.data,
  });
});

if(this.props.applicationName)
{
  fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
  .then((res) => res.json())
  .then((json) => {
    this.setState({
      ...this.state,
      isLoaded: true,
      mailDefination: json.data.filter(app=>app.application.includes(this.props.applicationName)),
      tableVisible: true,
    });
  });
}
else{
  fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
  .then((res) => res.json())
  .then((json) => {
    this.setState({
      ...this.state,
      isLoaded: true,
      mailDefination: json.data,
      tableVisible: true,
    });
  });
};}

  componentDidMount() {
    fetch("workbox-field-catalog/v1/applications",{headers:{"Authorization":this.props.authorization}})
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ...this.state,
          isLoaded: true,
          applicationmenu: json.data,
        },()=>this.changeapplication());
      });

      // if(this.props.applicationName){
      //   this.setState({
      //     ...th
      //   })
      // }

      if(this.props.applicationName)
      {
        fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
            mailDefination: json.data.filter(app=>app.application.includes(this.props.applicationName)),
            tableVisible: true,
          });
        });
      }
      else{
        fetch("/workbox-mail-configuration/v1/mail-definition",{headers:{"Authorization":this.props.authorization}})
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            ...this.state,
            isLoaded: true,
            mailDefination: json.data,
            tableVisible: true,
          });
        });
      }
  }
  setDefault = (content) => {
    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({ ...this.state, defaultValue: editorState ,editorState:editorState});
  };
  render() {
    // const { editorState } = this.state;
    // var { isLoaded, applicationmenu } = this.state;
    // var { isLoaded, mailDefination } = this.state;
    // var { isLoaded, entity } = this.state;
    // var { isLoaded, process } = this.state;
    // var { isLoaded, variablename } = this.state;
    // const { contentState } = this.state;
    const userAccess = this.props.userAccess;
    console.log(userAccess);
    const CreateEmailtemp = userAccess.activities.filter(
        ele => ele === "Create Email Template"
        ) ? true : false;
    // const CreateEmailtemp = true;    
    // const userAccess = this.props.userAccess;
    // const modellingEntity = userAccess.entities.find(ele => ele.name === "Modelling") ? true : false;
    // const userFormsEntity = userAccess.entities.find(ele => ele.name === "UserForms") ? true : false;
    // const configurationEntity = userAccess.entities.find(ele => ele.name === "Configuration") ? true : false;
    // const rulesEntity = userAccess.entities.find(ele => ele.name === "Rules") ? true : false;

    return (
      <div>
        {this.state.tableVisible && (
          <div>
            <span
              className="iconBtn"
              style={{ float: "right" }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={!CreateEmailtemp}
                style={{ margin:"-3rem 2rem 0rem 0rem" }}
                onClick={this.createNewField}
                //className={classes.buttonAdd}
              >
                Create
              </Button>
            </span>

           <div style={{marginTop:'-1rem',marginLeft:'1rem'}}>
              <TableContainer
              component={Paper}
              style={{ width: "99%" }}
            >
              <Table size="small" aria-label="a dense table" style={{marginTop:'-1rem'}}>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      Name
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                      Application
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                      Entity
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                    Process
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                      Status
                    </TableCell>

                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                      {" "}
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 700 }}>
                   <IconButton> <RefreshIcon  aria-label="Edit"
                        
                          onClick={() => this.refreshfield()}/></IconButton> 
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.mailDefination.map((option) => (
                    <TableRow key={option.id}>
                      {/* <TableCell >{row.application}</TableCell> */}

                      <TableCell>{option.name}</TableCell>
                      <TableCell>{option.applicationDesc}</TableCell>
                      <TableCell>{option.entityDesc}</TableCell>
                      <TableCell>{option.processDesc}</TableCell>
                      <TableCell>{option.status}</TableCell>

                      {/* <TableCell >{row.permissionType}</TableCell> */}

                      <TableCell style={{padding:'0'}}>
                        {/* <Tooltip title= 'Edit'> */}
                        <IconButton
                          aria-label="Edit"
                          style={{ color: "green" }}
                          onClick={() => this.editFields(option)}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                        <IconButton
                            size="medium"
                            aria-label="Edit"
                            color="secondary"
                           
                            onClick={()=>this.onDelete(option)}
                            disabled={option.status==="Active" ||!CreateEmailtemp? true : false}
                            >
                                <DeleteRoundedIcon />
                            </IconButton>
                        
                        <MessageBoxComponentDelete
         title="delete"
          open={this.state.showDialogDiscard}
          actions={["DISCARD", "CANCEL"]}
          onClose={(evt)=>this.deletetemplate(evt,this.state.selectedRow)}
        /> 
                        {/* </Tooltip> */}
                      </TableCell>
                      <TableCell>
                        <span
              className="iconBtn"
              style={{ float: "right", margin: "0.5rem" }}
            >
           {this.props.applicationName ?  <MessageBoxComponentcopy
          title="Combination"
          applicationName={this.props.applicationName}
          open={this.state.showDialogcopy}
          actions={["SAVE", "CANCEL"]}
          onClose={(evt)=>this.copytemplate(evt)}
          selectedRow={this.state.selectedRow}
          applicationmenu={this.state.applicationmenu}
          inputChangeHandler={this.inputChangeHandler}
          inputchangehandlerprocess={this.inputchangehandlerprocess}
          oninputchangehandlerprocess={this.oninputchangehandlerprocess}
          oninputchangehandlerentity={this.oninputchangehandlerentity}
          inputchangehandlerentity={this.inputchangehandlerentity}
          inputchangehandlerapplication={this.inputchangehandlerappliacation}
          entity={this.state.finalentity}
          process={this.state.finalprocess}
                /> : <MessageBoxComponentcopy
                title="Combination"
                
                open={this.state.showDialogcopy}
                actions={["SAVE", "CANCEL"]}
                onClose={(evt)=>this.copytemplate(evt)}
                selectedRow={this.state.selectedRow}
                applicationmenu={this.state.applicationmenu}
                inputChangeHandler={this.inputChangeHandler}
                inputchangehandlerprocess={this.inputchangehandlerprocess}
                oninputchangehandlerprocess={this.oninputchangehandlerprocess}
                oninputchangehandlerentity={this.oninputchangehandlerentity}
                inputchangehandlerentity={this.inputchangehandlerentity}
                inputchangehandlerapplication={this.inputchangehandlerappliacation}
                entity={this.state.finalentity}
                process={this.state.finalprocess}
                      />}
              <Button
                size="small"
                variant="contained"
                color="primary"
                //style={{ margin:"-5rem 2rem 0rem 0rem" }}
                onClick={()=>this.copycombo(option)}
                //className={classes.buttonAdd}
                disabled={option.status==="Active" ||  !CreateEmailtemp ? false : true}
              >
               Copy 
              </Button>
            </span></TableCell>
             
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>
          </div>
        )}
        {!this.state.tableVisible && (
          <div style={{marginTop:'-1rem'}}>
            <span style={{ float: "left",marginTop:"-0.75rem" }}>
              
              <IconButton
                aria-label="Edit"
                style={{ color: "blue", size: "2rem" }}
                onClick={this.backToTable}
              >
                <KeyboardArrowLeftIcon />
              
              
</IconButton>
            </span>
             <Grid style={{marginTop:'2rem',fontFamily:"monospace", fontSize:"1rem"}}>
Application: {this.state.selectedRow.application} |

   Entity: {this.state.selectedRow.entityDesc}|
   Process: {this.state.selectedRow.processDesc}
</Grid>
<span
              className="iconBtn"
              style={{ float: "right", margin: "0.5rem" }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ margin:"-5rem 2rem 0rem 0rem" }}
                onClick={()=>this.editcombo()}
                //className={classes.buttonAdd}
              >
               Edit
              </Button>
            </span>
            <Grid
              container
              spacing={3}
              hSpacing="2rem"
              style={{ padding: "1rem", width: "100%",marginTop:'-3rem' }}
            >
              {/* <Grid item xs={8} sm={4} md={3}>
                <FormControl
                  style={{ width: 220 }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel id="demo-simple-select-outlined-label" required>
                    Application
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // // size="small"

                    name="application"
                    style={{ width: "100%" }}
                    value={this.state.selectedRow.application}
                    onChange={(event) => this.inputChangeHandler(event)}
                  >
                    {this.state.applicationmenu.map((option) => (
                      <MenuItem
                        // value={option.application}

                        key={option.id}
                        value={option.name}
                        // onClick={(option) => this.applicationChangeHandler(option)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> 

              <Grid item xs={8} sm={4} md={3}>
                <FormControl
                  style={{ width: 220 }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel id="demo-simple-select-outlined-label" required>
                    Entity Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.state.selectedRow.entity}
                    // size="small"
                    name="entity"
                    style={{ width: "100%" }}
                    onChange={(event) => this.inputChangeHandler(event)}
                  >
                    {/* {this.state.applicationList.map(option =>
                                    <MenuItem value={option.id} key={option.id}
                                    // onClick={() =>
                                    //     this.applicationChangeHandler(option.id, index)

                                    // }
                                    // style={{ width: 30 }}
                                    >{option.name}</MenuItem>
                                )} 
                    {this.state.entity.map((option) => (
                      <MenuItem value={option.entity} key={option.id}>
                        {option.entity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                    </Grid>*/}
              {/* 
               */}

              <Grid item xs={8} sm={4} md={2}>
                <TextField
                  required
                  id="filled-disabled"
                  label="Email template Name"
                  name="name"
                  value={this.state.selectedRow.name}
                  variant="outlined"
                  //style={{ marginLeft: 30 }}
                  size="small"
                  onChange={(evt) => this.inputChangeHandler(evt)}
                  // className="customInput2"
                />
              </Grid>

              <Grid item xs={12} sm={5} md={8}>
                {/* <TextField
                  required
                  id="filled-disabled"
                  name="subject"
                  value={this.state.selectedRow.subject}
                  variant="outlined"
                  //style={{ marginLeft: 30 }}
                  size="small"
                  onChange={(evt) => this.inputChangeHandler(evt)}
                  style={{ marginTop: "1rem" }}
                  // className="customInput2"
                  fullWidth
                  label="Subject"
                > */}

                <MentionsInput
                  value={this.state.value}
                  onChange={this.handleChange}
                  style={defaultStyle}
                > 
                  <Mention
                    markup="&__display__"
                    trigger="&"
                    data={this.state.users}
                    // onAdd={this.onAdd}
                    style={defaultMentionStyle}
                  />
                </MentionsInput>
                {/* </TextField> */}
              </Grid>
              <Grid item l={12} sm={3} md={2}>
                <InputLabel id="bccAllowed">BCC Allowed</InputLabel>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  onChange={(evt) => this.statusHandler(evt)}
                  label="bccAllowed"
                >
                  <Switch
                    state={this.state.selectedRow.bccAllowed}
                    checked={this.state.selectedRow.bccAllowed}
                    color="primary"
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Stack>
              </Grid>

              
              <Grid item l={10} md={15}>
                <Editor
                  editorState={this.state.editorState}
                  defaultEditorState={this.state.defaultValue}
                  onEditorStateChange={this.onEditorStateChange}
                  onContentStateChange={this.onContentStateChange}
                  toolbar={{
                    inline: { inDropdown: false },
                    list: { inDropdown: false },
                    textAlign: { inDropdown: false },
                    link: { inDropdown: false },
                    history: { inDropdown: false },
                    image: {
                      uploadCallback: this.uploadCallback,
                      alt: { present: true, mandatory: true },
                      previewImage: true,
                      inputAccept:
                        "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    },
                  }}
                  mention={{
                    separator: " ",
                    trigger: "&",

                    suggestions: this.state.variablename.map((option) => ({
                      text: option.variable,
                      value: option.variable,
                      url: option.variable,
                    })),
                  }}
                />
              </Grid>
              
            </Grid>
            
          </div>
        )}
        {!this.state.tableVisible && (

          <div className="footerdiv" style={{position:"fixed", right:"10px" ,bottom:"10px"}}>
            <Button
              style={{ margin: "0 0.5rem" }}
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => this.mailDefination()}
            >
              Cancel
            </Button>
            <Button
             style={{ margin: "0 0.5rem" }}
              variant="contained"
              color="primary"
              size="small"
              disabled={!CreateEmailtemp}
              onClick={() => this.savethedata()}
            >
              Save
            </Button>
            
            <Button
              variant="contained"
              color="primary"
              size="small"
             
              onClick={() => this.validatethedata()}
              disabled={this.state.selectedRow.status==="Active" || !CreateEmailtemp? true : false}
            >
              Submit
            </Button>
          </div>
        )}
        <MessageBoxComponentSave
          title="save"
          open={this.state.showDialogSave}
          actions={["SAVE", "CANCEL"]}
          onClose={(evt)=>this.closeDialog(evt)}
        />
        <MessageBoxComponentSubmit
          title="submit"
          open={this.state.showDialogSubmit}
          actions={["SUBMIT", "NO","CANCEL"]}
          onClose={(evt)=>this.closeDialog(evt)}
          tempName={this.state.tempName}
        />
         {this.props.applicationName ? <MessageBoxComponentcombination
          title="Combination"
          open={this.state.showDialogcombination}
          actions={["SAVE", "CANCEL"]}
          applicationName={this.props.applicationName}
          onClose={(evt)=>this.createtemplate(evt)}
          selectedRow={this.state.selectedRow}
          applicationmenu={this.state.applicationmenu}
          inputChangeHandler={this.inputChangeHandler}
          inputchangehandlerprocess={this.inputchangehandlerprocess}
          inputchangehandlerentity={this.inputchangehandlerentity}
          inputchangehandlerapplication={this.inputchangehandlerappliacation}
          entity={this.state.finalentity}
          process={this.state.finalprocess}
          combodialog={this.state.combodialog}
         


        />:<MessageBoxComponentcombination
        title="Combination"
        open={this.state.showDialogcombination}
        actions={["SAVE", "CANCEL"]}
        
        onClose={(evt)=>this.createtemplate(evt)}
        selectedRow={this.state.selectedRow}
        applicationmenu={this.state.applicationmenu}
        inputChangeHandler={this.inputChangeHandler}
        inputchangehandlerprocess={this.inputchangehandlerprocess}
        inputchangehandlerentity={this.inputchangehandlerentity}
        inputchangehandlerapplication={this.inputchangehandlerappliacation}
        entity={this.state.finalentity}
        process={this.state.finalprocess}
        combodialog={this.state.combodialog}
       


      /> }
         
        
        <Snackbar open={this.state.messageOpen} message={this.state.messageContent} autoHideDuration={3000} onClose={() => {this.setState({...this.state,messageOpen:false})}}>
        <Alert severity="success"
          sx={{ width: '100%' }} 
          style={{backgroundColor:"lightgreen"}} 
          >
  {this.state.messageContent}
  </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withRouter(CwMSEmailtemplate);
