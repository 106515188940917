import logo from './logo.svg';
import React from "react";
import ReactDOM from 'react-dom';
import './App.css';
import CwMSLandingPage from "./CwMSLandingPage";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
require("./scss/application.scss");
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>test 1</h1>
       
//       </header>
//     </div>
//   );
// }

// export default App;
export default class App extends React.Component {
  render() {
    console.log(this.props,"props");
    return (
      <React.Fragment>
        <Router>
          {/* <Switch> */}
            {/* <Route exact path="/"  > */}
              <CwMSLandingPage userid={this.props.userid} {...this.props}></CwMSLandingPage>
            {/* </Route> */}
            {/* <Route exact path="/users" component={Users} />
        <Route path="/roles" component={Roles} /> */}
        {/* <Route path="/rolesAdd" component={RolesAdd} /> */}
          {/* </Switch> */}
        </Router>
      </React.Fragment>
    )
  }
}
