import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@mui/material';
import { Button } from '@mui/material';
import MessageBoxComponentSave from "./CwWfSaveConfirmation";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Paper from "@material-ui/core/Paper";
import { TextField, InputAdornment} from '@material-ui/core';
import TableRow from "@material-ui/core/TableRow";
import Stack from '@mui/material/Stack';
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import { Chip } from '@material-ui/core';

class MapPropertiesMessagetext extends Component {
    constructor(props) {
        super(props);
        this.state={
          application:'',
          mapapp:[],
          mapsave:[],
          showDialogSave:false,
          finalproperties:JSON.parse(JSON.stringify(this.props.properties)),
          properties:JSON.parse(JSON.stringify(this.props.properties)),
        }

    }
    inputchangehandlerapplication=(e,value)=>{
      this.setState
    ({
        ...this.state,
        application:value,
      })

    }
    savethedata=()=>{
      this.setState({
        ...this.state,
    
        showDialogSave: true
    
    })
     };
     onSaveDataElement = async(e) => {
      var tempmessage="Add all the fields" ;
      let toApp=[];
      for(var i=0;i<this.state.mapapp.length;++i)
      {
        toApp.push(this.state.mapapp[i].applicationName)

      }
      let messageDetails=[];
      for(var k=0;k<this.state.mapsave.length;++k)
      {
         messageDetails.push({languageCodes: this.state.mapsave[k].availableLanguageCodes,messageNumber:this.state.mapsave[k].messageNumber,messageType:this.state.mapsave[k].messageType,usageArea:this.state.mapsave[k].usageArea})
      }
      var  payload ={
        fromApplication: this.props.applicationName,
        messageDetails:messageDetails,
        toApplication:toApp
      };
      console.log(this.state.mapsave)
      console.log(payload)
        let method, url;
       
          method = "POST";
          url = "CW_Worktext/message/mapMessages";
        
          let headers= {
            ContentType: "application/json",
            Accept: "application/json",
            cache: false,
            "X-CSRF-Token": "Fetch",
            "Authorization":this.props.authorization,
        };
        const response =   await fetch("/CW_Worktext/application/getApplications", {headers:headers});
        
        const token =  response.headers.get("X-Csrf-Token");
        console.log(token)
        const requestParam1 = {
          method: method,
          headers: { "X-CSRF-Token": token,"Content-Type": "application/json","Authorization":this.props.authorization },
          body: JSON.stringify(payload),
        };
        fetch(url, requestParam1)
          .then((response)=>response.json())
          .then((response) => {
            if(response.statusCode==200)alert('Mapping is done');
            else alert("Error");
          })
          ;
      }
          
          
     closeDialog = (evt) => {    
      if (evt === "SAVE" ) {
          this.onSaveDataElement();
      }
      
    
     
      this.setState(
          {
              ...this.state,            
              showDialogSave: false,  
          });
    
    }
    onChangeSearchQuery=(evt)=>{
      let tempproperties=this.state.finalproperties.filter(app=>app.propertyName.includes(evt.target.value));
     this.setState({
         ...this.state,
         properties:tempproperties,
     })

  }
    changeproperty=(evt,value,index)=>{
      let tempproperties=this.state.properties;
      //let templang=tempproperties[index].availableLanguages.filter(ele=>ele.languageDescription!=value[value.length-1].languageDescription);
     
     
      if(value.length>tempproperties[index].availableLanguages.length)
      {
        tempproperties[index].availableLanguages= tempproperties[index].availableLanguages.filter(ele=>ele.languageDescription!=value[value.length-1].languageDescription)
        tempproperties[index].availableLanguages.push(value[value.length-1])
      }
      else
      {
        tempproperties[index].availableLanguages=value;
      }
      tempproperties[index].availableLanguageDescriptions=[];
      tempproperties[index].availableLanguageCodes=[];
        for(var i=0;i<value.length;++i)
        {
          tempproperties[index].availableLanguageDescriptions.push(value[i].languageDescription);
          tempproperties[index].availableLanguageCodes.push(value[i].languageCode);
       
        }
      
      this.setState({
          ...this.state,
          properties:tempproperties
      })

    
    }
    addapplication=(evt,value)=>{
      this.setState
      ({
        ...this.state,
      mapapp:evt
    })
    }
    getValue=(e)=>{
      let temp=this.state.mapsave;
      if(e.target.checked){
        temp.push(this.state.properties[e.target.value])
      }
      if(!e.target.checked){
        temp=temp.filter(ele=>ele.propertyName!==this.state.properties[e.target.value].propertyName)
      }
      this.setState({
        ...this.state,
        mapsave:temp,
      })
     }
    componentDidMount(){
       
        
      // fetch("CW_Worktext/translation/getPropertiesTranslations?applicationName="+"GLOBAL"+"&elementName=UI&languageCode=EN")
      // .then((res) => res.json())
      // .then((json) => {
      //   this.setState({
      //     ...this.state,
      //     isLoaded: true,
      //    properties: json.data,
        

      //   });
      // });
    }

    render() {
      const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
     
        return (
          <div>
          <div style={{height:'2rem'}}>

<IconButton onClick ={()=>{this.props.showmapprops()}}><KeyboardArrowLeftIcon /></IconButton>
 {/* <TextField
             
             variant='outlined'
             style={{ width:'20rem',background: '#ffffff' }}
             placeholder="Search for Properties..."
           //   value={searchQuery}
             className="styleSearch"
             onChange={(evt)=>this.props.onChangeSearchQuery(evt)}
           //   onKeyDown={onChangeSearchQuery}
             InputProps={{
               endAdornment: (
                 <InputAdornment position="end">
                   <IconButton
                     aria-label="toggle password visibility"
                     
                   >
                     <SearchIcon />
                   
                   </IconButton>
                  
                 </InputAdornment>
               )
             }} /> */}
From Application:  <TextField size='small' style={{marginLeft:'3rem',height:'2rem'}}variant='outlined' value={this.props.applicationName}/>
To Application :   
  <Autocomplete
     id="multiple-limit-tags"
     multiple
      value={this.state.mapapp}
      onChange={(event, newValue) => 
       this.addapplication(newValue)
      }
     limitTags={2}
   
    style={{marginLeft:'35rem',marginTop:'-2.8rem',width:'20rem'}}
    options={this.props.application}
    getOptionLabel={(option) => option.applicationName}
    filterSelectedOptions
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
       
       
      />
    )}
  />
   <TextField
             
             variant='outlined'
             style={{ width:'20rem',background: '#ffffff',marginLeft:'60rem',marginTop:'-2.3rem' }}
             placeholder="Search for Properties..."
           //   value={searchQuery}
             className="styleSearch"
             onChange={(evt)=>this.onChangeSearchQuery(evt)}
           //   onKeyDown={onChangeSearchQuery}
             InputProps={{
               endAdornment: (
                 <InputAdornment position="end">
                   <IconButton
                     aria-label="toggle password visibility"
                     
                   >
                     <SearchIcon />
                   
                   </IconButton>
                  
                 </InputAdornment>
               )
             }} />
  {/* <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={this.props.application}
 value={this.state.application}
  
onChange={(e,value) => this.inputchangehandlerapplication(e,value)}
  renderInput={(params) => <TextField {...params} label="Application" />}
/> */}
</div>
<TableContainer
          component={Paper}
          style={{ margin: "0.5rem", width: "99%" }}
        >
<Table
              size="small"
              aria-label="a dense table"
              name="fieldsTable"
            >
              <TableHead>
                <TableRow>
                <TableCell width="5%" style={{ fontWeight: 700 }}>
                <Checkbox color="primary" value="header" onChange={(e)=>this.getValue(e)} />
                  </TableCell>
                  <TableCell width="15%" style={{ fontWeight: 700 }}>
                    
                    Usage Area
                  </TableCell>
                  <TableCell width="15%" style={{ fontWeight: 700 }}>
                   
                 MessageType
                  </TableCell>
                  <TableCell width="15%" style={{ fontWeight: 700 }}>
                   
                 Message No
                  </TableCell>
                  
                 
                  <TableCell width="15%" style={{ fontWeight: 700 }}>
                    Available Translation
                  </TableCell>
                  <TableCell width="15%" style={{ fontWeight: 700 }}>
                   
                  Language
                  </TableCell>

                  <TableCell width="15%" style={{ fontWeight: 700 }}>

                   </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
               
              {this.state.properties.map((option,index) => (
                 
                <TableRow key={option.id}>
                  {/* <TableCell >{row.application}</TableCell> */}

                  <TableCell>  <Checkbox color="primary" value={index} onChange={(e)=>this.getValue(e)} /></TableCell>
                  <TableCell>{option.usageArea}</TableCell>
                  
                  <TableCell>{option.messageType}</TableCell>
                  <TableCell>{option.messageNumber}</TableCell>
                 
                  <TableCell>{option.availableTranslations}</TableCell>
                  {/* <TableCell>{this.props.properties[index].availableLanguages.map((option1)=>(<Chip label={option1.languageDescription}/>))}</TableCell> */}

                  <TableCell > <Autocomplete
   
    id="tags-standard"
    multiple
    limitTags={2}
    onChange={(evt,newValue) => 
      this.changeproperty(evt,newValue,index)
     }
     value={this.state.properties[index].availableLanguages}
    style={{width:'20rem'}}
    options={this.props.properties[index].availableLanguages}
    getOptionLabel={(option1) => option1.languageDescription}
   
    filterSelectedOptions
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
       
       
      />
    )}
  /></TableCell>

                 <TableCell> <IconButton
                      aria-label="Edit"
                      style={{ color: "green" }}
                      onClick ={()=>{this.props.showmapprops()}}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton></TableCell>
         
                </TableRow>
              ))}
            </TableBody>
             </Table></TableContainer>
             <div className="footerdiv" style={{position:"fixed", right:"10px" ,bottom:"10px"}}>
                    <Button
                      style={{ margin: "0 0.5rem" }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick ={()=>{this.props.showmapprops()}}
                    >
                      Cancel
                    </Button>
                    <Button
                     style={{ margin: "0 0.5rem" }}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => this.savethedata()}
                    >
                      Save
                    </Button>
                    
                    
                  </div>
                  <MessageBoxComponentSave
                  title="save"
                  open={this.state.showDialogSave}
                  actions={["SAVE", "CANCEL"]}
                  onClose={(evt)=>this.closeDialog(evt)}
                />
        </div>
        );
    }
}



export default MapPropertiesMessagetext;