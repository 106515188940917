import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@mui/material/TextField";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
// import UsersAdd from './CwWfUsersAdd';
import { Row } from "react-bootstrap";
//import {useHistory} from 'react-router-dom';
const MessageBoxComponentcombination = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      //onClose={() => props.onClose('CANCEL')}
    >
      <DialogTitle
        style={{
          height: "3rem",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #d9d9d9",
        }}
      >
        {props.combodialog}
      </DialogTitle>
      <DialogContent style={{ minWidth: "20rem", minHeight: "4rem" }}>
        <DialogContentText style={{}}>
          {props.applicationName ? (
            <TextField
              label="Application"
              value={props.applicationName}
              disabled

            />
          ) : (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
             
              options={props.applicationmenu}
              value={props.selectedRow.application}
              sx={{ width: 222, marginTop: 2 }}
              onChange={(e, value) =>
                props.inputchangehandlerapplication(e, value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Application" />
              )}
            />
          )}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.entity}
            value={props.selectedRow.entityDesc}
            sx={{ width: 222, marginTop: 2 }}
            onChange={(e, value) => props.inputchangehandlerentity(e, value)}
            renderInput={(params) => <TextField {...params} label="Entity" />}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.process}
            value={props.selectedRow.processDesc}
            sx={{ width: 222, marginTop: 2 }}
            onChange={(e, value) => props.inputchangehandlerprocess(e, value)}
            renderInput={(params) => <TextField {...params} label="Process" />}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ height: "3rem", borderTop: "1px solid #d9d9d9" }}>
        {/* {props.actions.map(action => <Button key={action} variant="contained" size="small" onClick={() => props.handleClose(action)}>{action}</Button>)} */}
        <Button
          key={"CANCEL"}
          variant="contained"
          size="small"
          onClick={() => {
            props.onClose("CANCEL");
          }}
        >
          CANCEL
        </Button>
        <Button
          key={"SAVE"}
          variant="contained"
          size="small"
          onClick={() => props.onClose("SAVE")}
          color="secondary"
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withRouter(MessageBoxComponentcombination);
