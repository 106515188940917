import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
//import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@mui/material/Autocomplete';
import InputBase from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import ValueHelpDialog from './valueHelpDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

class Composite extends React.Component {

    constructor(props) {
        
        
        super(props);
        this.valuehelp = React.createRef();
        let emptyRow = this.emptyRow();
        
        this.state = {
            // value: "DataElement",
            // tableVisible: true,
            snackbarOpen: false,
            snackbarMessg: "",
            dataElement: [],
            showValueHelpDialog: false,
            showVHTableDialog: false,
            selectedRow: props.selectedRow,
            constraints1: [],
            busyIndicator: true,
            fields: props.fields?props.fields:[],
            sheets: [],
            apiType: [{
                name: "REST"
            }, {
                name: "ODATA"
            }
            ],
            constraintType: [{
                name: "PATH VARIABLE"
            }, {
                name: "QUERY PARAMETER"
            }, {
                name: "HEADER PARAMETER"
            }
            ],
            fileTypeDropDown: [{
                name: ".XLSX"
            }, {
                name: ".CSV"
            }, {
                name: ".XML"
            }
            ],
            valueHelpType: [{
                name: "Static"
            }, {
                name: "API"
            }, {
                name: "Database"
            }, {
                name: "File"
            }
            ],
            apiMetadata: {
                "url": "",
                "destination": "",
                "apiType": "",
                "resultPath": null,
                "constraints": null,

            },
            dbMetadata: {},
            fileMetadata: {
                fileType: null,
                properties: {
                    "parentTag": null,
                    "sheets": []
                }
            },
            valueList: props.valueList ? props.valueList : [],
            destinations:[]
        };
        console.log(this.state.valueList,"valueList");
    }
    
    componentWillReceiveProps(props) {
        
        let fields;
        if (this.props.lookupType !== "VL") {

            if (this.state.fields.length === 0) {
                fields = [
                    {
                        "columnName": this.props.selectedRow.name,
                        "displayName": this.props.selectedRow.label,
                        "id": null,
                        "isDisplayName": true,
                        "mappedName": null,
                        "searchable": true
                    }
                ]


            } else {
                fields = this.state.fields;
                //fields[0].columnName = this.state.selectedRow.name
                //fields[0].displayName = this.state.selectedRow.label

            }
            this.setState({
                ...this.state,
                fields: fields,
            })
        }
        this.setState({
            ...this.state,
            
            valueList: props.valueList? props.valueList :[],
            busyIndicator: false,
            constraints1: props.constraints?props.constraints:[],
            fields:  props.fields?props.fields:[],
            apiMetadata : props.apiMetadata1? props.apiMetadata1:[],
            fileMetadata : props.fileMetadata?props.fileMetadata:[],
            sheets: props.sheets?props.sheets:[],
            dbMetadata: props.dbMetaData?props.dbMetaData:[]

        })
        // this.setState({ open: props.drawerOpen })
      }
    componentDidUpdate(prevProps) {
        let fields, destination;
        // if (this.props.lookupType !== "VL") {

        //     if (this.state.fields.length === 0) {
        //         fields = [
        //             {
        //                 "columnName": this.props.selectedRow.name,
        //                 "displayName": this.props.selectedRow.label,
        //                 "id": null,
        //                 "isDisplayName": true,
        //                 "mappedName": null,
        //                 "searchable": true
        //             }
        //         ]


        //     } else {
        //         fields = this.state.fields;
        //         fields[0].columnName = this.state.selectedRow.name
        //         fields[0].displayName = this.state.selectedRow.label

        //     }
        //     // this.setState({
        //     //     ...this.state,
        //     //     fields: fields
        //     // })
        // }
        // if (this.props.lookupType === "API" || this.props.lookupType === "DB") {
        //     let url = "workbox-field-catalog/v1/lapi/destination"
        //     fetch(url)
        //         .then((res) => res.json())
        //         .then((result) => {
        //             destination = result.data ? result.data : [];
        //             this.setState({
        //                 ...this.state, destinations: result.data ? result.data : []
        //                 // fields: fields
        //             });

        //         })
        // }

    }
    addNewConstraint = (event) => {
        let constraints = [...this.state.constraints1];
        let obj = {

            "constraintColumn": null,
            "constraintName": null,
            "constraintOperator": "EQ",
            "constraintType": null,
            "constraintValue": null,
            "id": null,
            "mappedName": null
        };

        constraints.push(obj);
        this.setState({
            ...this.state,
            constraints1: constraints,
        });
    };
    addNewFields = (event) => {
        let fields = [...this.state.fields];
        let obj = {
            "id": "",
            "columnName": "",
            "displayName": "",
            "searchable": true,
            "isDisplayName": false,
            "mappedName": "",
            "tempId": fields.length - 1
        };

        fields.push(obj);
        this.setState({
            ...this.state,
            fields: fields,
        });
    };
    deleteFieldRows = (row, index) => {

        let fields = [...this.state.fields];
        //  let i;
        // if (row) {
        //     fields.filter(function (element, index) {
        //         if (element.id === row) {
        //             i = index;
        //         }
        //     });
        // }else{
        //     fields.filter(function (element, index) {
        //         if (element.tempId === tempId) {
        //             i = index;
        //         }
        //     });
        // }
        fields.splice(index, 1);
        this.setState({
            ...this.state,
            fields: fields,
        });

    }
    deleteConstraintRows = (row, index) => {
        let constraints = [...this.state.constraints1];




        constraints.splice(index, 1);
        this.setState({
            ...this.state,
            constraints1: constraints,
        });

    }
    addNewStaticVL = (event) => {
        let staticVL = [...this.state.valueList];
        let obj = {
            key: "",
            value: "",
            additionalValue: "",

        };

        staticVL.push(obj);
        this.setState({
            ...this.state,
            valueList: staticVL,
        });
    };
    deleteStaticVL = (row, index) => {
        let fields = [...this.state.valueList];
        fields.splice(index, 1);
        this.setState({
            ...this.state,
            valueList: fields,
        });

    }
    sheetsInputs = (evt, index) => {
        let value, name;
        name = evt.target.name
        value = evt.target.value;
        let sheets = [...this.state.sheets];
        if (evt.target.name === "sheetIndex") {
            if (parseInt(value)) {
                name = "sheetIndex";
                sheets[index].sheetName = null;
            } else {
                name = "sheetName";
                sheets[index].sheetIndex = null;
            }
        }
        sheets[index][name] = value;
        this.setState({
            ...this.state,
            sheets: sheets
        })
    }
    deleteSheet = (evt, index) => {
        let sheets = [...this.state.sheets];

        sheets.splice(index, 1);
        this.setState({
            ...this.state,
            sheets: sheets,
        });
    }
    addSheets = (event) => {
        var sheet = this.state.sheets;
        let obj = {
            "sheetName": null,
            "sheetIndex": null,
            "rowStart": null,
            "columnStart": null
        }
        sheet.push(obj);
        this.setState({
            ...this.state,

            sheets: sheet

        })
    }
    valueHelpTableInputs = (evt, index) => {
        
        let value;
        if (evt.target.name !== "isDisplayName" && evt.target.name !== "searchable") {
            value = evt.target.value;
        } else {
            value = evt.target.checked;
        }
        if (this.props.lookupType === "API" || this.props.lookupType === "FILE" || this.props.lookupType === "COMPOSITE" ) {
            if (evt.target.id === "fieldsTable") {
                let fields = [...this.state.fields];
                if (evt.target.name == "isDisplayName") {
                    fields.filter(function (element) {
                        element.isDisplayName = false;
                    });
                }

                fields[index][evt.target.name] = value;
                this.setState({
                    ...this.state,
                    fields: fields
                })
            } else if (evt.target.id === "constraintTable") {
                let constraints = [...this.state.constraints1];

                constraints[index][evt.target.name] = value;
                if (evt.target.name === "constraintColumn" && evt.target.row) {
                    constraints[index]["constraintName"] = evt.target.row.label
                }
                this.setState({
                    ...this.state,
                    constraints1: constraints,
                    // showVHTableDialog: false
                })
            }
        } 
        // else if (this.props.lookupType === "DB") {

        // } 
        else if (this.props.lookupType === "VL") {
            let valueList = [...this.state.valueList];
            valueList[index][evt.target.name] = value;
            this.setState({
                ...this.state,
                valueList: valueList
            })

        }

    }
    emptyRow = () => {

        const selectedRow = {
            attributeId: "",
            createdBy: "",
            createdOn: null,
            dataType: "",
            description: "",
            destinations: null,
            isLookup: true,
            label: "",
            length: "",
            lookUpId: "",
            lookUpType: "",
            lookupConfig: null,
            name: "",
            source: "",
            updatedBy: "",
            updatedOn: null,
            propertyDto: {
                isMandatory: true,
                isVisible: true,
                isNullable: true,
                defaultValue: "abc",
                isFilterable: true,
                isSortable: true,
                isEditable: true
            }
        };
        return selectedRow;
    };
    createNewField = (event, newValue) => {
        this.setState({
            ...this.state,
            tableVisible: false,
        });
    };
    handleSubmit = (evt) => {
        let a = evt;
    }
    editFields = (row) => {
        this.setState({
            ...this.state,
            tableVisible: false,
            selectedRow: row
        });
    };


    onValueHelpType = (event) => {
        let value = event.target.value;
        this.props.lookupType = value;
    };




    getAttributeList = (appId) => {
        let url;

        url = "workbox-field-catalog/v1/attribute-master?applicationId=" + appId
        fetch(url,{headers:{"Authorization":this.props.authorization}})
            .then((res) => res.json())
            .then((result) => {

                // this.setState({
                //     ...this.state,
                //     tempArr: result.output
                // });

                this.setState({
                    ...this.state,
                    selectedApplication: appId,
                    fieldCatalogArray: result.data ? result.data : []
                });

            })
    }
    closeDialog = (action) => {
        if (action === "DISCARD") {
            //   this.discardMappingRoles();
        }
        else if (action === "SUBMIT") {
            //   this.onSaveMappingRoles();
        }
        else if (action === "SAVE") {
            //   this.onSaveMappingRolesDraft();
            this.fnLookupsave();
        }
        this.props.onClose("a");
        // this.setState(
        //     {
        //         ...this.state,
        //         showVHTableDialog: false,
        //     });

    }
    // inputChangeHandler1=()=>{
    //     this.props.closeDialog("a");
    // }
    inputChangeHandler1 = (evt, val) => {
        
        let value = evt.target.value, key, name;
        let fileTypeProp;
        name = evt.target.name;
        if (val) {
            value = val.name;
            name = evt.target.id.split("-")[0];
        }
        if (this.props.lookupType === "API") {
            key = "apiMetadata";
        } else if (this.props.lookupType === "DB") {
            key = "dbMetadata";
        } else if (this.props.lookupType === "FILE") {
            key = "fileMetadata";
            if(this.state.fileMetadata.properties){
            fileTypeProp = {
                "parentTag": this.state.fileMetadata.properties.parentTag,
                "sheets": this.state.sheets
            };
        }
            if (name === "parentTag") {
                fileTypeProp.parentTag = value;
            }


        }
        if (this.props.lookupType !== "FILE") {
            this.setState({
                ...this.state,
                [key]: {
                    ...this.state[key],

                    [name]: value

                }
            })
        } else {
            this.setState({
                ...this.state,
                [key]: {
                    ...this.state[key],
                    [name]: value,
                    properties: fileTypeProp

                }
            })
        }
    }
    inputChangeHandler2 = (evt, val) => {
        
        let value = val.lookupTableId;
        let fileTypeProp;
       
            this.setState({
                ...this.state,
                
                dbMetadata: {
                    ...this.state.dbMetadata,

                    lookupTable: value

                }
            })
       
    }
    fnLookupsave = (data) => {
        
        let method, lookupType = this.props.lookupType, valueList, oPayload, sUrl;
        // if (this.state.lookupId) {
        //     method = "PATCH"
        //     sUrl = "workbox-field-catalog/v1/lapi/" + this.state.lookupId+ "?objectId=" + data.attributeId + "&objectType=CL" ;
        // } else {
        //     method = "POST";
        //     sUrl = "workbox-field-catalog/v1/lapi" + "?objectId=" + data.attributeId + "&objectType=CL";
        // }
        if (lookupType === "VL") {
            oPayload = {
                "lookupId": this.props.lookupId,
                "lookupName": this.props.name+"_CL_"+this.props.compositeRow.value,
                "lookupType": lookupType,
                "valueList": this.state.valueList
            }
        } else if (lookupType === "API") {

            oPayload = {
                "lookupId": this.props.lookupId,
                "lookupName": this.props.name+"_CL_"+this.props.compositeRow.value,
                "lookupType": lookupType,
                "apiMetadata": {
                    "apiType": this.state.apiMetadata.apiType,
                    "constraints": this.state.constraints1,
                    "destination": this.state.apiMetadata.destination,
                    "fields": this.state.fields,
                    "pagination": {
                        "allowed": true,
                        "recordsPerPage": "30"
                    },
                    "resultPath": this.state.apiMetadata.resultPath,
                    "url": this.state.apiMetadata.url
                },

            }
        } else if (lookupType === "DB") {
            oPayload = {
                "lookupId": this.props.lookupId,
                "lookupName": this.props.name+"_CL_"+this.props.compositeRow.value,
                "lookupType": lookupType,
                "dbMetadata": {
                    "constraints": this.state.constraints1,
                    "databasePropertiesDto": {
                        "dbType": "string",
                        "lookupId": "string",
                        "password": "string",
                        "url": "string",
                        "userName": "string"
                    },
                    "destination": this.state.dbMetadata.destination,
                    "fields": this.state.fields,
                    "lookupTable": this.state.dbMetadata.lookupTable,
                    "pagination": {
                        "allowed": true,
                        "recordsPerPage": "30"
                    }
                },

            }
        } else if (lookupType === "FILE") {
            let sheets = [...this.state.sheets]
            //let sheets = [...this.state.sheets]
            if (this.state.sheets.length !== 0) {
                sheets.filter(function (element, i) {
                    if (element.columnStart) {
                        element.columnStart = element.columnStart.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1;
                    }
                })
            }
            oPayload = {
                "lookupId": this.props.lookupId,
                "lookupName": this.props.name+"_CL_"+this.props.compositeRow.value,
                "lookupType": lookupType,
                "fileMetadata": {
                    "constraints": this.state.constraints1,
                    "fields": this.state.fields,
                    
                    "fileType": this.state.fileMetadata.fileType.toUpperCase(),
                    "lookupId": this.props.lookupId,
                    "properties": {
                        "parentTag": this.state.fileMetadata.properties.parentTag,
                        "sheets": this.state.sheets
                    }
                },
            }
        }
        const requestParam1 = {
            method: method,
            headers: { "Content-Type": "application/json","Authorization":this.props.authorization},
            body: JSON.stringify(oPayload),
        }
        this.props.getData1(oPayload);
        // fetch(sUrl, requestParam1)
        //     .then((response) => response.json())
        //     .then((data, message) => {
        //         console.log(data);
        //         this.props.getData1(data);
        //         this.setState({
        //             ...this.state,
        //             snackbarOpen: true,
        //             snackbarMessg: 'Lookup Saved!'

        //         })
        //     }
        //     )

    }
    rowSelected = (row) => {
        
        if (this.state.tableType === "constraintTable") {
            let evt = {
                target: {
                    row: row,
                    value: row.name,
                    id: "constraintTable",
                    name: "constraintColumn"
                }
            }
            // this.setState({
            //     ...this.state,
            //     selectedField: row.name,
            //     attributeLapiDataInput: row.metaData.isLookup
            // })
            this.valueHelpTableInputs(evt, this.state.index)
        } else if(this.state.tableType === "fieldsTable") {
            let evt = {
                target: {
                    row: row,
                    value: row.name,
                    id: "fieldsTable",
                    name: "columnName"
                }
            }
            // this.setState({
            //     ...this.state,
            //     selectedField: row.name,
            //     attributeLapiDataInput: row.metaData.isLookup
            // })
            this.valueHelpTableInputs(evt, this.state.index)


        }

        if (this.state.lookupType === "COMPOSITE" && row.metaData.isLookup) {
            this.getLapiData(row.metaData);
        }

    }
    valueHelpTableInputs = (evt, index) => {
        
        let value;
        if (evt.target.name !== "isDisplayName" && evt.target.name !== "searchable") {
            value = evt.target.value;
        } else {
            value = evt.target.checked;
        }
        if (this.props.lookupType === "API" || this.props.lookupType === "FILE" ||this.props.lookupType === "DB" ) {
            if (evt.target.id === "fieldsTable") {
                let fields = [...this.state.fields];

                if (evt.target.name == "isDisplayName") {
                    fields.filter(function (element) {
                        element.isDisplayName = false;
                    });
                }

                fields[index][evt.target.name] = value;
                if (evt.target.name === "columnName" && evt.target.row) {
                    fields[index]["displayName"] = evt.target.row.label
                }
                this.setState({
                    ...this.state,
                    fields: fields,
                    showVHTableDialog: false
                })
            } else if (evt.target.id === "constraintTable") {
                let constraints = [...this.state.constraints1];

                constraints[index][evt.target.name] = value;
                if (evt.target.name === "constraintColumn" && evt.target.row) {
                    constraints[index]["constraintName"] = evt.target.row.label
                }
                this.setState({
                    ...this.state,
                    constraints1: constraints,
                    showVHTableDialog: false
                })
            }
        } 
        // else if (this.props.lookupType === "DB") {

        // } 
        else if (this.props.lookupType === "VL") {
            let valueList = [...this.state.valueList];
            valueList[index][evt.target.name] = value;
            this.setState({
                ...this.state,
                valueList: valueList
            })

        }

    }
    fnAttrValueHelp = (index, evt, table) => {

        this.setState({
            ...this.state,
            showVHTableDialog: true,
            index: index,
            tableType: table

        });

    }
    render() {

        // const userAccess = this.props.userAccess;
        // const modellingEntity = userAccess.entities.find(ele => ele.name === "Modelling") ? true : false;
        // const userFormsEntity = userAccess.entities.find(ele => ele.name === "UserForms") ? true : false;
        // const configurationEntity = userAccess.entities.find(ele => ele.name === "Configuration") ? true : false;
        // const rulesEntity = userAccess.entities.find(ele => ele.name === "Rules") ? true : false;

        return (
            <div>
        {/* {this.state.busyIndicator ? <div style={{ display: "flex", float: "center", paddingBottom: 20, marginLeft: 500, marginTop: 200 }}><CircularProgress /></div> : */}
        <Dialog
            fullScreen={false}
            open={this.props.open}
class="dialogClass"
        //onClose={() => props.onClose('CANCEL')}
        >
            <DialogTitle style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #d9d9d9' }}>Configure Value Help</DialogTitle>
            <DialogContent >
                <DialogContentText style={{ width: "95%" }}>




                    {(this.props.lookupType === "API" || this.props.lookupType === "DB") && <div>

                        <Grid container spacing={3} hSpacing="2rem"
                            style={{ margin: "1rem" }}
                        >

                            <Grid item xs={12} sm={3} md={3} hidden={this.props.lookupType === "DB"}>


                                <Autocomplete
                                    style={{ width: 220 }}
                                    name="destination"
                                    id="destination"
                                    // value={this.state.apiMetadata.destination}
                                    inputValue={this.state.apiMetadata.destination}
                                    options={this.props.destinations}
                                    getOptionLabel={(option) => option.name}
                                    // renderOption={(option) => option.name}
                                    onChange={(evt, val) => this.inputChangeHandler1(evt, val)}
                                    renderInput={(params) => <TextField {...params} variant="outlined"
                                        value={this.state.apiMetadata.destination}
                                        label={this.props.translation.DESTINATION.longDescription}
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} hidden={this.props.lookupType === "API"}>


                                <Autocomplete
                                    style={{ width: 220 }}
                                    name="destination"
                                    id="destination"
                                    // value={this.state.apiMetadata.destination}
                                    inputValue={this.state.dbMetadata.destination}
                                    options={this.props.destinations}
                                    getOptionLabel={(option) => option.name}
                                    // renderOption={(option) => option.name}
                                    onChange={(evt, val) => this.inputChangeHandler1(evt, val)}
                                    renderInput={(params) => <TextField {...params} variant="outlined"
                                        value={this.state.dbMetadata.destination}
                                        label={this.props.translation.DESTINATION.longDescription}
                                    />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} hidden={this.props.lookupType !== "DB"}>
                            <Autocomplete
                                style={{ width: 220 }}
                                name="lookupTable"
                                id="lookupTable"
                                required
                                // value={this.props.parentState.apiMetadata.destination}
                                inputValue={this.state.dbMetadata.lookupTable}
                                options={this.props.dataTable}
                                getOptionLabel={(option) => option.lookupTableName}
                                // renderOption={(option) => option.name}
                                onChange={(evt, val) => this.inputChangeHandler2(evt, val)}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                    value={this.state.dbMetadata.lookupTable}
                                    label={this.props.translation.DATA_TABLE.longDescription}
                                />}
                            />
                                {/* <TextField
                                    required
                                    // hidden={this.props.parentState.lookupType !== "DB"}
                                    id="filled-disabled"
                                    label={this.props.translation.DATA_TABLE.longDescription}
                                    name="lookupTable"
                                    value={this.state.dbMetadata.lookupTable}
                                    variant="outlined"
                                    //style={{ marginLeft: 30 }}
                                    size="small"
                                    onChange={(evt) => this.inputChangeHandler1(evt)}
                                // className="customInput2" 
                                /> */}
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} hidden={this.props.lookupType === "DB"}>
                                <TextField
                                    required
                                    id="filled-disabled"
                                    label={this.props.translation.VALUE_HELP_URL.longDescription}
                                    name="url"
                                    value={this.state.apiMetadata.url}
                                    variant="outlined"
                                    //style={{ marginLeft: 30 }}
                                    size="small"
                                    onChange={(evt) => this.inputChangeHandler1(evt)}
                                // className="customInput2" 
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} hidden={this.props.lookupType === "DB"}>

                                <FormControl
                                    style={{ width: 220 }}
                                    variant="outlined" size="small">
                                    <InputLabel id="demo-simple-select-outlined-label" required>{this.props.translation.API_TYPE.longDescription}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.apiMetadata.apiType}
                                        // size="small"
                                        name="apiType"
                                        style={{ width: "100%" }}
                                        onChange={(evt) => this.inputChangeHandler1(evt)}
                                    // hidden={true}
                                    >
                                        {this.state.apiType.map(option =>
                                            <MenuItem value={option.name} key={option.name}
                                            // onClick={() =>
                                            //     this.applicationChangeHandler(option.id, index)

                                            // }
                                            // style={{ width: 30 }}
                                            >{option.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} hidden={this.props.lookupType === "DB"}>
                                <TextField

                                    id="filled-disabled"
                                    label={this.props.translation.RESPONSE_PATH.longDescription}
                                    name="resultPath"
                                    value={this.state.apiMetadata.resultPath}
                                    variant="outlined"
                                    //style={{ marginLeft: 30 }}
                                    size="small"
                                    onChange={(evt) => this.inputChangeHandler1(evt)}
                                // className="customInput2" 
                                />
                            </Grid>
                        </Grid>
                        <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => this.addNewConstraint()}
                            //className={classes.buttonAdd}
                            >
                                Add
                            </Button>
                        </span>
                        <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                            <Table size="small" aria-label="a dense table" name="constraintTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT_TYPE.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.OPERATOR.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.VALUE.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.constraints1.map((row, index) => {
                                        return (


                                            <TableRow key={row.id}>

                                                {/* <TableCell >{row.application}</TableCell> */}


                                                <TableCell >

                                                    <FormControl
                                                        // style={{ width: 220 }}
                                                        variant="outlined" size="small">
                                                       <InputBase
                                                       name="constraintColumn"
                                                       id="constraintTable"
                                                       value={row.constraintColumn}
                                                       variant="outlined"
                                                       // disabled
                                                       style={{ width: "10rem"}}
                                                       InputProps={{
                                                           readOnly: true,
                                                       }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={(evt) => this.fnAttrValueHelp(index, evt, "constraintTable")}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        edge="end" >
                                                                        <SvgIcon >
                                                                            <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                        </SvgIcon >
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }>
                                                        </InputBase>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="constraintTable"
                                                    disabled
                                                    name="constraintName"
                                                    value={row.constraintName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="constraintTable"

                                                    name="mappedName"
                                                    value={row.mappedName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>

                                                {/* <TableCell >{row.permissionType}</TableCell> */}
                                                <TableCell><TextField
                                                    required
                                                    id="constraintTable"

                                                    name="constraintType"
                                                    value={row.constraintType}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="constraintTable"
                                                    disabled
                                                    name="constraintOperator"
                                                    value={row.constraintOperator}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="constraintTable"

                                                    name="constraintValue"
                                                    value={row.constraintValue}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell >
                                                    {/* <Tooltip title= 'Edit'> */}
                                                    <IconButton aria-label="Edit" style={{ color: "red" }}
                                                        onClick={() => this.deleteConstraintRows(row.id, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    {/* </Tooltip> */}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => this.addNewFields()}
                            //className={classes.buttonAdd}
                            >
                                Add
                            </Button>
                        </span>
                        <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                            <Table size="small" aria-label="a dense table" name="fieldsTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.FIELDS.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.SEARCHABLE.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.UI_DISPLAY_NAME.longDescription}</TableCell>

                                        <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.fields.map((row, index) => {
                                        return (


                                            <TableRow key={row.id}>

                                                {/* <TableCell >{row.application}</TableCell> */}


                                                <TableCell >
                                                    <FormControl
                                                        // style={{ width: 220 }}
                                                        variant="outlined" size="small">

                                                        <InputBase
                                                            name="columnName"
                                                            id="fieldsTable"
                                                            value={row.columnName}
                                                            variant="outlined"
                                                            // disabled
                                                            style={{ width: "10rem"}}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={(evt) => this.fnAttrValueHelp(index, evt, "fieldsTable")}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        edge="end" >
                                                                        <SvgIcon >
                                                                            <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                        </SvgIcon >
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }>
                                                        </InputBase>
                                                    </FormControl>
                                                </TableCell >
                                                <TableCell ><TextField
                                                    required
                                                    id="fieldsTable"

                                                    name="displayName"
                                                    value={row.displayName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>

                                                {/* <TableCell >{row.permissionType}</TableCell> */}
                                                <TableCell><TextField
                                                    required
                                                    id="fieldsTable"

                                                    name="mappedName"
                                                    value={row.mappedName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell><FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} >
                                                    {/* <InputLabel id="demo-simple-select-outlined-label" ></InputLabel> */}
                                                    <FormGroup aria-label="position" row >

                                                        <FormControlLabel
                                                            required
                                                            // id="fieldTable"
                                                            size="small"
                                                            value={row.searchable}
                                                            //checked={this.state.obj.active}
                                                            name="searchable"
                                                            onChange={(evt) => this.props.valueHelpTableInputs(evt, index)}
                                                            //value={row.active}
                                                            labelPlacement="start"
                                                            control={<Switch color="primary"
                                                                checked={row.searchable}
                                                                id="fieldsTable" />}


                                                        />


                                                    </FormGroup>
                                                </FormControl ></TableCell>
                                                <TableCell>
                                                    <RadioGroup row

                                                        style={{ marginLeft: "1rem" }}

                                                        name="isDisplayName"
                                                    >
                                                        <Radio id="fieldsTable"
                                                            checked={row.isDisplayName}

                                                            onChange={(evt) => this.props.valueHelpTableInputs(evt, index)} />
                                                    </RadioGroup>

                                                </TableCell>
                                                <TableCell >
                                                    {/* <Tooltip title= 'Edit'> */}
                                                    <IconButton aria-label="Edit" style={{ color: "red" }}
                                                        onClick={() => this.deleteFieldRows(row.id, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    {/* </Tooltip> */}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>



                    </div>}
                    {(this.props.lookupType === "VL") && <div>

                        <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => this.addNewStaticVL()}
                            //className={classes.buttonAdd}
                            >
                                Add
                            </Button>
                        </span>
                        <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.KEY.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.VALUE.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.ADDITIONAL_VALUE.longDescription}</TableCell>

                                        <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.valueList.map((row, index) => {
                                        return (


                                            <TableRow key={row.id}>

                                                {/* <TableCell >{row.application}</TableCell> */}


                                                <TableCell >
                                                    {/* <FormControl
                                                style={{ width: 220 }}
                                                variant="outlined" size="small">

                                                <Select
                                                    id="demo-simple-select-outlined"
                                                    value={row.label}
                                                    // size="small"
                                                    name="dataElement"
                                                    style={{ width: "100%" }}
                                                    onChange={(evt) => this.inputChangeHandler(evt)}
                                                >

                                                </Select>
                                            </FormControl> */}
                                                    <TextField
                                                        required
                                                        id="filled-disabled"

                                                        name="key"
                                                        value={row.key}
                                                        variant="outlined"
                                                        //style={{ marginLeft: 30 }}
                                                        size="small"
                                                        onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                    // className="customInput2" 
                                                    />
                                                </TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="filled-disabled"

                                                    name="value"
                                                    value={row.value}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="filled-disabled"

                                                    name="additionalValue"
                                                    value={row.additionalValue}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>

                                                {/* <TableCell >{row.permissionType}</TableCell> */}
                                                <TableCell >
                                                    {/* <Tooltip title= 'Edit'> */}
                                                    <IconButton aria-label="Edit" style={{ color: "red" }}
                                                        onClick={() => this.deleteStaticVL(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    {/* </Tooltip> */}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>



                    </div>}
                    {(this.props.lookupType === "FILE") && <div>

                        <Grid container spacing={3} hSpacing="2rem"
                            style={{ margin: "1rem" }}
                        >

                            <Grid item xs={12} sm={3} md={3}>

                                <FormControl
                                    style={{ width: 220 }}
                                    variant="outlined" size="small">
                                   <InputLabel id="demo-simple-select-outlined-label" required>{this.props.translation.FILE_TYPE.longDescription}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label={this.props.translation.FILE_TYPE.longDescription}
                                        value={this.state.fileMetadata.fileType}
                                        //value={this.props.parentState.fileMetadata.fileType}
                                        // size="small"
                                        name="fileType"
                                        style={{ width: "100%" }}
                                        onChange={(evt) => this.inputChangeHandler1(evt)}
                                    // hidden={true}
                                    >
                                        {this.state.fileTypeDropDown.map(option =>
                                            <MenuItem value={option.name} key={option.name}
                                            // onClick={() =>
                                            //     this.applicationChangeHandler(option.id, index)

                                            // }
                                            // style={{ width: 30 }}
                                            >{option.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} hidden={this.state.fileMetadata.fileType !== ".XML"}>
                                <TextField
                                    required
                                    // hidden={this.props.parentState.fileMetadata.fileType !== ".xml"}
                                    id="filled-disabled"
                                    label="ParentTag"
                                    name="parentTag"
                                    //value={this.state.fileMetadata.properties.parentTag?this.state.fileMetadata.properties.parentTag:""}
                                    variant="outlined"
                                    //style={{ marginLeft: 30 }}
                                    size="small"
                                    onChange={(evt) => this.props.inputChangeHandler1(evt)}
                                // className="customInput2" 
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} >
                                <Button
                                    hidden={this.state.fileMetadata.fileType !== ".XLSX"}
                                    size="small"
                                    variant="contained"
                                    color="primary"

                                    onClick={() => this.addSheets()}
                                //className={classes.buttonAdd}
                                >
                                    Add Sheets
                                </Button>
                            </Grid>

                        </Grid>
                        {this.state.sheets.map((row, index) => {
                            return (
                                <Grid container spacing={3} hSpacing="2rem"
                                    style={{ margin: "1rem" }} hidden={this.state.fileMetadata.fileType !== ".XLSX"}>
                                    <Grid item xs={12} sm={3} md={3} >
                                        <TextField
                                            required

                                            id="filled-disabled"
                                            label={this.props.translation.SHEET_INDEX.longDescription}
                                            name="sheetIndex"
                                            value={row.sheetName?row.sheetName:row.sheetIndex}
                                            variant="outlined"

                                            size="small"
                                            onChange={(evt) => this.sheetsInputs(evt, index)}

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3} >
                                        <TextField
                                            required
                                            id="filled-disabled"
                                            label={this.props.translation.ROW_START.longDescription}
                                            name="rowStart"
                                            value={row.rowStart}
                                            variant="outlined"
                                            //style={{ marginLeft: 30 }}
                                            size="small"
                                            onChange={(evt) => this.sheetsInputs(evt, index)}
                                        // className="customInput2" 
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3} >
                                        <TextField

                                            id="filled-disabled"
                                            // label={this.props.translation.COLUMN_START.longDescription}
                                            label="Column Start"
                                            name="columnStart"
                                            value={row.columnStart}
                                            variant="outlined"
                                            //style={{ marginLeft: 30 }}
                                            size="small"
                                            onChange={(evt) => this.sheetsInputs(evt, index)}
                                        // className="customInput2" 
                                        />
                                    </Grid>
                                    <IconButton aria-label="Edit" style={{ color: "red" }}
                                        onClick={() => this.deleteSheet(row.id, index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            );
                        })}
                        <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => this.addNewConstraint()}
                            //className={classes.buttonAdd}
                            >
                                Add
                            </Button>
                        </span>
                        <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                            <Table size="small" aria-label="a dense table" name="constraintTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.CONSTRAINT_TYPE.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.OPERATOR.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.VALUE.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.constraints1.map((row, index) => {
                                        return (


                                            <TableRow key={row.id}>

                                                {/* <TableCell >{row.application}</TableCell> */}


                                                <TableCell >
                                                <FormControl
                                                        // style={{ width: 220 }}
                                                        variant="outlined" size="small">
                                                       <InputBase
                                                       name="constraintColumn"
                                                       id="constraintTable"
                                                       value={row.constraintColumn}
                                                       variant="outlined"
                                                       // disabled
                                                       style={{ width: "10rem"}}
                                                       InputProps={{
                                                           readOnly: true,
                                                       }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={(evt) => this.fnAttrValueHelp(index, evt, "constraintTable")}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        edge="end" >
                                                                        <SvgIcon >
                                                                            <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                        </SvgIcon >
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }>
                                                        </InputBase>
                                                    </FormControl>
                                                    {/* <TextField
                                                        required
                                                        id="constraintTable"

                                                        name="constraintColumn"
                                                        value={row.constraintColumn}
                                                        variant="outlined"
                                                        //style={{ marginLeft: 30 }}
                                                        size="small"
                                                        onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                    // className="customInput2" 
                                                    /> */}
                                                </TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="constraintTable"
                                                    disabled
                                                    name="constraintName"
                                                    value={row.constraintName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="constraintTable"

                                                    name="mappedName"
                                                    value={row.mappedName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>

                                                {/* <TableCell >{row.permissionType}</TableCell> */}
                                                <TableCell><TextField
                                                    required
                                                    id="constraintTable"

                                                    name="constraintType"
                                                    value={row.constraintType}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="constraintTable"
                                                    disabled
                                                    name="constraintOperator"
                                                    value={row.constraintOperator}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="constraintTable"

                                                    name="constraintValue"
                                                    value={row.constraintValue}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell >
                                                    {/* <Tooltip title= 'Edit'> */}
                                                    <IconButton aria-label="Edit" style={{ color: "red" }}
                                                        onClick={() => this.deleteConstraintRows(row.id, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    {/* </Tooltip> */}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <span className="iconBtn" style={{ float: 'right', marginBottom: '0.5rem' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => this.addNewFields()}
                            //className={classes.buttonAdd}
                            >
                                Add
                            </Button>
                        </span>
                        <TableContainer component={Paper} style={{ margin: '0.5rem', width: '99%' }}>
                            <Table size="small" aria-label="a dense table" name="fieldsTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.FIELDS.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.DISPLAY_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.MAPPED_NAME.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.SEARCHABLE.longDescription}</TableCell>
                                        <TableCell width="10%" style={{ fontWeight: 700 }}> {this.props.translation.UI_DISPLAY_NAME.longDescription}</TableCell>

                                        <TableCell width="10%" style={{ fontWeight: 700 }}> </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.fields.map((row, index) => {
                                        return (
                                            <TableRow key={row.id}>

                                                {/* <TableCell >{row.application}</TableCell> */}
                                                <TableCell >
                                                <FormControl
                                                        // style={{ width: 220 }}
                                                        variant="outlined" size="small">

                                                        <InputBase
                                                            name="columnName"
                                                            id="fieldsTable"
                                                            value={row.columnName}
                                                            variant="outlined"
                                                            // disabled
                                                            style={{ width: "10rem"}}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={(evt) => this.fnAttrValueHelp(index, evt, "fieldsTable")}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        edge="end" >
                                                                        <SvgIcon >
                                                                            <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>
                                                                        </SvgIcon >
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }>
                                                        </InputBase>
                                                    </FormControl>
                                                    {/* <TextField
                                                    required
                                                    id="fieldsTable"
                                                    name="columnName"
                                                    value={row.columnName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /> */}
                                                </TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="fieldsTable"

                                                    name="displayName"
                                                    value={row.displayName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>

                                                {/* <TableCell >{row.permissionType}</TableCell> */}
                                                <TableCell><TextField
                                                    required
                                                    id="fieldsTable"

                                                    name="mappedName"
                                                    value={row.mappedName}
                                                    variant="outlined"
                                                    //style={{ marginLeft: 30 }}
                                                    size="small"
                                                    onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                // className="customInput2" 
                                                /></TableCell>
                                                <TableCell><FormControl component="fieldset" style={{ marginLeft: 10, width: "100%" }} >
                                                    {/* <InputLabel id="demo-simple-select-outlined-label" ></InputLabel> */}
                                                    <FormGroup aria-label="position" row >

                                                        <FormControlLabel
                                                            required
                                                            // id="fieldTable"
                                                            size="small"
                                                            value={row.searchable}
                                                            //checked={this.state.obj.active}
                                                            name="searchable"
                                                            onChange={(evt) => this.valueHelpTableInputs(evt, index)}
                                                            //value={row.active}
                                                            labelPlacement="start"
                                                            control={<Switch color="primary"
                                                                checked={row.searchable}
                                                                id="fieldsTable" />}


                                                        />


                                                    </FormGroup>
                                                </FormControl ></TableCell>
                                                <TableCell>
                                                    <RadioGroup row

                                                        style={{ marginLeft: "1rem" }}

                                                        name="isDisplayName"
                                                    >
                                                        <Radio id="fieldsTable"
                                                            checked={row.isDisplayName}

                                                            onChange={(evt) => this.valueHelpTableInputs(evt, index)} />
                                                    </RadioGroup>

                                                </TableCell>
                                                <TableCell >
                                                    {/* <Tooltip title= 'Edit'> */}
                                                    <IconButton aria-label="Edit" style={{ color: "red" }}
                                                        onClick={() => this.deleteFieldRows(row.id, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    {/* </Tooltip> */}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>




                    </div>}

                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ height: '3rem', borderTop: '1px solid #d9d9d9' }}>

                <Button key={'CANCEL'} variant="contained" size="small" onClick={() => { this.closeDialog("CANCEL") }}>CANCEL</Button>
                <Button key={'SAVE'} variant="contained" size="small" onClick={() => { this.closeDialog("SAVE") }} color="primary">SAVE</Button>
            </DialogActions>
            <ValueHelpDialog title="Value Help" open={this.state.showVHTableDialog} actions={["DISCARD", "CANCEL"]} onClose={() => this.props.closeDialog()} dataElement={this.props.parentState.fieldCatalogArray}
                rowSelected={(row) => this.rowSelected(row, this.state.index)} />
                <Snackbar open={this.state.snackbarOpen} autoHideDuration={3000} onClose={() => this.setState({ ...this.state, snackbarOpen: false })}
                    message={this.state.snackbarMessg} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} />
        </Dialog>
        {/* } */}
        </div>
        );
    }
}

export default withRouter(Composite);