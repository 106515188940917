import React from 'react';
import { withRouter } from 'react-router-dom';
import { ResponsivePopover } from '@ui5/webcomponents-react/dist/ResponsivePopover';
import { Button } from '@ui5/webcomponents-react/dist/Button';
import { List } from '@ui5/webcomponents-react/dist/List';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import CwMSFields from './CwMSFields';
import CwMSEmailconfiguration from './CwMSEmailconfiguration';
import CwWorkText from './CwWorkText';
require('./App.css');

// import CwWfHomePage from './CwWfHomePage'
// import CwWfSubmittedForms from './CwWfSubmittedForms';
// import CwWfTextRules from './CwWfTextRules';
let defaultTab = "";
class CwWfLandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.settingsPopoverRef = React.createRef();
        this.state = {
            value: this.props.defaultTab,
            applicationName: '',
            translation: {
                "USER_ONBOARDING_UR": {},
                "DESTINATION": {
                    longDescription: "Destination"
                },
                "VALUE_HELP_URL": {
                    longDescription: "ValueHelp Url"
                },
                "API_TYPE": {
                    longDescription: "Api Type"
                },
                "CONSTRAINT": {
                    longDescription: "Constraint"
                },
                "CONSTRAINT_TYPE": {
                    longDescription: "Constraint Type"
                },
                "OPERATOR": {
                    longDescription: "Operator"
                },
                "VALUE": {
                    longDescription: "Value"
                },
                "DISPLAY_NAME": {
                    longDescription: "Display Name"
                },
                "MAPPED_NAME": {
                    longDescription: "Mapped Name"
                },
                "RESPONSE_PATH": {
                    longDescription: "Response Path"
                },
                "KEY": {
                    longDescription: "Key"
                },
                "VALUE": {
                    longDescription: "Value"
                },
                "ADDITIONAL_VALUE": {
                    longDescription: "Additional Value"
                },
                "FIELDS": {
                    longDescription: "Fields"
                },
                "SEARCHABLE": {
                    longDescription: "Searchable"
                },
                "UI_DISPLAY_NAME": {
                    longDescription: "UI Display Name"
                },
                "DATA_TABLE": {
                    longDescription: "Data Table"
                },
                "FILE_TYPE": {
                    longDescription: "File Type"
                },
                "SHEET_INDEX": {
                    longDescription: "Sheet Index/ Sheet Name"
                },
                "ROW_START": {
                    longDescription: "Row Start"
                },


            }
        };
    }
    componentDidMount() {
        
        console.log("aaa");
        Promise.all([
            fetch("/CW_Worktext/translation/getTranslations?applicationName=WorkBoxAdmin&elementName=UI&languageCode=EN").then((res) => res.json()),
            //fetch("/userRoles/api/v1/roles").then((res) => res.json())
            // fetch("/userRoles/api/v1/roles/activityRoleMapping/" + id).then((res) => res.json())
        ])
            .then(([translationList]) => {
                //this.getRolesList(userrolesList.data).then(updatesUserRolesList => {
                //console.log(updatesUserRolesList)
                this.setState({
                    ...this.state,
                    translation1: translationList.data.properties,
                    // translationList:


                })
                console.log(this.state.f);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    handleChange = (event, newValue) => {
        this.setState({
            ...this.state,
            value: newValue
        });
    };

    onSettingsClick = (e) => {
        // this.settingsPopoverRef.current.open(e.target);
    }

    goToMsgComp = event => {
        this.props.history.push('/messageSummary');
    }

    goToThemeComp = event => {
        this.props.history.push('/themeStyling');
    }

    render() {
        var emailconfig = false;
        var attribute = false;
        var changed = false;
        var applicationName = "";
        
        const userAccess = this.props.userAccess;
        const newArr = this.props.newArr;
        // const dashboard = userAccess.entities.find(ele => ele === "Dashboard") ? true : false;
        // const fieldCatalog = userAccess.entities.find(ele => ele === "Field Catalog" || ele === "Data Elements") ? true : false;
        // const emailConfig = userAccess.entities.find(ele => ele === "Email Configuration") ? true : false;
        // const applications = userAccess.entities.find(ele => ele === "Applications") ? true : false;
        // const workText = userAccess.entities.find(ele => ele === "Work Text") ? true : false;
        // this.setDefault();
        if (window.top !== window.self) {
            var url = window.location.href.substring(1);
            var qArray = url.split('&');
            for (var i = 0; i < qArray.length; i++) {

                var pArr = qArray[i].split('=');
                if (pArr[0] == "appName") {

                    applicationName = pArr[1];
                    // this.setState({
                    //     ...this.state,

                    // })


                }
                if (pArr[0] == "tab") {
                    if (pArr[1] === "emailConfig") {
                        emailconfig = true;
                        changed = true;
                    }
                    else if (pArr[1] === "fieldCatalog") {
                        attribute = true;
                        changed = true;
                    }

                }
            }
        }
        else {
            changed = false
        }
        return (
            <div>
                {!changed && <div>
                    <div className="titleBar">
                        <h2 className="titleText">WorkBox Admin</h2>


                    </div>

                    <TabContext value={this.state.value}>
                        <AppBar position="static">
                            <TabList onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                                {newArr.map((item) => {
                                    // if(userAccess.entities[index] ===)
                                    if (item === "DashBoard")
                                        return (<Tab label="DashBoard" value="DashBoard" />)
                                    else if (item === "Applications")
                                        return (<Tab label="Applications" value="Applications" />)
                                    else if (item === "Field Catalog" || item === "Data Elements")
                                        return (<Tab label="Field Catalog/Data Elements" value="Field Catalog" />)
                                    else if (item === "Email Configuration")
                                        return (<Tab label="Email Configuration" value="Email Configuration" />)
                                    else
                                        return (<Tab label="Worktext" value="Worktext" />)
                                })}

                            </TabList>
                        </AppBar>
                        {newArr.map((item) => {
                            // if(userAccess.entities[index] ===)
                            if (item === "DashBoard")
                                return (<TabPanel value="DashBoard" />)
                            else if (item === "Applications")
                                return (<TabPanel value="Applications" />)
                            else if (item === "Field Catalog" || item === "Data Elements")
                                return (<TabPanel value="Field Catalog" >
                                    <CwMSFields {...this.props} translation={this.state.translation} />
                                </TabPanel>)
                            else if (item === "Email Configuration")
                                return (<TabPanel value="Email Configuration" >
                                    <CwMSEmailconfiguration {...this.props} translation={this.state.translation} />
                                </TabPanel>)
                            else
                                return (<TabPanel value="Worktext" >
                                    <CwWorkText {...this.props} translation={this.state.translation} />
                                </TabPanel>)
                        })}

                    </TabContext>
                </div>}
                {changed && emailconfig && <CwMSEmailconfiguration {...this.props} translation={this.state.translation} applicationName={applicationName} />}
                {changed && attribute && <CwMSFields {...this.props} translation={this.state.translation} applicationName={applicationName} />}
            </div>
        );
    }
}

export default withRouter(CwWfLandingPage);

{/* {dashboard &&
                                <div> */}
{/* <div hidden={dashboard === false ? 'hidden' : ''}> */ }
{/* <Tab hidden="hidden" label="DashBoard" value="DashBoard"  /> */ }
{/* </div> */ }
{/* <div hidden={applications === false ? 'hidden' : ''}> */ }
{/* <Tab hidden={!applications} label="Applications" value="Applications" /> */ }
{/* </div> */ }
{/* <div hidden={fieldCatalog === false ? 'hidden' : ''}> */ }
{/* <Tab hidden={!fieldCatalog} label="Field Catalog/Data Elements" value="Field Catalog" /> */ }
{/* </div> */ }
{/* <div hidden={emailConfig === false ? 'hidden' : ''}> */ }
{/* <Tab hidden={!emailConfig} label="Email Configuration" value="Email Configuration" /> */ }
{/* </div> */ }
{/* <div hidden={workText === false ? 'hidden' : ''}> */ }
{/* <Tab hidden={!workText} label="Worktext" value="Worktext" /> */ }
{/* </div> */ }

{/* <TabPanel value="DashBoard" hidden={dashboard === false ? 'hidden' : ''}>

                        </TabPanel>
                        <TabPanel value="Applications" hidden={applications === false ? 'hidden' : ''}>

                        </TabPanel>

                        <TabPanel value="Field Catalog" hidden={fieldCatalog === false ? 'hidden' : ''}>
                            <CwMSFields {...this.props} translation={this.state.translation} />
                        </TabPanel>

                        <TabPanel value="Email Configuration" hidden={emailConfig === false ? 'hidden' : ''}>
                            <CwMSEmailconfiguration {...this.props} translation={this.state.translation} />
                        </TabPanel>
                        <TabPanel value="Worktext" hidden={workText === false ? 'hidden' : ''}>
                            <CwWorkText {...this.props} translation={this.state.translation} />
                        </TabPanel>*/}


{/* {dashboard && <span className="iconBtn">
                        <IconButton
                            // hidden={!this.props.roleCollection.includes('WF_ADMIN')}
                            // hidden={!configurationEntity}
                            aria-label="Settings" className="btnclr" aria-controls="simple-menu" aria-haspopup="true" onClick={this.onSettingsClick}>
                            <SettingsIcon />
                        </IconButton> */}
{/* <ResponsivePopover id="settingsPopover" ref={this.settingsPopoverRef} placementType="Left">
                            <div>
                                <div>
                                    <List>
                                        <div>
                                            <Button onClick={this.goToMsgComp} className="menuBtns">
                                                Message Components
                                            </Button>
                                        </div>
                                        <Button onClick={this.goToThemeComp} className="menuBtns">
                                            Design Templates
                                    </Button>
                                    </List>
                                </div>
                            </div>
                        </ResponsivePopover> */}
{/* </span>} */ }